import React from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import {FaBars} from 'react-icons/fa';

const ProSide = () => {
	 const { collapseSidebar } = useProSidebar();
	return (
		<div style={{ display: "flex", height: "100vh" }}>
	      <Sidebar className="" breakPoint="sm">
	        <Menu>
	          <MenuItem className="menu1" icon={
              <FaBars
                onClick={() => {
                  collapseSidebar();
                }}
              />
            }>
	            <h2>QUICKPAY</h2>
	          </MenuItem>
	          <MenuItem> Dashboard </MenuItem>
	          <MenuItem> Invoices </MenuItem>
	          <SubMenu label="">
	            <MenuItem> Timeline Chart </MenuItem>
	            <MenuItem> Bubble Chart </MenuItem>
	          </SubMenu>
	          <SubMenu label="Wallets">
	            <MenuItem>Current Wallet</MenuItem>
	            <MenuItem>Savings Wallet</MenuItem>
	          </SubMenu>
	          <MenuItem> Transactions </MenuItem>
	          <SubMenu label="Settings">
	            <MenuItem> Account </MenuItem>
	            <MenuItem> Privacy </MenuItem>
	            <MenuItem> Notifications </MenuItem>
	          </SubMenu>
	          <MenuItem> Logout </MenuItem>
	        </Menu>
	      </Sidebar>
	    </div>
	)
}

export default ProSide