import React from 'react';
import { Link,useNavigate } from 'react-router-dom';
import {BiCog} from 'react-icons/bi';
import { AiOutlineEllipsis,AiOutlineSearch } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { logout, RESET } from "../../redux/kbmone-features/auth/authSlice";
// import { SuperAdminLink } from "./hiddenLinks";
import {
  selectUser,
} from "../../redux/kbmone-features/auth/authSlice";


export const shortenText = (text, n) => {
  if (text.length > n) {
    const shoretenedText = text.substring(0, n).concat("...");
    return shoretenedText;
  }
  return text;
};


const KbmOneHeader = () => {

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const logoutUser = async () => {
	    dispatch(RESET());
	    await dispatch(logout());
	    navigate("/login");
	};

	return (
		<header>
			{/* <!--  BEGIN NAVBAR  -->*/}
		    <div className="header-container container-xxl">
		        <header className="header navbar navbar-expand-sm expand-header" style={{justifyContent : 'space-between'}}>

		            <ul className="navbar-item theme-brand flex-row  text-center">
		                <li className="nav-item theme-logo">
		                    <Link to="/">
		                    	<h3>Kbm One</h3>
		                    	
		                        {/*<img src="../src/assets/img/kbmlogo.png" className="navbar-logo" alt="KBM_LOGO"/>*/}
		                    </Link>
		                </li>
		                
		            </ul>

		            <ul className="navbar-item custom_navbar flex-row">
		            	<li className="nav-item">
		                    <Link to="/" className="nav-link p-0">Home</Link>
		                </li>
		                
		                <li className="nav-item">
		                    <Link to="/" className="nav-link p-0">Getting Started</Link>
		                </li>
		                
		            </ul>

		            <ul className="navbar-item flex-row ms-auto ms-0 action-area">

		                {<li className="nav-item theme-toggle-item">
		                    <Link to="/crm" className="nav-link">
		                    	<BiCog size={24} color='#e0e6ed' />
		                    </Link>
		                </li> }

		                <li className="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
		                    <Link to="" className="nav-link dropdown-toggle user" id="userProfileDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
		                        <div className="avatar-container">
		                            <div className="avatar avatar-sm avatar-indicators avatar-online">
		                                <UserProfile />
		                                {/*<img alt="avatar" src='../src/assets/img/avatar.png' className="rounded-circle"/>*/}
		                            </div>
		                        </div>
		                    </Link>

		                    <div className="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
		                        <div className="user-profile-section">
		                            <div className="media mx-auto">
		                                <div className="emoji me-2">
		                                    &#x1F44B;
		                                </div>
		                                <div className="media-body">
		                                    <UserName />
		                                    <UserRole />
		                                </div>
		                            </div>
		                        </div>
		                        <div className="dropdown-item">
		                            <Link to="/profile-account">
		                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> <span>Profile</span>
		                            </Link>
		                        </div>
		                        <div className="dropdown-item">
		                            <Link to="/change-password">
		                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> <span>Change Password</span>
		                            </Link>
		                        </div>
		                        
		                        <div className="dropdown-item">
		                            <Link to="" onClick={logoutUser}>
		                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg> <span>Log Out</span>
		                            </Link>
		                        </div>
		                    </div>
		                    
		                </li>
		            </ul>
		        </header>
		    </div>
		    {/*<!--  END NAVBAR  -->*/}
		</header>
	)
}

export const UserProfile = () => {
  const profile = useSelector(selectUser);

  const userProfile = profile?.photo || "...";

  return <img alt="avatar" src={userProfile} className="rounded-circle"/>;
};

export const UserName = () => {
  const profile = useSelector(selectUser);

  const userName = profile?.name || "...";

  return <h5 className="mb-0">{shortenText(userName,8)}</h5>;
};

export const UserRole = () => {
  const profile = useSelector(selectUser);

  const userRole = profile?.role || "...";

  return <span>{shortenText(userRole)}</span>;
};

export default KbmOneHeader