import axios from 'axios';
import download from "downloadjs";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/leads/crm/`;

// export file
const exportFile = async(moduleData) =>{
	const response = await axios.post(API_URL + "exportFile", moduleData);
	download(response.data, new Date().toLocaleDateString() + '-data.csv');
	return response.data;
};

// import file
const importFile = async(leadData) =>{
	const response = await axios.post(API_URL + "importFile", leadData);
	return response.data;
};

// add lead
const createLead = async(leadData) =>{
	const response = await axios.post(API_URL + "createLead", leadData);
	return response.data;
};

// Get all leads
const getLeads = async (id) => {
	const response = await axios.get(API_URL + /getLeads/ + id);
	return response.data;
};

// get lead
const getLead = async(id) =>{
	const response = await axios.get(API_URL + id);
	return response.data;
};

// update lead
const updateLead = async(id, moduleData) =>{
	const response = await axios.patch(`${API_URL}${id}`, moduleData);
	return response.data;
};

// delete lead
const deleteLead = async(id) =>{
	const response = await axios.delete(API_URL + id);
	return response.data.message;
};


const leadService = {
	createLead,
	getLeads,
	getLead,
	updateLead,
	deleteLead,
	exportFile,
	importFile,
}

export default leadService;