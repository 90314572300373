import React,{useEffect,useState,useMemo,useRef,useReducer} from 'react';
import ReactDOM from "react-dom";
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit,AiOutlineEllipsis } from "react-icons/ai";
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { toast } from "react-toastify";
import {MaterialReactTable} from 'material-react-table';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import {
	targetModule,
	addModule,
  	getModules,
  	deleteModule,
  	selectModuleItem,
} 	from "../../redux/crm-features/moduleItem/moduleSlice";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

// const data = [
//   {
//     _id: '1',
//     title: "apple",
//   },
//   {
//     _id: '2',
//     title: "mango",
//   },
//   {
//     _id: '3',
//     title: "green",
//   },
// ];

const initialState ={
	title : '',
	name : '',
	lastModify : '',
}

const reorder = (list, startIndex, endIndex) =>{
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex,0,removed);
	return result;
}

const getListStyle = (isDraggingOver) =>({
	background: isDraggingOver? '#fff':'#fff',
})

const getItemStyle = (isDragging,draggableStyle)=>({
	userSelect: 'none',
	padding: 10,
	margin: '0 0 10px 0',
	background: isDragging?'#081a3e':'#f1f1f1',
	color: isDragging?'#fff':'#081a3e',
	fontSize: '14px',
	cursor: 'move',
	display: 'flex',
	alignItems: 'center',
	fontWeight: 500,
	flexDirection: 'row',
	...draggableStyle
})

const Modules = () => {
	
	const navigate = useNavigate();

	useRedirectLoggedOutUser("/crm/login");

	const [moduleData, setModuleData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const {title,name,lastModify} = moduleData;
	const [ViewPost, SetPostShow] = useState(false)
    const handlePostShow = () => { SetPostShow(true) }
    const hanldePostClose = () => { SetPostShow(false) }
     //FOr DragNdrop Model
    const [ViewDndModel, SetDndModelShow] = useState(false)
    const handleDndModelShow = () => { SetDndModelShow(true) }
    const hanldeDndModelClose = () => { SetDndModelShow(false) }

    const dispatch = useDispatch();

    var { moduleItems, moduleItem, isLoading, isSuccess, message } = useSelector(
	    (state) => state.moduleItem
	);

    const handleInputChange = (e) => {
		const {name, value} = e.target;
		setModuleData({...moduleData, [name]: value});
	}

	const moduleAdd = async (event) => {
		event.preventDefault();

		if (!title || !name){
			return toast.error('All fields are required')
		}

		const moduleData = {title, name, lastModify};
		// console.log(userData);
		await dispatch(addModule(moduleData));
		setModuleData({ title: "", name: "", lastModify: "" });
		SetPostShow(false);
		forceUpdate();
		// navigate('/users-list');
		// window.location.reload();
		// await dispatch(sendVerificationEmail());
	};

	useEffect(() => {
	    dispatch(getModules());
	    dispatch(targetModule());
	}, [dispatch, reducerValue]);

	const removeModule = async (id) => {
	    await dispatch(deleteModule(id));
	    dispatch(getModules());
	};

	const confirmDelete = (id) => {
	    confirmAlert({
	      title: "Delete This Module",
	      message: "Are you sure to do delete this module?",
	      buttons: [
	        {
	          label: "Delete",
	          onClick: () => removeModule(id),
	        },
	        {
	          label: "Cancel",
	          // onClick: () => alert("Click No"),
	        },
	      ],
	    });
	};

	const getModule = async (item) => {
	    await dispatch(targetModule(item));
	    navigate("/crm/module-layout-setting");
	};

const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.title}`, //alternate way
        id: 'title', //id required if you use accessorFn instead of accessorKey
        header: 'Display in tab as',
        Header: () => <span>Display in tab as</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          <span className="d-flex align-items-center"><Link to={`/crm/module/${row.original._id}`}><b>{renderedCellValue}</b></Link>
		        {/*<div className="dropdown">
						  <span className="dropdown-toggle mx-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						    <AiOutlineEllipsis />
						  </span>
						  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						    <li><Link className="dropdown-item" onClick={() => getModule(row.original)}>Edit</Link></li>
						    <li><Link className="dropdown-item" to="#" onClick={() => confirmDelete(row.original._id)}>Delete</Link></li>
						    <li><a className="dropdown-item">Rename</a></li>
						    <li><a className="dropdown-item" href="#">Layout</a></li>
						    <li><a className="dropdown-item" href="#">Module Permission</a></li>
						    <li><a className="dropdown-item" href="#">Map Dependency Fields</a></li>
						    <li><a className="dropdown-item" href="#">Set Validation Rules</a></li>
						    <li><a className="dropdown-item" href="#">Duplicate Check Preference</a></li>
						  </ul>
						</div>*/}
						<div className="customDropDownButton">
			        {['end'].map(
			          (direction) => (
			            <DropdownButton
			              key={direction}
			              id={`dropdown-button-drop-${direction}`}
			              drop={direction}
			              title={`...`}
			            >
			              <li><Link className="dropdown-item" onClick={() => getModule(row.original)}>Edit</Link></li>
								    <li><Link className="dropdown-item" to="#" onClick={() => confirmDelete(row.original._id)}>Delete</Link></li>
								    <li><a className="dropdown-item">Rename</a></li>
								    <li><a className="dropdown-item" href="#">Layout</a></li>
								    <li><a className="dropdown-item" href="#">Module Permission</a></li>
								    <li><a className="dropdown-item" href="#">Map Dependency Fields</a></li>
								    <li><a className="dropdown-item" href="#">Set Validation Rules</a></li>
								    <li><a className="dropdown-item" href="#">Duplicate Check Preference</a></li>
			            </DropdownButton>
			          ),
			        )}
			      </div>
		      </span>
        ),
      },

      {
        accessorFn: (row) => row.name, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'Module Name',
        Header: () => <span>Module Name</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.lastModify, //alternate way
        id: 'lastModify', //id required if you use accessorFn instead of accessorKey
        header: 'Last Modified',
        Header: () => <span>Last Modified</span>, //optional custom header render
      },
      // {
      //   accessorFn: (row) => <span>
      //                       <FaTrashAlt
      //                         size={18}
      //                         cursor="pointer"
      //                         color="rgb(0,0,0,.8)"
      //                         onClick={() => confirmDelete(row._id)}
      //                       />
      //                       &nbsp;
      //                       <span to={`/crm/module-layout-setting`}>
	     //                        <AiFillEdit
	     //                          size={20}
	     //                          cursor="pointer"
	     //                          color="rgb(0,0,0,.8)"
						// 		  onClick={() => getModule(row)}
	     //                        />
      //                       </span>
      //                     </span>, //alternate way
      //   id: 'action', //id required if you use accessorFn instead of accessorKey
      //   header: 'Action',
      //   Header: () => <span>Action</span>, //optional custom header render
      // },
    ],
    [],
  );

	const [items, setItems] = useState([]);

	useEffect(() => {
	    setItems(moduleItems);
	}, []);

	// console.log(moduleItems)

	const onDragEnd = (result) => {
	    if (!result.destination) {
	      return;
		}

	    const reorderedItems = reorder(
	      items,
	      result.source.index,
	      result.destination.index
	    );

	    // console.log({ reorderedItems });
	    setItems(reorderedItems);
	};

	// const moduleDnd = useSelector(selectModuleItem);

	const isFirstRender = useRef(true);

  // const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
  //   [],
  // );
  const [columnVisibility, setColumnVisibility] = useState({});
  // const [density, setDensity] = useState<MRT_DensityState>('comfortable');
  // const [globalFilter, setGlobalFilter] = useState<string | undefined>(
  //   undefined,
  // );
  // const [showGlobalFilter, setShowGlobalFilter] = useState(false);
  // const [showColumnFilters, setShowColumnFilters] = useState(false);
  // const [sorting, setSorting] = useState<MRT_SortingState>([]);

  //load state from local storage
  useEffect(() => {
    // const columnFilters = sessionStorage.getItem('mrt_columnFilters_table_1');
    const columnVisibility = localStorage.getItem(
      'mrt_columnVisibility_table_1',
    );
    // const density = sessionStorage.getItem('mrt_density_table_1');
    // const globalFilter = sessionStorage.getItem('mrt_globalFilter_table_1');
    // const showGlobalFilter = sessionStorage.getItem(
    //   'mrt_showGlobalFilter_table_1',
    // );
    // const showColumnFilters = sessionStorage.getItem(
    //   'mrt_showColumnFilters_table_1',
    // );
    // const sorting = sessionStorage.getItem('mrt_sorting_table_1');

    // if (columnFilters) {
    //   setColumnFilters(JSON.parse(columnFilters));
    // }
    if (columnVisibility) {
      setColumnVisibility(JSON.parse(columnVisibility));
    }
    // if (density) {
    //   setDensity(JSON.parse(density));
    // }
    // if (globalFilter) {
    //   setGlobalFilter(JSON.parse(globalFilter) || undefined);
    // }
    // if (showGlobalFilter) {
    //   setShowGlobalFilter(JSON.parse(showGlobalFilter));
    // }
    // if (showColumnFilters) {
    //   setShowColumnFilters(JSON.parse(showColumnFilters));
    // }
    // if (sorting) {
    //   setSorting(JSON.parse(sorting));
    // }
    isFirstRender.current = false;
  }, []);

  //save states to local storage
  // useEffect(() => {
  //   if (isFirstRender.current) return;
  //   sessionStorage.setItem(
  //     'mrt_columnFilters_table_1',
  //     JSON.stringify(columnFilters),
  //   );
  // }, [columnFilters]);

  useEffect(() => {
    if (isFirstRender.current) return;
    localStorage.setItem(
      'mrt_columnVisibility_table_1',
      JSON.stringify(columnVisibility),
    );
  }, [columnVisibility]);

  // useEffect(() => {
  //   if (isFirstRender.current) return;
  //   sessionStorage.setItem('mrt_density_table_1', JSON.stringify(density));
  // }, [density]);

  // useEffect(() => {
  //   if (isFirstRender.current) return;
  //   sessionStorage.setItem(
  //     'mrt_globalFilter_table_1',
  //     JSON.stringify(globalFilter ?? ''),
  //   );
  // }, [globalFilter]);

  // useEffect(() => {
  //   if (isFirstRender.current) return;
  //   sessionStorage.setItem(
  //     'mrt_showGlobalFilter_table_1',
  //     JSON.stringify(showGlobalFilter),
  //   );
  // }, [showGlobalFilter]);

  // useEffect(() => {
  //   if (isFirstRender.current) return;
  //   sessionStorage.setItem(
  //     'mrt_showColumnFilters_table_1',
  //     JSON.stringify(showColumnFilters),
  //   );
  // }, [showColumnFilters]);

  // useEffect(() => {
  //   if (isFirstRender.current) return;
  //   sessionStorage.setItem('mrt_sorting_table_1', JSON.stringify(sorting));
  // }, [sorting]);

  const resetState = () => {
    // sessionStorage.removeItem('mrt_columnFilters_table_1');
    localStorage.removeItem('mrt_columnVisibility_table_1');
    // sessionStorage.removeItem('mrt_density_table_1');
    // sessionStorage.removeItem('mrt_globalFilter_table_1');
    // sessionStorage.removeItem('mrt_showGlobalFilter_table_1');
    // sessionStorage.removeItem('mrt_showColumnFilters_table_1');
    // sessionStorage.removeItem('mrt_sorting_table_1');
    window.location.reload();
  };
  // console.log(columnVisibility);

	return(
		<>
		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />

		                    <div className="row layout-top-spacing">

		                    	<div className="tabMenu d-flex justify-content-between">
		                        	<ul>
		                        		<li><Link className="btn btn-secondary active" to="/crm/modules">Modules</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/tab-group">Tab Groups</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/web-tab">Web Tabs</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/global-set">Global Sets</Link></li>
		                        	</ul>
		               
		                        </div>
		                        <div className="modules_btn text-end mt-4">
		                      		<button onClick={() => { handleDndModelShow() }} type="button" className="btn btn-secondary">Organize Modules</button>
		                      		<Link to="/crm/module-layout-setting" className="btn btn-secondary mx-1">Add Module</Link>
		                      		{/*<Link to="/crm/module-form-data" className="btn btn-secondary mx-1">Form Data</Link>*/}
		                      	</div>
		                        
			                    <div className="col-12">
			                    {/*<MaterialReactTable
      columns={columns}
      data={moduleItems}
      // onColumnFiltersChange={setColumnFilters}
      onColumnVisibilityChange={setColumnVisibility}
      // onDensityChange={setDensity}
      // onGlobalFilterChange={setGlobalFilter}
      // onShowColumnFiltersChange={setShowColumnFilters}
      // onShowGlobalFilterChange={setShowGlobalFilter}
      // onSortingChange={setSorting}
      state={{
        // columnFilters,
        columnVisibility,
        // density,
        // globalFilter,
        // showColumnFilters,
        // showGlobalFilter,
        // sorting,
      }}
      // renderTopToolbarCustomActions={() => (
      //   // <Button onClick={resetState}>Reset State</Button>
      // )}
    />*/}
		                        	<div className="statbox widget box box-shadow moduleData">
		                                <div className="widget-content widget-content-area">
		                                {moduleItems.length === 0 ? (
		                                	<p>Module not found...</p>
		                                ):(
		                                	<MaterialReactTable columns={columns} 
		                                	data={moduleItems} 
		                                	onColumnVisibilityChange={setColumnVisibility}
		                                	state={{columnVisibility}}
		                                	options={{
															          filtering: true // Enable filtering globally
															        }}
															        enableRowSelection={true}
		                                	// enableRowNumbers 
		                                	// rowNumberMode="original"
		                                	/>
		                                )}
		                                </div>
		                            </div>
		                        </div>
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    {/*<!-- Modal -->*/}
		    <Modal show={ViewPost}
                    onHide={hanldePostClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
			
			            	<form className="form" onSubmit={moduleAdd}>
			            	<h5 className="modal-title">Add Modules</h5>
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control mb-3" id="title" placeholder="Title" name="title" value={title} onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                   <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control mb-3" id="name" placeholder="Module Name" name="name" value={name} onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control mb-3" id="lastModify" placeholder="Last Modified" name="lastModify" value={lastModify} onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-1">
                                        <div className="form-group text-end">
                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
			                				<button type="button" className="btn btn btn-light-dark mx-3" onClick={hanldePostClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
			            
			</Modal>

			{/*<!-- Modal -->*/}
		    <Modal show={ViewDndModel}
                    onHide={hanldeDndModelClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
				<h4>Organize Modules</h4>
				<p>You can choose to show or hide modules, or change the order in which they are displayed in the tab.</p>
			    <div className="dragNdrop-moduleList">
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppableId">
							{(provided, snapshot) =>(
									<div 
									{...provided.droppableProps} 
									ref={provided.innerRef} 
									style={getListStyle(snapshot.isDraggingOver)}
									>
										{
											items && items.map((item,index) =>(
												<Draggable key={item._id} draggableId={item._id} index={index}>
													{(provided,snapshot) => (
														<div className="">
															
															<div 
																className="card" 
																ref={provided.innerRef} 
																{...provided.draggableProps} 
																{...provided.dragHandleProps}
																style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)}
																>
																{item.title}
																</div>
														</div>
													)}
												</Draggable>
											))
										}
										{provided.placeholder}
									</div>
								)}
						</Droppable>
					</DragDropContext>
				</div>
				<div className="form-group text-end">
					<button type="submit" className="m-1 btn btn-secondary">Save</button>
					<button onClick={hanldeDndModelClose} className="m-1 btn btn-secondary">Close</button>
				</div>
			</Modal>

		    
		</>
	);
}

export default Modules