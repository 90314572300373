import React,{useEffect,useState,useLayoutEffect,useReducer} from 'react';
import axios from "axios";
import ReactDOM from 'react-dom';
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
// import Breadcrumb from '../components/Breadcrumb';
// import Sidebar from '../components/Sidebar';
import PasswordInput from '../components/PasswordInput';
import KbmOneSidebar from '../components/KbmOneSidebar';
// import Footer from '../components/Footer';
// import UserStats from '../components/UserStats';
import { Link,useNavigate,useParams } from 'react-router-dom'; 
import { toast } from "react-toastify";
import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
// import { deleteUser,getAllUsers } from "../redux/crm-features/auth/authSlice";
// import { shortenText } from "./Profile";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { validateEmail } from "../../redux/kbmone-features/auth/authService";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleUserId,
  // selectUser,
  getAllUsersKbmOne,
  updateUserId,
  RESET,
  sendVerificationEmail,
} from "../../redux/kbmone-features/auth/authSlice";
// import {
//   	getProfiles
// } 	from "../../redux/crm-features/userProfile/profileSlice";
// import {
//   	getRoles
// } 	from "../../redux/crm-features/userRole/roleSlice";

// import imagePreview from "https://i.pinimg.com/736x/1f/51/03/1f51038bd5af319dd132a3fab9ee3b57.jpg";
const cloud_name = process.env.REACT_APP_CLOUD_NAME;
const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;

const EditUser = () => {
	useRedirectLoggedOutUser("/crm/login");

	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const { users, isLoading, isSuccess, message } = useSelector(
	    (state) => state.auth
	);

	const initialState ={
		name: users?.name || "",
    lname: users?.lname || "",
    email: users?.email || "",
    status: users?.status || "",
    isVerified: users?.isVerified || false,
	}

	useEffect(() => {
	    dispatch(getSingleUserId(id));
	}, [dispatch]);

	const [formInfoData, setFormInfoData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	
	// const {name,lname,email} = formData;

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setFormInfoData({...formInfoData, [name]: value});
	}

	const userEdit = async (e) => {
		e.preventDefault();

		// let imageURL;

		// if (profileImage !== null &&(profileImage.type === "image/jpeg" || profileImage.type === "image/jpg" ||
		// 		 profileImage.type === "image/png"))
		// 	{

		// 		const image = new FormData();
		//         image.append("file", profileImage);
		//         image.append("cloud_name", cloud_name);
		//         image.append("upload_preset", upload_preset);

		//         // Save image to Cloudinary
		//         const response = await fetch(
		//           "https://api.cloudinary.com/v1_1/kbm/image/upload",
		//           { method: "post", body: image }
		//         );
		//         const imgData = await response.json();
		//         // console.log(imgData);
		//         imageURL = imgData.url.toString();

		// 	}

		// if (!name || !email || !password || !phone || !desc || !role){
		// 	return toast.error('All fields are required')
		// }
		// if (password.length < 6) {
	 //      	return toast.error("Password must be up to 6 characters");
	 //    }
		// if (!validateEmail(email)){
		// 	return toast.error('Please enter a valid email')
		// }

		const userData = {
			name: formInfoData.name, 
			lname: formInfoData.lname,  
			email: formInfoData.email,  
      status: formInfoData.status,
		};
		
		await dispatch(updateUserId({id, userData}));
		await dispatch(getAllUsersKbmOne());
		forceUpdate();
		navigate('/user');
		// window.location.reload();
		// await dispatch(sendVerificationEmail());
	};

	useLayoutEffect(() => {
	    if (users) {
	      setFormInfoData({
	        ...formInfoData,
	        name: users.name,
	        lname: users.lname,
	        email: users.email,
	        status: users.status,
	      });
	    }
	    
	}, [users]);

	
	return (
		<div>

		{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container kbmone" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <KbmOneSidebar/>

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                   
		                    
		                    <div className="row layout-top-spacing">

			                    {/*content*/}

	                        	<div className="personal_setting_sec">
		                    			<h5 className="mt-4">Edit User</h5>
	                                <form onSubmit={userEdit}>
																		
																		<div className="col-md-12">
					                              <div className="form-group">
					                                  <input type="text" className="form-control mb-3" id="fullName" placeholder="Full Name" name="name" value={formInfoData?.name} onChange={handleInputChange}/>
					                              </div>
					                          </div>
					                          <div className="col-md-12">
					                              <div className="form-group">
					                                  <input type="text" className="form-control mb-3" id="lastName" placeholder="Last Name" name="lname" value={formInfoData?.lname} onChange={handleInputChange}/>
					                              </div>
					                          </div>
					                          <div className="col-md-12">
					                              <div className="form-group">
					                                  <input type="email" className="form-control mb-3" id="email" name="email" placeholder="Email Address*" value={formInfoData?.email} onChange={handleInputChange}/>
					                              </div>
					                          </div>
					                          
					                          <div className="col-md-12">
					                          	<select value={formInfoData?.status} onChange={handleInputChange} name="status" className="form-select form-control-sm mb-3">
																     		<option>Select Status</option>
																     		<option value="active">Active</option>
																     		<option value="inactive">In-active</option>
																     	</select>
					                          </div>  

																		<div className="col-md-12 mt-1">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Update</button>
                                        </div>
                                    </div>

																	</form>
		                    		</div>


			                    {/*content*/}

		                    </div>

		                </div>

		            </div>

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		</div>
	)
}

export default EditUser