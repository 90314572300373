import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/layouts/crm/`;

// add user profile
const addLayout = async(layoutData) =>{
	const response = await axios.post(API_URL + "addLayout", layoutData);
	return response.data;
};

// Get all profiles
const getLayouts = async () => {
  const response = await axios.get(API_URL + "getLayouts");
  return response.data;
};

// get user profile
const getLayout = async(id) =>{
	const response = await axios.get(API_URL + id);
	return response.data;
};

// update user profile
const updateLayout = async(id, layoutData) =>{
	const response = await axios.patch(`${API_URL}${id}`, layoutData);
	return response.data;
};

// delete user profile
const deleteLayout = async(id) =>{
	const response = await axios.delete(API_URL + id);
	return response.data.message;
};

const layoutService = {
	addLayout,
	getLayouts,
	getLayout,
	updateLayout,
	deleteLayout,
}

export default layoutService;