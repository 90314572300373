import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";

const Chat = () => {
	
	useRedirectLoggedOutUser("/crm/login");

	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">
		                        
		                     

		                        <div className="col-12 text-end mt-4">
			                        <div className="d-flex align-items-center justify-content-center">
			                        	<h5 className="text-center">Connect with your visitors instantly with messenger</h5>
			                        </div>
		                        </div>

		                        <div className="getting_started_video text-center">
		                        	<iframe width="90%" height="450" src="https://www.youtube.com/embed/_IogPtBTcwk" title="Make Selling easy & effective - SalesIQ's Integration with Zoho CRM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
		                        </div>

		                        <div className="col-12 text-end mt-4">
			                        <div className="d-flex align-items-center justify-content-center">
			                        	<h6 className="text-center">Integrate Zoho SalesIQ with Zoho CRM to generate, engage and, converge leads to optimize customer management.</h6>
			                        </div>
		                        </div>

		                        

		                        <div className="col-12 text-center mt-4">
		                        	<Link to="/crm" className="btn btn-secondary">Get Started</Link>
		                        </div>
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		</>
	);
}

export default Chat