import React,{useEffect,useState} from 'react';
import ReactDOM from "react-dom";
import { Link,useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  	getModules,
} 	from "../../redux/crm-features/moduleItem/moduleSlice";

const TabGroup = () => {
	
	useRedirectLoggedOutUser("/crm/login");

	
	const [ViewPost, SetPostShow] = useState(false)
    const handlePostShow = () => { SetPostShow(true) }
    const hanldePostClose = () => { SetPostShow(false) }
    
    const dispatch = useDispatch();

    const { moduleItems } = useSelector(
	    (state) => state.moduleItem
	);
    useEffect(() => {
	    dispatch(getModules());
	}, [dispatch]);

	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">

		                    	<div className="tabMenu d-flex justify-content-between">
		                        	<ul>
		                        		<li><Link className="btn btn-secondary" to="/crm/modules">Modules</Link></li>
		                        		<li><Link className="btn btn-secondary active" to="/crm/tab-group">Tab Groups</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/web-tab">Web Tabs</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/global-set">Global Sets</Link></li>
		                        	</ul>
		               
		                        </div>

		                        <div className="col-12 text-end mt-4">
			                        <div className="d-flex align-items-center justify-content-between">
			                        	<h5 className="">Tab Groups</h5>
				                        <div className="modules_btn text-end mt-4">
				                      		<button onClick={() => { handlePostShow() }} type="button" className="btn btn-secondary">New Tab Group</button>
				                      	</div>
			                        </div>
		                        </div>
		                        
			                    
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    {/*<!-- Modal -->*/}
		    <Modal show={ViewPost}
                    onHide={hanldePostClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
			
			            	<form className="form" >
			            	<h5 className="modal-title">Tab Groups</h5>
                                <div className="row mt-4">
                                    
                                   <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control mb-3" id="name" placeholder="Name" name="name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <h6>Tabs</h6>
                                        <div className="tabCheckboxList">
	                                        <div>
	                                        	<input type="checkbox" id="home" name="home" value="Home"/>
	  											<label htmlFor="home">Home</label>
	                                        </div>
	                                        {
	                                        	Array.isArray(moduleItems) ? 
	                                        	moduleItems.map((item) => {
	                                        		return(
	                                        				<div key={item._id}>
					                                        	<input type="checkbox" id={item.name} name={item.name} value={item.name}/>
					  											<label className="text-capitalize" htmlFor={item.name}>{item.name}</label>
					                                        </div>
	                                        			)
	                                        	})
	                                        	: null
	                                        }
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-1">
                                        <div className="form-group text-end">
                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
			                				<button type="button" className="btn btn btn-light-dark mx-3" onClick={hanldePostClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
			            
			</Modal>

			
		    
		</>
	);
}

export default TabGroup