import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";

const ComplianceSetting = () => {
	
	useRedirectLoggedOutUser("/crm/login");

	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">
		                        
		                        <div className="tabMenu">
		                        	<ul>
		                        		<li><Link className="btn btn-secondary" to="/crm/compliance-setting">GDPR Compliance</Link></li>
		                        	</ul>
		                        </div>

		                        <div className="col-12 mt-4">
		                        	<p>This page helps you decide how you want to handle, manage, and process personal data of your customers to<br/>
		                        	comply with GDPR for your organiztion.</p>
		                        </div>

		                        <div className="col-12 text-end mt-4">
			                        <div className="d-flex align-items-center justify-content-between">
			                        	<h5>Enable GDPR Compliance Settings</h5>
			                        </div>
		                        </div>

		                        <div className="row my-4">
			                        <div className="col-md-4 text-center">
			                        	<div className="info-box-1 color-2">
										    <div className="info-box-1-content-wrapper">
										        <h5 className="info-box-1-title">Data Collection</h5>
										        <div className="info-box-1-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
										    </div>
										    <Link className="info-box-1-button" to="/crm">Know More </Link>
										</div>
			                        </div>		
			                        <div className="col-md-4 text-center">
			                        	<div className="info-box-1 color-2">
										    <div className="info-box-1-content-wrapper">
										        <h5 className="info-box-1-title">Data Processing</h5>
										        <div className="info-box-1-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
										    </div>
										    <Link className="info-box-1-button" to="/crm">Know More </Link>
										</div>
			                        </div>		
			                        <div className="col-md-4 text-center">
			                        	<div className="info-box-1 color-2">
										    <div className="info-box-1-content-wrapper">
										        <h5 className="info-box-1-title">Data Request</h5>
										        <div className="info-box-1-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
										    </div>
										    <Link className="info-box-1-button" to="/crm">Know More </Link>
										</div>
			                        </div>		                        	
		                        </div>

		                        <div className="col-12 text-center mt-4">
		                        	<button className="btn btn-secondary">Enable GDPR Compliance</button>
		                        </div>
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		</>
	);
}

export default ComplianceSetting