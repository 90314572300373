import React,{useEffect,useState,useRef,useReducer} from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
// import { FaTrashAlt } from "react-icons/fa";
// import { AiFillEdit } from "react-icons/ai";
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { toast } from "react-toastify";
// import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
// import { confirmAlert } from "react-confirm-alert";
// import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch } from "react-redux";
import {
	addRole,
  	getRoles,
  	// deleteProfile,
} 	from "../../redux/crm-features/userRole/roleSlice";
import TreeMenu, { ItemComponent } from "react-simple-tree-menu";
// import "react-simple-tree-menu/dist/main.css";

const initialState ={
	name : '',
	desc : '',
}


const dataInArray = [
  {
    key: "KBM",
    label: "KBM",
    nodes: [
      {
        key: "Administrator",
        label: "Administrator",
        nodes: [
          {
            key: "Manager",
            label: "Manager",
            nodes: []
          }
        ]
      },
      {
        key: "Standard",
        label: "Standard",
        nodes: [
          {
            key: "Manager",
            label: "Manager",
            nodes: []
          }
        ]
      },
    ]
  }
];

const Role = () => {
	
	useRedirectLoggedOutUser("/crm/login");

	const [roleData, setRoleData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const {name,desc} = roleData;
	const [ViewPost, SetPostShow] = useState(false)
    const handlePostShow = () => { SetPostShow(true) }
    const hanldePostClose = () => { SetPostShow(false) }

    const dispatch = useDispatch();
	// const navigate = useNavigate();

 //    const { userRoles, isLoading, isSuccess, message } = useSelector(
	//     (state) => state.userRole
	// );

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setRoleData({...roleData, [name]: value});
	}

	const roleAdd = async (e) => {
		e.preventDefault();

		if (!name){
			return toast.error('Name is required')
		}

		const roleData = {name, desc};
		// console.log(userData);
		await dispatch(addRole(roleData));
		setRoleData({ name: "", desc: "" });
		SetPostShow(false);
		forceUpdate();
		// navigate('/users-list');
		// window.location.reload();
		// await dispatch(sendVerificationEmail());
	};

	useEffect(() => {
	    dispatch(getRoles());
	}, [dispatch, reducerValue]);

	const [data, setData] = useState(dataInArray);
    const [openNodes, setOpenNodes] = useState();
    const update = () => {
	    setData(data => [
	      ...data,
	      // {
	      //   key: "foo",
	      //   label: "Foo",
	      //   url: "https://www.google.com/search?q=foo",
	      //   nodes: [
	      //     {
	      //       key: "bar",
	      //       label: "Bar",
	      //       url: "https://www.google.com/search?q=bar"
	      //     }
	      //   ]
	      // }
	    ]);
	    // setOpenNodes(["mammal"]);
	};

	// const removeProfile = async (id) => {
	//     await dispatch(deleteProfile(id));
	//     dispatch(getProfiles());
	// };

	// const confirmDelete = (id) => {
	//     confirmAlert({
	//       title: "Delete This Profile",
	//       message: "Are you sure to do delete this profile?",
	//       buttons: [
	//         {
	//           label: "Delete",
	//           onClick: () => removeProfile(id),
	//         },
	//         {
	//           label: "Cancel",
	//           // onClick: () => alert("Click No"),
	//         },
	//       ],
	//     });
	// };

// const columns = useMemo(
//     () => [
//       {
//         accessorFn: (row) => row.name, //alternate way
//         id: 'name', //id required if you use accessorFn instead of accessorKey
//         header: 'Profile Name',
//         Header: () => <span>Role Name</span>, //optional custom header render
//       },
//       {
//         accessorFn: (row) => row.desc, //alternate way
//         id: 'desc', //id required if you use accessorFn instead of accessorKey
//         header: 'Profile Description',
//         Header: () => <span>Profile Description</span>, //optional custom header render
//       },
//       {
//         accessorFn: (row) => row.createdAt, //alternate way
//         id: 'createdAt', //id required if you use accessorFn instead of accessorKey
//         header: 'Created At',
//         Header: () => <span>Created At</span>, //optional custom header render
//       },
//       {
//         accessorFn: (row) => row.updatedAt, //alternate way
//         id: 'updatedAt', //id required if you use accessorFn instead of accessorKey
//         header: 'Modified At',
//         Header: () => <span>Modified At</span>, //optional custom header render
//       },
//       {
//         accessorFn: (row) => <span>
//                             <FaTrashAlt
//                               size={18}
//                               cursor="pointer"
//                               color="rgb(0,0,0,.8)"
//                               onClick={() => confirmDelete(row._id)}
//                             />
//                             &nbsp;
//                             <Link to={`/edit-profile/${row._id}`}>
// 	                            <AiFillEdit
// 	                              size={20}
// 	                              cursor="pointer"
// 	                              color="rgb(0,0,0,.8)"
// 	                            />
//                             </Link>
//                           </span>, //alternate way
//         id: 'action', //id required if you use accessorFn instead of accessorKey
//         header: 'Action',
//         Header: () => <span>Action</span>, //optional custom header render
//       },
//     ],
//     [],
//   );

	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">
		                        
		                        <div className="tabMenu">
		                        	<ul>
		                        		<li><Link className="btn btn-secondary" to="/crm/security-control">Profiles</Link></li>
		                        		<li><Link className="btn btn-secondary active" to="/crm/user-role">User Roles</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/data-sharing-setting">Data Sharing Settings</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm">Zoho Mail Add-On Users</Link></li>
		                        	</ul>
		                        </div>

		                        <div className="col-12 text-end mt-4">
		                        	<button onClick={() => { handlePostShow() }} type="button" className="btn btn-secondary">Add Role</button>
		                        </div>

		                        <div className="treeViewData">
								<TreeMenu
						      data={data}
						         resetOpenNodesOnDataUpdate
						         initialOpenNodes={openNodes}
						       >
						         {({ items }) => (
						        	
						           <ul className="tree-item-group">
						             {items.map(({ key, onClickItem, ...props }) => (
						               <ItemComponent key={key} {...props} />
						             ))}
						           </ul>
						         )}
						    </TreeMenu>
						  </div>

		                        {/*<div className="col-12">
		                        	<div className="statbox widget box box-shadow">
		                                <div className="widget-content widget-content-area">
		                                {userProfiles.length === 0 ? (
		                                	<p>Profile not found...</p>
		                                ):(
		                                	<MaterialReactTable columns={columns} data={userProfiles} enableRowNumbers rowNumberMode="original"/>
		                                )}
		                                </div>
		                            </div>
		                        </div>*/}
		                        
		                       
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    {/*<!-- Modal -->*/}
		    <Modal show={ViewPost}
                    onHide={hanldePostClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
			
			            	<form className="form" onSubmit={roleAdd}>
			            	<h5 className="modal-title">Add Role</h5>
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control mb-3" id="name" placeholder="Role Name" name="name" value={name} onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                   
                                    <div className="col-md-12">
                                        <div className="form-group">
                                        	<textarea type="text" className="form-control mb-3" name="desc" id="phone" placeholder="Role Description" value={desc} onChange={handleInputChange}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-1">
                                        <div className="form-group text-end">
                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
			                											<button type="button" className="btn btn btn-light-dark mx-3" onClick={hanldePostClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
			            
			</Modal>

		</>
	);
}

export default Role