import React,{useState,useRef,useEffect,useMemo,useReducer} from 'react';
import { Link, useParams } from "react-router-dom";
import ModuleSideBar from './ModuleSideBar';
import { Modal, Card } from 'react-bootstrap';
import { FaTrashAlt } from "react-icons/fa";
import {AiOutlineSearch, AiOutlineArrowLeft, AiFillEdit, AiFillCaretDown, AiOutlineEllipsis } from "react-icons/ai";
import Footer from '../components/Footer';
// import Calendar from '../components/Calendar';
import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import Select from 'react-select';
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import { destPix, destFile, pixAddress, fileAddress } from '../../formBuilder/srcSet';
import {
	createLead,
  	getLeads,
  	deleteLead,
  	exportFile,
  	importFile,
} 	from "../../redux/crm-features/lead/leadSlice";
import {
	getModule,getModules, targetModule, findFormDataCount, addFormData, findFormData, updateFormData, deleteFormData, getFormData, 
} 	from "../../redux/crm-features/moduleItem/moduleSlice";
import {
	previewArrMap, setValue, seperator, setMultiSelect, getPattern, setData,
  	pixSave, fileDelete, fileSave, setImageUpload, setFileUpload, setType, shortName,
	connectSection, connectField, removeElm,
} from '../../formBuilder/FormHelper';
import FileViewer from 'react-file-viewer';
const options = [
  { value: 'All Leads', label: 'All Leads' },
  { value: 'All Loacked Leads', label: 'All Loacked Leads' },
  { value: 'Junk Leads', label: 'Junk Leads' }
]

// const layouts = [
// 	{
// 		"name": "Carissa Kidman (Sample)",
// 		"company": "Oh My Goodknits Inc",
// 		"email": "carissa-kidman@noemail.com",
// 		"phone": "555-555-5555",
// 		"leadSource": "Advertisement",
// 		"leadOwner": "Kelvin",
// 	},
// ];


const initialState ={
	name : '',
	company : '',
	email : '',
	phone : '',
	leadSource : '',
	leadOwner : '',
}

const ModuleRecord = () => {

	const { id } = useParams();
	useRedirectLoggedOutUser("/crm/login");

	const [moduleData, setModuleData] = useState([]);
	const [multiSelectOptions, setMultiSelectOptions] = useState([]);
	const [fileUploadArr, setFileUploadArr] = useState([]);
	const [imageUploadArr, setImageUploadArr] = useState([]);
	const [moduleError, setModuleError] = useState([]);
	const [modulePreview, setModulePreview] = useState([]);
	const [moduleArr, setModuleArr] = useState([]);
	const [saving, setSaving] = useState([]);
	const [header, setHeader] = useState([]);
	const [body, setBody] = useState([]);
	const [moduleId, setModuleId] = useState('');
	const [moduleRecords, setModuleRecords] = useState([]);
	const [isClearable, setIsClearable] = useState(true);
	const [isSearchable, setIsSearchable] = useState(true);
	const [loading, setLoading] = useState(false);
	const [leadData, setLeadData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const {name,company,email,phone,leadOwner,leadSource} = leadData;
	const [ViewPost, SetPostShow] = useState(false)
	const [ViewCalendar, SetCalendarShow] = useState(false)
	const [formPreview, setFormPreview] = useState([])
	const [error, setError] = useState([])
	const handleCalendarClose = () => { SetCalendarShow(false) }
	const handlePostClose = () => { SetPostShow(false) }
	const [ViewImportModel, SetImportModelShow] = useState(false)
	const handleImportModelShow = () => { SetImportModelShow(true) }
	const hanldeImportModelClose = () => { SetImportModelShow(false) }
	const [ViewDocModel, SetViewDocModelShow] = useState(false)
	const handleViewDocModelShow = () => { SetViewDocModelShow(true) }
	const hanldeViewDocModelClose = () => { SetViewDocModelShow(false) }
	const [isLoading, setIsLoading] = useState(false);

   const dispatch = useDispatch();

   	const { leads, isSuccess, message } = useSelector(
		(state) => state.lead
	);

	const { moduleItems, moduleItem } = useSelector(
		(state) => state.moduleItem
	);

	useEffect(() => {
	    dispatch(getModules());
	}, [dispatch]);

	const onSaveFormData = async() => {
		setSaving(true)
		var pattern = getPattern(moduleArr)
		var formData = pattern.formData
		setModuleArr(pattern.arr)
		setMultiSelectOptions(pattern.multiSelectOptions)

		await previewArrMap(moduleArr, saving, dispatch).then(res => {
			const { formPreview, error, imageUploadArr, fileUploadArr, objectArr } = res
			setModuleArr(objectArr)
			setModulePreview(formPreview)
			setImageUploadArr(imageUploadArr)
			setFileUploadArr(fileUploadArr)

			for(let x=0; x<formData.length; x++) {
			  var field = formData[x]
			  if(['pickList', 'multiSelect', 'imageUpload'].includes(field.type)) {
				var options = field.options
				if(field.type==='pickList') {
				  for(let a=0; a<options.length; a++) {
					if(options[a].defaultValue) {
					  field.optionId = a
					  field.value = options[a].label
					}
				  }
				} else if(field.type==='multiSelect') {
				  var selectionArr = []
				  for(let a=0; a<options.length; a++) {
					var targetKey = options[a].key
					const option = document.getElementById(targetKey + '-selected')
					if(option) selectionArr.push(targetKey)
				  }
				  field.value = selectionArr
				  // field.value = selectionArr.join(', ');
				} else if(field.type==='imageUpload') {
				  // console.log('imageUploadXXXX')
				}
			  }
			}
		
			// Deep Copy
			const newFormData = JSON.parse(JSON.stringify(formData));
			for(let x=0; x<newFormData.length; x++) {
			  var field = newFormData[x]
			  if(['pickList', 'multiSelect', 'imageUpload'].includes(field.type)) {
				field.options = undefined
			  }
			}
		
			const data = {
				moduleId,
				moduleData: newFormData
			}
	
			if(!error) {
				dispatch(addFormData(data))
				.then(async res => {
				if(imageUploadArr.length>0) {
				  const dataId = res.payload._id
				  for(let m=0; m<imageUploadArr.length; m++) {
					const { elm, objectArr, i, ic, icc } = imageUploadArr[m]
					const fileBArr = elm.options
					const value = elm.value
					const deletedItems = elm.deletedItems
					for(let ix=0; ix<fileBArr.length; ix++) {
					  // console.log(fileBArr[ix].type)
					  const type = fileBArr[ix].type
					  if(type) {
						const name = value[ix].id //dataId + "-" + value[ix].id
						await pixSave(fileBArr[ix], `${1000}|${name}|${destPix.replaceAll("/", "@")}`, dispatch)
					  }
					}
					for(let d=0; d<deletedItems.length; d++) {
					  const name = deletedItems[d].id + '.jpeg' //dataId + "-" + deletedItems[d].id + '.jpeg'
					  await fileDelete({dest: destPix + "/" + name}, dispatch)
					}
				  }
		
				}
				if(fileUploadArr.length>0) {
				  // console.log(fileUploadArr)
				  const dataId = res.payload._id
				  for(let m=0; m<fileUploadArr.length; m++) {
					const { elm, objectArr, i, ic, icc } = fileUploadArr[m]
					const fileBArr = elm.options
					const value = elm.value
					const deletedItems = elm.deletedItems
					for(let ix=0; ix<fileBArr.length; ix++) {
					  const type = fileBArr[ix].type
					  const nameX = fileBArr[ix].name
					  if(type) {
						const name = value[ix].id // dataId + "-" + value[ix].id
						await fileSave(fileBArr[ix], `${name}|${destFile.replaceAll("/", "@")}`, nameX, dataId, destFile, dispatch)
					  }
					}
					for(let d=0; d<deletedItems.length; d++) {
					  const name = `${dataId}-${deletedItems[d].name}.${deletedItems[d].type}`
					  await fileDelete({dest: destFile + "/" + name}, dispatch)
					}
				  }
		
				}
				field.deletedItems = undefined
	
				setModuleId(res.payload._id)
				SetPostShow(false);
				setSaving(false)
				forceUpdate();
			  })
			}
		})

	
	}

	const handleCalendarShow = async () => {
		await SetCalendarShow(true)
	}

	const handlePostShow = async () => {
		var objectArr = JSON.parse(JSON.stringify(moduleData.form))
		setModuleArr(objectArr)
		setModuleId(moduleData._id)
		await SetPostShow(true)
		await previewArrMap(objectArr, false, dispatch).then(async res => {
			const { formPreview, error, multiSelectArr, objectArr } = res
			setModulePreview(formPreview)
			setError(error)
			// this.setState({ formPreview, error })
			setMultiSelect(multiSelectArr)
			setModuleArr(objectArr)
		})
		await SetPostShow(false)
		setTimeout(() => {
			SetPostShow(true)
		}, 1000);
	}

	const removeProfile = async (row) => {
		var data = row.moduleData
		for(let i=0; i<data.length; i++) {
			var type = data[i].type
			if(['imageUpload', 'fileUpload'].includes(type)) {
				var value = data[i].value
				for(let i2=0; i2<value.length; i2++) {
					if(type==='imageUpload'){
						const name = value[i2].id + '.jpeg'
						await fileDelete({dest: destPix + "/" + name}, dispatch)
					} else if(type==='fileUpload') {
						const name = `${value[i2].id}.${value[i2].type}`
						console.log(name)
						await fileDelete({dest: destFile + "/" + name}, dispatch)
					}
				}
			}
		}
	    await dispatch(deleteLead(row._id));
	    dispatch(getLeads());
		forceUpdate();
	};

	const confirmDelete = (row) => {
	    confirmAlert({
	      title: "Delete This Lead",
	      message: "Are you sure to do delete this lead?",
	      buttons: [
	        {
	          label: "Delete",
	          onClick: () => removeProfile(row),
	        },
	        {
	          label: "Cancel",
	          // onClick: () => alert("Click No"),
	        },
	      ],
	    });
	};

const [selectedFile, setSelectedFile] = useState(null);

const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
};

const handleFileUpload = (e) => {
	e.preventDefault();
	const Info = `${id}|${moduleData.userId}`
    const leadData = new FormData();
    leadData.append('file', selectedFile, Info);

    // axios
    //   .post('http://localhost:4000/api/v1/importFile', formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   })
    //   .then((response) => {
    //     console.log(response.data);
    //     // Handle success message or perform any other action on successful upload
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     // Handle error message or perform any other action on failure
    //   });
    dispatch(importFile(leadData));
    SetImportModelShow(false);
	forceUpdate();
    // window.alert('ok');
};

const handleFileDownload = async() => {	
	var body = []
	for(let i=0; i<moduleRecords.length; i++) {
		const id = moduleRecords[i]._id
		var moduleData = moduleRecords[i].moduleData
		moduleData = JSON.parse(JSON.stringify(moduleData));
		var mData = moduleData.sort((a, b) => a.order - b.order);
		// console.log(11, id)
		var dx = {}
		dx.id = id
		for(const [key, value] of Object.entries(mData)) {
			dx[`${value.label}`] = value.value
		}
		body.push(dx)
		// for(let x=0; x<mData.length; x++) {
		// }
	}
	// console.log(88, data)
    dispatch(exportFile({header, body}));
    dispatch(getLeads());
};

	const getHeader = async (data) => {
		// console.log(data)
		
		const header = await data.map (
			(item, i) => (
				  {
					  accessorFn: (row) => row[item.key],
						id: item.key,
						header: item.label,
						Header: () => `${item.label}`,
						isVisible: true,
				  }
			)
		)
		const action = {
			accessorFn: (row) => <span>
								<FaTrashAlt
								  size={18}
								  cursor="pointer"
								  color="rgb(0,0,0,.8)"
								  onClick={() => confirmDelete(row)}
								/>
								&nbsp;
								<Link to={`/crm/edit-record/${row._id}`}>
									<AiFillEdit
									  size={20}
									  cursor="pointer"
									  color="rgb(0,0,0,.8)"
									/>
								</Link>
							  </span>, //alternate way
			id: 'action', //id required if you use accessorFn instead of accessorKey
			header: 'Action',
			Header: () => <span>Action</span>, //optional custom header render
			isVisible: true,
		}
		
		header.push(action)
		// console.log(1111, header)
		setHeader(header)
	}

	useEffect(() => {
		dispatch(getModule(id)).then(res => {
			var mdlData = res.payload
			setModuleData(mdlData)
			dispatch(targetModule(mdlData))
			dispatch(getLeads(id)).then(async res => {
				var data = res.payload
				var pattern = await getPattern(mdlData.form)
				var headerData = pattern.formData.sort((a, b) => a.order - b.order);
				setModuleRecords(data);
				var body = await setData(data, pattern);
				// console.log(headerData)
				setBody(body);
				getHeader(headerData);
				setIsLoading(false);
			});
		})
	}, [dispatch, reducerValue, id, moduleArr, modulePreview, moduleError, formPreview]);


	const [windowHeight, setWindowHeight] = useState({ winHeight: window.innerHeight })

	const detectSize = () =>{
		setWindowHeight({
			winHeight: window.innerHeight 
		})
	}

	useEffect(() => {
	  window.addEventListener('resize', detectSize);
	  return () => {
	    window.removeEventListener('resize', detectSize)
	  }
	}, [windowHeight])

	// const isFirstRender = useRef(true);
	const [columnVisibility, setColumnVisibility] = useState({});

	useEffect(() => {
	    const columnVisibility = localStorage.getItem(
	      'mrt_columnVisibility_table_1',
	    );
	    
	    if (columnVisibility) {
	      setColumnVisibility(JSON.parse(columnVisibility));
	    }
	    
	    // isFirstRender.current = false;
	  }, []);

	useEffect(() => {
	    // if (isFirstRender.current) return;
	    localStorage.setItem(
	      'mrt_columnVisibility_table_1',
	      JSON.stringify(columnVisibility),
	    );
	  }, [columnVisibility]);

	//const file = 'https://www.pqglobaledu.com/files/1709307841517.mp4';
	const file = 'https://www.kbmtr.co.uk/vd/df43tyreyft894ut8dfhg4htu8ef4de4rdgcs.mp4';
	const type = 'mp4';

	return (
		<>
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container reports_sec" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		      	{/*<!--  BEGIN SIDEBAR  -->*/}
	        {/*<div className="sidebar-wrapper sidebar-theme">

	            <nav id="sidebar">
	            
	                <div className="navbar-nav theme-brand flex-row  text-center">
	                    <div className="nav-logo">
	                        
	                        <div className="nav-item theme-text">
	                            <Link to="" className="nav-link"> KBM </Link>
	                        </div>
	                    </div>
	                    <div className="nav-item sidebar-toggle">
	                        <div className="btn-toggle sidebarCollapse">
	                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevrons-left"><polyline points="11 17 6 12 11 7"></polyline><polyline points="18 17 13 12 18 7"></polyline></svg>
	                        </div>
	                    </div>
	                </div>
	                <div className="shadow-bottom"></div>
	                
	                <ul className="list-unstyled menu-categories" id="accordionExample">
	                <li className="mt-4">
	                	<h5>Folders </h5>
	                </li>
	                <li>
	                	<form className="custom-search mb-3">
							<div className="position-relative">
								<input type="text" placeholder="Search" className="form-control" />
								<button><AiOutlineSearch size={18}/></button>
							</div>
						</form>
	                </li>
	                <li className="menu active">
	                    <Link to="" className="dropdown-toggle mb-2">
                            <div className="">
                                <span title="All Reports">All Reports</span>
                            </div>
                        </Link>
                        
                    </li>
	                <li className="menu active">
	                    <Link to="" className="dropdown-toggle mb-2">
                            <div className="">
                                <span title="My Reports">My Reports</span>
                            </div>
                        </Link>
                        
                    </li>
	                </ul>
	                
	            </nav>

	        </div>*/}
	       {/* <!--  END SIDEBAR  -->*/}

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content reportDetail">
		            <div className="layout-px-spacing">
					
		                <div className="middle-content container-xxl p-0">

		                    <div className="row layout-top-spacing">
		                    
		                    <div className="viewModule_rightSide">
								<div className="row">
									<div className="col-md-3 col-12">
										<div className="" style={{width:'200px'}}>
											<Select
												className="basic-single"
												classNamePrefix="All Records"
												defaultValue={options[0]}
												isLoading={loading}
												isClearable={isClearable}
												isSearchable={isSearchable}
												name="color"
												options={options}
											/>
										</div>
									</div>
									<div className="col-md-9 col-12">
										<div className="d-flex justify-content-between align-items-baseline" style={{width:'100%'}}>
											<div className="">
												<h5 className="m-0"><b>{moduleData.title}</b></h5>
											</div>
											<div className="d-flex text-end">
												<div className="d-flex justify-content-end">
													{ moduleData.calendar &&
														<button onClick={() => { handleCalendarShow() }} className="btn btn-secondary mx-1">Calendar</button>
													}
													<button onClick={() => { handleViewDocModelShow() }} className="btn btn-secondary mx-1">Click Me</button>
													<button onClick={() => { handlePostShow() }} className="btn btn-secondary mx-1">Create New</button>
													<div className="dropdown">
														<button className="btn btn-secondary dropdown-toggle mx-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
															Action <AiFillCaretDown style={{height : '12px' , width : '12px'}}/>
														</button>
														<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
															<li><button className="dropdown-item" onClick={() => { handleImportModelShow() }} >Import</button></li>
															<li><button className="dropdown-item" onClick={() => { handleFileDownload() }} >Export</button></li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
													<div className="d-flex justify-content-between align-items-baseline flex-wrap">

														<div className="col-md-3 col-12">
															<div className="moduleRecordFilter" style={{height : windowHeight.winHeight - 210 }}>
																<form className="custom-search">
																	<div className="position-relative">
																		<input type="text" placeholder="Search" className="form-control" />
																		<button><AiOutlineSearch size={18}/></button>
																	</div>
																</form>
																<div className="moduleFilterSec mt-4">
																	<h6><b>System Defined Filters</b></h6>
																	<ul>
																		<li>
																			<input type="checkbox" id="filter1" name="filter1" value="Touched Records"/>
  																		<label htmlFor="filter1">Touched Records</label>
																		</li>
																		<li>
																			<input type="checkbox" id="filter2" name="filter2" value="Untouched Records"/>
  																		<label htmlFor="filter2">Untouched Records</label>
																		</li>
																		<li>
																			<input type="checkbox" id="filter3" name="filter3" value="Record Action"/>
  																		<label htmlFor="filter3">Record Action</label>
																		</li>
																		<li>
																			<input type="checkbox" id="filter4" name="filter4" value="Related Records Action"/>
  																		<label htmlFor="filter4">Related Records Action</label>
																		</li>
																	</ul>
																</div>
																<div className="moduleFilterSec">
																	<h6><b>Website Activity</b></h6>
																	<ul>
																		<li>
																			<input type="checkbox" id="filter5" name="filter5" value="Chats"/>
  																		<label htmlFor="filter5">Chats</label>
																		</li>
																		<li>
																			<input type="checkbox" id="filter6" name="filter6" value="Attended By"/>
  																		<label htmlFor="filter6">Attended By</label>
																		</li>
																		<li>
																			<input type="checkbox" id="filter7" name="filter7" value="Browser"/>
  																		<label htmlFor="filter7">Browser</label>
																		</li>
																		<li>
																			<input type="checkbox" id="filter8" name="filter8" value="Operating System"/>
  																		<label htmlFor="filter8">Operating System</label>
																		</li>
																	</ul>
																</div>
																<div className="moduleFilterSec">
																	<h6><b>Filter By Fields</b></h6>
																	<ul>
																		<li>
																			<input type="checkbox" id="filter9" name="filter9" value="Annual Revenue"/>
  																		<label htmlFor="filter9">Annual Revenue</label>
																		</li>
																		<li>
																			<input type="checkbox" id="filter10" name="filter10" value="City"/>
  																		<label htmlFor="filter10">City</label>
																		</li>
																		<li>
																			<input type="checkbox" id="filter11" name="filter11" value="Company"/>
  																		<label htmlFor="filter11">Company</label>
																		</li>
																		<li>
																			<input type="checkbox" id="filter12" name="filter12" value="Converted Account"/>
  																		<label htmlFor="filter12">Converted Account</label>
																		</li>
																	</ul>
																</div>
															</div>
														</div>	
			                    	<div className="statbox widget box box-shadow moduleData col-md-9 col-12">
	                            <div className="widget-content widget-content-area">
	                            {body.length === 0 ? (
		                                	isLoading
		                                ):(
	                              	<MaterialReactTable 
	                              	data={body}
	                              	columns={header} 
	                              	onColumnVisibilityChange={setColumnVisibility}
		                            enableRowSelection={true}
	                              	options={{
							          filtering: true // Enable filtering globally
							        }}
							        // enableColumnOrdering={true}
							        state={{ columnVisibility }}
								      // muiCircularProgressProps={{
								      //   color: 'secondary',
								      //   thickness: 5,
								      //   size: 55,
								      // }}
								      // muiSkeletonProps={{
								      //   animation: 'pulse',
								      //   height: 28,
								      // }}
	                              	// enableRowNumbers={false} rowNumberMode="original"
	                              	/>
	                            		)
	                            }
	                            </div>
	                      		</div>

                      		</div>
		                    </div>
		                     		
		                     	
		                    	
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		    {/*<!-- Modal -->*/}
		    <Modal show={ViewCalendar}
				onHide={handleCalendarClose}
				backdrop="static"
				keyboard={false}
				className="modal-xl w-100"
			>
				<Modal.Header>
					<h5 className="">Calendar</h5>
				</Modal.Header>
					Calendar ...
					{/* <Calendar /> */}
				<Modal.Footer>
					<div className="col-md-12 mt-1">
						<div className="form-group text-end">
							<button type="button" className="btn btn btn-light-dark mx-3" onClick={handleCalendarClose}><i className="flaticon-cancel-12"></i> Cancel</button>
						</div>
					</div>
				</Modal.Footer>
			</Modal>

			<Modal show={ViewPost}
				onHide={handlePostClose}
				backdrop="static"
				keyboard={false}
				className="modal-xl w-100"
			>
				<Modal.Header>
					<div className="d-flex justify-content-between w-100 align-items-center">
						<h5 className="">Create New Record in {moduleData.title}</h5>
						<div className="form-group text-end">
							<button className="btn btn-secondary _effect--ripple waves-effect waves-light" onClick={() => onSaveFormData()}>Save</button>
							<button type="button" className="btn btn btn-light-dark mx-3" onClick={handlePostClose}><i className="flaticon-cancel-12"></i> Cancel</button>
						</div>
					</div>
				</Modal.Header>
					{modulePreview}
				<Modal.Footer>
					<div className="col-md-12 mt-1">
						<div className="form-group text-end">
							<button className="btn btn-secondary _effect--ripple waves-effect waves-light" onClick={() => onSaveFormData()}>Save</button>
							<button type="button" className="btn btn btn-light-dark mx-3" onClick={handlePostClose}><i className="flaticon-cancel-12"></i> Cancel</button>
						</div>
					</div>
				</Modal.Footer>
			</Modal>
			{/*<!-- Modal -->*/}
		    <Modal show={ViewImportModel}
                    onHide={hanldeImportModelClose}
                    backdrop="static"
                    keyboard={false}
                    className="importFileModal"
                    >
					<h4>Import File</h4>	
				
					<div className="single-form mb-3">
						<input type="file" onChange={handleFileChange}/>
					</div>
					<button onClick={handleFileUpload} className="m-1 btn btn-secondary">Upload</button>
					    
				<div className="form-group text-end">
					<button onClick={hanldeImportModelClose} className="m-1 btn btn-secondary">Close</button>
				</div>
			</Modal>

			{/*<!-- Modal -->*/}
		    <Modal show={ViewDocModel}
                    onHide={hanldeViewDocModelClose}
                    backdrop="static"
                    keyboard={false}
                    className="modal-xl w-100"
                    >
                <div className="d-flex justify-content-between w-100 align-items-center">
					<h4 className="mb-3"><b className="text-uppercase">{type}</b> Document</h4>	
                	<span onClick={hanldeViewDocModelClose}><i className="fa fa-times"></i></span>
                </div>
				<Modal.Body>
					<FileViewer
						style={{width:'300px'}}
						fileType={type}
						filePath={file}
					/>
				</Modal.Body>


				<div className="form-group text-end">
					<button onClick={hanldeViewDocModelClose} className="m-1 btn btn-secondary">Close</button>
				</div>
			</Modal>

		</>
	)
}

export default ModuleRecord