import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import authService from './authService';

const initialState = {
	isLoggedIn: false,
	user: null,
	users: [],
	twoFactor: false,
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: "",
	verifiedUsers: 0,
	suspendedUsers: 0,
};

// register user
export const register = createAsyncThunk (
	"auth/register",
	async (userData, thunkAPI) => {
		try{
			return await authService.register(userData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// login user
export const login = createAsyncThunk (
	"auth/login",
	async (userData, thunkAPI) => {
		try{
			return await authService.login(userData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// logout user
export const logout = createAsyncThunk (
	"auth/logout",
	async (_, thunkAPI) => {
		try{
			return await authService.logout();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get login status
export const getLoginStatus = createAsyncThunk (
	"auth/getLoginStatus",
	async (_, thunkAPI) => {
		try{
			return await authService.getLoginStatus();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// add user
export const addUser = createAsyncThunk (
	"auth/addUser",
	async (userData, thunkAPI) => {
		try{
			return await authService.addUser(userData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//get all
export const getAll = createAsyncThunk (
	"auth/getAll",
	async (_, thunkAPI) => {
		try{
			return await authService.getAll();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//get all users
export const getAllUsers = createAsyncThunk (
	"auth/getAllUsers",
	async (_, thunkAPI) => {
		try{
			return await authService.getAllUsers();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//get all users active
export const getAllUsersActive = createAsyncThunk (
	"auth/getAllUsersActive",
	async (_, thunkAPI) => {
		try{
			return await authService.getAllUsersActive();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//get all users inactive
export const getAllUsersInActive = createAsyncThunk (
	"auth/getAllUsersInActive",
	async (_, thunkAPI) => {
		try{
			return await authService.getAllUsersInActive();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//get all companies
export const getAllCompanies = createAsyncThunk (
	"auth/getAllCompanies",
	async (_, thunkAPI) => {
		try{
			return await authService.getAllCompanies();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get user
export const getUser = createAsyncThunk (
	"auth/getUser",
	async (_, thunkAPI) => {
		try{
			return await authService.getUser();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get user info
export const getUserInfo = createAsyncThunk (
	"auth/getUserInfo",
	async (userData, thunkAPI) => {
		try{
			return await authService.getUserInfo(userData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get users by type
export const getUserType = createAsyncThunk (
	"auth/getUserType",
	async (userData, thunkAPI) => {
		try{
			return await authService.getUserType(userData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// update user
export const updateUser = createAsyncThunk (
	"auth/updateUser",
	async (userData, thunkAPI) => {
		try{
			return await authService.updateUser(userData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// send verification email
export const sendVerificationEmail = createAsyncThunk (
	"auth/sendVerificationEmail",
	async (_, thunkAPI) => {
		try{
			return await authService.sendVerificationEmail();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// verify user
export const verifyUser = createAsyncThunk (
	"auth/verifyUser",
	async (verificationToken, thunkAPI) => {
		try{
			return await authService.verifyUser(verificationToken);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// change password
export const changePassword = createAsyncThunk (
	"auth/changePassword",
	async (userData, thunkAPI) => {
		try{
			return await authService.changePassword(userData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// forgot password
export const forgotPassword = createAsyncThunk (
	"auth/forgotPassword",
	async (userData, thunkAPI) => {
		try{
			return await authService.forgotPassword(userData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// reset password
export const resetPassword = createAsyncThunk (
	"auth/resetPassword",
	async ({userData, resetToken}, thunkAPI) => {
		try{
			return await authService.resetPassword(userData, resetToken);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)


//delete user
export const deleteUser = createAsyncThunk (
	"auth/deleteUser",
	async (id, thunkAPI) => {
		try{
			return await authService.deleteUser(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//delete EMS users
export const deleteEmsUsers = createAsyncThunk (
	"auth/deleteEmsUsers",
	async (_, thunkAPI) => {
		try{
			return await authService.deleteEmsUsers();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//update EMS users
export const updateEmsUsers = createAsyncThunk (
	"auth/updateEmsUsers",
	async (_, thunkAPI) => {
		try{
			return await authService.updateEmsUsers();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// upgrade user
export const upgradeUser = createAsyncThunk (
	"auth/upgradeUser",
	async (userData, thunkAPI) => {
		try{
			return await authService.upgradeUser(userData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// sendLoginCode
export const sendLoginCode = createAsyncThunk (
	"auth/sendLoginCode",
	async (email, thunkAPI) => {
		try{
			return await authService.sendLoginCode(email);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// loginWithCode
export const loginWithCode = createAsyncThunk (
	"auth/loginWithCode",
	async ({code, email}, thunkAPI) => {
		try{
			return await authService.loginWithCode(code, email);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// loginWithGoogle
export const loginWithGoogle = createAsyncThunk (
	"auth/loginWithGoogle",
	async (userToken, thunkAPI) => {
		try{
			return await authService.loginWithGoogle(userToken);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get user id
export const getSingleUserId = createAsyncThunk (
	"auth/getSingleUserId",
	async (id, thunkAPI) => {
		try{
			return await authService.getSingleUserId(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get company users by id
export const getCompanyUsers = createAsyncThunk (
	"auth/getCompanyUsers",
	async (id, thunkAPI) => {
		try{
			return await authService.getCompanyUsers(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// update user is
export const updateUserId = createAsyncThunk (
	"auth/updateUserId",
	async ({id, userData}, thunkAPI) => {
		try{
			return await authService.updateUserId(id, userData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

const authSlice = createSlice ({
	name: 'auth',
	initialState,
	reducers: {
		RESET(state){
			state.twoFactor = false;
			state.isError = false;
			state.isSuccess = false;
			state.isLoading = false;
			state.message = '';
		},
		CALC_VERIFIED_USER(state, action) {
	      const array = [];
	      state.users.map((user) => {
	        const { isVerified } = user;
	        return array.push(isVerified);
	      });
	      let count = 0;
	      array.forEach((item) => {
	        if (item === true) {
	          count += 1;
	        }
	      });
	      state.verifiedUsers = count;
		},
		CALC_SUSPENDED_USER(state, action) {
	      const array = [];
	      state.users.map((user) => {
	        const { role } = user;
	        return array.push(role);
	      });
	      let count = 0;
	      array.forEach((item) => {
	        if (item === "suspended") {
	          count += 1;
	        }
	      });
	      state.suspendedUsers = count;
	    },
	    // SET_LOGIN(state, action) {
	    //   state.isLoggedIn = action.payload;
	    // },
	    // SET_NAME(state, action) {
	    //   localStorage.setItem("name", JSON.stringify(action.payload));
	    //   state.name = action.payload;
	    // },
	    // SET_USER(state, action) {
	    //   const profile = action.payload;
	    //   state.user.name = profile.name;
	    //   state.user.email = profile.email;
	    //   state.user.phone = profile.phone;
	    //   state.user.bio = profile.bio;
	    //   state.user.photo = profile.photo;
	    // },
	    
	},
	extraReducers: (builder) =>{
		builder
			//register user
			.addCase(register.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(register.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isLoggedIn = true;
				state.user = action.payload;
				toast.success("Registration Successful");
			})
			.addCase(register.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				state.user = null;
				toast.error(action.payload);
			})

			//login user
			.addCase(login.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(login.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isLoggedIn = true;
				state.user = action.payload;
				toast.success("Login Successful");
			})
			.addCase(login.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				state.user = null;
				toast.error(action.payload);
				if (action.payload.includes("New browser")) {
		          state.twoFactor = true;
		        }
			})

			//logout user
			.addCase(logout.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(logout.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isLoggedIn = false;
				state.user = null;
				toast.success(action.payload);
			})
			.addCase(logout.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get login status
			.addCase(getLoginStatus.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getLoginStatus.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isLoggedIn = action.payload;
			})
			.addCase(getLoginStatus.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			//add user
			.addCase(addUser.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(addUser.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				// state.isLoggedIn = false;
				// state.isError = false;
				// state.user = action.payload;
				toast.success('Add Successful');
			})
			.addCase(addUser.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			// get user
			.addCase(getUser.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getUser.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isLoggedIn = true;
				state.user = action.payload;
			})
			.addCase(getUser.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			// get user info
			.addCase(getUserInfo.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getUserInfo.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isLoggedIn = true;
				state.user = action.payload;
				toast.success("Users founded");
			})
			.addCase(getUserInfo.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			// get users by type
			.addCase(getUserType.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getUserType.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isLoggedIn = true;
				state.user = action.payload;
				toast.success("Users founded");
			})
			.addCase(getUserType.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//update user 
			.addCase(updateUser.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(updateUser.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isLoggedIn = true;
				state.user = action.payload;
				toast.success("User is Updated");
			})
			.addCase(updateUser.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//send verification email
			.addCase(sendVerificationEmail.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(sendVerificationEmail.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(sendVerificationEmail.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//verify user
			.addCase(verifyUser.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(verifyUser.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(verifyUser.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//chnage password
			.addCase(changePassword.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(changePassword.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(changePassword.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//forgot password
			.addCase(forgotPassword.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(forgotPassword.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(forgotPassword.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//reset password
			.addCase(resetPassword.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(resetPassword.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(resetPassword.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get all users
			.addCase(getAll.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getAll.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.users = action.payload;
			})
			.addCase(getAll.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get all users
			.addCase(getAllUsers.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getAllUsers.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.users = action.payload;
			})
			.addCase(getAllUsers.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get all users active
			.addCase(getAllUsersActive.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getAllUsersActive.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.users = action.payload;
			})
			.addCase(getAllUsersActive.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get all users inactive
			.addCase(getAllUsersInActive.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getAllUsersInActive.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.users = action.payload;
			})
			.addCase(getAllUsersInActive.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get all companies
			.addCase(getAllCompanies.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getAllCompanies.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.users = action.payload;
			})
			.addCase(getAllCompanies.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//delete user
			.addCase(deleteUser.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(deleteUser.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(deleteUser.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//delete EMS users
			.addCase(deleteEmsUsers.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(deleteEmsUsers.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(deleteEmsUsers.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//upgrade user
			.addCase(upgradeUser.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(upgradeUser.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(upgradeUser.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//send login code
			.addCase(sendLoginCode.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(sendLoginCode.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(sendLoginCode.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//login with code
			.addCase(loginWithCode.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(loginWithCode.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isLoggedIn = true;
				state.twoFactor = false;
				state.user = action.payload;
				toast.success(action.payload);
			})
			.addCase(loginWithCode.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				state.user = null;
				toast.error(action.payload);
			})

			//login with google
			.addCase(loginWithGoogle.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(loginWithGoogle.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isLoggedIn = true;
				state.user = action.payload;
				toast.success('Login Successful');
			})
			.addCase(loginWithGoogle.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				state.user = null;
				toast.error(action.payload);
			})

			//get user id
			.addCase(getSingleUserId.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getSingleUserId.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				// state.isLoggedIn = false;
				state.users = action.payload;
			})
			.addCase(getSingleUserId.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get company users by id
			.addCase(getCompanyUsers.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getCompanyUsers.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.users = action.payload;
			})
			.addCase(getCompanyUsers.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//update user is
			.addCase(updateUserId.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(updateUserId.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				// state.isLoggedIn = false;
				state.users = action.payload;
				toast.success("User is Updated");
			})
			.addCase(updateUserId.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})
	}
});

export const { RESET, CALC_VERIFIED_USER, CALC_SUSPENDED_USER } = authSlice.actions;
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectUser = (state) => state.auth.user;

export default authSlice.reducer;