import React from 'react';
import KbmOneSidebar from '../components/KbmOneSidebar';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";

const KbmOneDashboard = () => {

	useRedirectLoggedOutUser("/login");

	return (
		<>
			
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container kbmone" id="container">

		    		<div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <KbmOneSidebar />

		        <div id="content"  className="main-content">
		        	<div className="layout-px-spacing">
			          	<div className="middle-content container-xxl p-0">      
				          	<div className="row layout-top-spacing">
				        	
				        	</div>
			        	</div>
		        	</div>
		        </div>

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		</>
	)
}

export default KbmOneDashboard