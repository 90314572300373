import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import layoutService from './layoutService';

const initialState = {
	layout: null,
	layouts: [],
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: "",
};

// add user profile
export const addLayout = createAsyncThunk (
	"layout/addLayout",
	async (layoutData, thunkAPI) => {
		try{
			return await layoutService.addLayout(layoutData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//get all users profiles
export const getLayouts = createAsyncThunk (
	"layout/getLayouts",
	async (_, thunkAPI) => {
		try{
			return await layoutService.getLayouts();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get user profile
export const getLayout = createAsyncThunk (
	"layout/getLayout",
	async (id, thunkAPI) => {
		try{
			return await layoutService.getLayout(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// update user profile
export const updateLayout = createAsyncThunk (
	"layout/updateLayout",
	async ({id, layoutData}, thunkAPI) => {
		try{
			return await layoutService.updateLayout(id, layoutData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//delete user profile
export const deleteLayout = createAsyncThunk (
	"layout/deleteLayout",
	async (id, thunkAPI) => {
		try{
			return await layoutService.deleteLayout(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

const layoutSlice = createSlice ({
	name: 'layout',
	initialState,
	reducers: {
		
	},
	extraReducers: (builder) =>{
		builder
			
			//add user profile
			.addCase(addLayout.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(addLayout.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.layouts = action.payload;
				toast.success('Layout Add Successful');
			})
			.addCase(addLayout.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get user profile
			.addCase(getLayouts.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getLayouts.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.layouts = action.payload;
			})
			.addCase(getLayouts.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//update user profile
			.addCase(updateLayout.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(updateLayout.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.layout = action.payload;
				toast.success("Layout is Updated");
			})
			.addCase(updateLayout.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get all users profiles
			.addCase(getLayout.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getLayout.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.layouts = action.payload;
			})
			.addCase(getLayout.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//delete user profile
			.addCase(deleteLayout.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(deleteLayout.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(deleteLayout.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

	}
});

export const selectLayout = (state) => state.layout.layout;

export default layoutSlice.reducer;