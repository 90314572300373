import React,{useEffect,useState,useRef,useMemo,useReducer} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit,AiOutlineEllipsis } from "react-icons/ai";
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { toast } from "react-toastify";
import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import {
	addProfile,
  	getProfiles,
  	deleteProfile,
} 	from "../../redux/crm-features/userProfile/profileSlice";

const initialState ={
	name : '',
	cloneProfile : '',
	desc : '',
}

const SecurityControl = () => {
	
	useRedirectLoggedOutUser("/crm/login");

	const [profileData, setProfileData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const {name,cloneProfile,desc} = profileData;
	const [ViewPost, SetPostShow] = useState(false)
    const handlePostShow = () => { SetPostShow(true) }
    const hanldePostClose = () => { SetPostShow(false) }

    const dispatch = useDispatch();
	// const navigate = useNavigate();

    const { userProfiles, isLoading, isSuccess, message } = useSelector(
	    (state) => state.userProfile
	);

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setProfileData({...profileData, [name]: value});
	}

	const profileAdd = async (e) => {
		e.preventDefault();

		if (!name || !cloneProfile){
			return toast.error('All fields are required')
		}

		const profileData = {name, cloneProfile, desc};
		// console.log(userData);
		await dispatch(addProfile(profileData));
		setProfileData({ name: "", cloneProfile:"", desc: "" });
		SetPostShow(false);
		forceUpdate();
		// navigate('/users-list');
		// window.location.reload();
		// await dispatch(sendVerificationEmail());
	};

	useEffect(() => {
	    dispatch(getProfiles());
	}, [dispatch, reducerValue]);

	const removeProfile = async (id) => {
	    await dispatch(deleteProfile(id));
	    dispatch(getProfiles());
	};

	const confirmDelete = (id) => {
	    confirmAlert({
	      title: "Delete This Profile",
	      message: "Are you sure to do delete this profile?",
	      buttons: [
	        {
	          label: "Delete",
	          onClick: () => removeProfile(id),
	        },
	        {
	          label: "Cancel",
	          // onClick: () => alert("Click No"),
	        },
	      ],
	    });
	};

const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'Profile Name',
        Header: () => <span>Profile Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          // <span className=""><Link to={`/crm/profile-setting`}><b>{renderedCellValue}</b></Link></span>
          <span className="d-flex align-items-center"><Link to={`/crm/profile-setting`}>{renderedCellValue}</Link>
            <div className="dropdown">
              <span className="dropdown-toggle mx-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <AiOutlineEllipsis />
              </span>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><Link className="dropdown-item" to={`/crm/edit-profile/${row.original._id}`}>Edit</Link></li>
                <li><Link className="dropdown-item" to="#" onClick={() => confirmDelete(row.original._id)}>Delete</Link></li>
              </ul>
            </div>
          </span>
        ),
      },
      {
        accessorFn: (row) => row.desc, //alternate way
        id: 'desc', //id required if you use accessorFn instead of accessorKey
        header: 'Profile Description',
        Header: () => <span>Profile Description</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.createdAt.toLocaleString("en-US"), //alternate way
        id: 'createdAt', //id required if you use accessorFn instead of accessorKey
        header: 'Created At',
        Header: () => <span>Created At</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.updatedAt, //alternate way
        id: 'updatedAt', //id required if you use accessorFn instead of accessorKey
        header: 'Modified At',
        Header: () => <span>Modified At</span>, //optional custom header render
      },
      // {
      //   accessorFn: (row) => <span>
      //                       <FaTrashAlt
      //                         size={18}
      //                         cursor="pointer"
      //                         color="rgb(0,0,0,.8)"
      //                         onClick={() => confirmDelete(row._id)}
      //                       />
      //                       &nbsp;
      //                       <Link to={`/crm/edit-profile/${row._id}`}>
	     //                        <AiFillEdit
	     //                          size={20}
	     //                          cursor="pointer"
	     //                          color="rgb(0,0,0,.8)"
	     //                        />
      //                       </Link>
      //                     </span>, //alternate way
      //   id: 'action', //id required if you use accessorFn instead of accessorKey
      //   header: 'Action',
      //   Header: () => <span>Action</span>, //optional custom header render
      // },
    ],
    [],
  );

	const isFirstRender = useRef(true);
	const [columnVisibility, setColumnVisibility] = useState({});

	useEffect(() => {
	    const columnVisibility = localStorage.getItem(
	      'mrt_columnVisibility_table_1',
	    );
	    
	    if (columnVisibility) {
	      setColumnVisibility(JSON.parse(columnVisibility));
	    }
	    
	    isFirstRender.current = false;
	  }, []);

	useEffect(() => {
	    if (isFirstRender.current) return;
	    localStorage.setItem(
	      'mrt_columnVisibility_table_1',
	      JSON.stringify(columnVisibility),
	    );
	  }, [columnVisibility]);

	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">
		                        
		                        <div className="tabMenu">
		                        	<ul>
		                        		<li><Link className="btn btn-secondary active" to="/crm/security-control">Profiles</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/user-role">User Roles</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/data-sharing-setting">Data Sharing Settings</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm">Zoho Mail Add-On Users</Link></li>
		                        	</ul>
		                        </div>

		                        <div className="col-12 text-end mt-4">
		                        	<button onClick={() => { handlePostShow() }} type="button" className="btn btn-secondary">Add New Profile</button>
		                        </div>

		                        <div className="col-12">
		                        	<div className="statbox widget box box-shadow moduleData">
		                                <div className="widget-content widget-content-area">
		                                {userProfiles.length === 0 ? (
		                                	<p>Profile not found...</p>
		                                ):(
		                                	<MaterialReactTable 
		                                	onColumnVisibilityChange={setColumnVisibility}
                                      state={{columnVisibility}}
                                      enableRowSelection={true}
		                                	columns={columns} 
		                                	data={userProfiles} 
		                                	// enableRowNumbers rowNumberMode="original"
		                                	/>
		                                )}
		                                </div>
		                            </div>
		                        </div>
		                        
		                       
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    {/*<!-- Modal -->*/}
		    <Modal show={ViewPost}
                    onHide={hanldePostClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
			
			            	<form className="form" onSubmit={profileAdd}>
			            	<h5 className="modal-title">Add Profile</h5>
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control mb-3" id="name" placeholder="Profile Name" name="name" value={name} onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                   <div className="col-md-12">
                                    	<select value={cloneProfile} onChange={handleInputChange} name="cloneProfile" className="form-select form-control-sm mb-3">
								     		<option>Clone Profile</option>
								     		<option value="admin">Admin</option>
								     		<option value="author">Author</option>
								     		<option value="client">Client</option>
								     		<option value="suspended">Suspended</option>
								     	</select>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                        	<textarea type="text" className="form-control mb-3" name="desc" id="phone" placeholder="Profile Description" value={desc} onChange={handleInputChange}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-1">
                                        <div className="form-group text-end">
                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
			                				<button type="button" className="btn btn btn-light-dark mx-3" onClick={hanldePostClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
			            
			</Modal>

		</>
	);
}

export default SecurityControl