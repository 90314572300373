import React,{useState,useEffect,useReducer,useLayoutEffect} from 'react';
import { Link,useParams,useNavigate } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { toast } from "react-toastify";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import {AiOutlineLeft} from 'react-icons/ai';
import { useDispatch, useSelector } from "react-redux";
import {
  	getModules,
  	// selectModuleItem,
  	// getModule,
} 	from "../../redux/crm-features/moduleItem/moduleSlice";
import {
  	getLayout,
	getLayouts,
	updateLayout,
} 	from "../../redux/crm-features/layout/layoutSlice";


const EditLayout = () => {

	useRedirectLoggedOutUser("/crm/login");

	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();

	const { moduleItems } = useSelector(
	    (state) => state.moduleItem
	);

	useEffect(() => {
	    dispatch(getModules());
	    // dispatch(getModule(id));
	}, [dispatch]);


	const { layouts, isLoading, isSuccess, message } = useSelector(
	    (state) => state.layout
	);

	const initialState ={
	  	name: layouts?.name || "",
	  	status: layouts?.status || "",
	}

	useEffect(() => {
	    dispatch(getLayout(id));
	}, [dispatch, id]);

	const [formData, setFormData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const {name,status} = formData;

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setFormData({...formData, [name]: value});
	}

	const layoutEdit = async (e) => {
		e.preventDefault();

		const layoutData = {
			name: formData.name,  
			status: formData.status, 
		};
		
		await dispatch(updateLayout({id, layoutData}));
		await dispatch(getLayouts());
		forceUpdate();
		navigate('/crm/modules');
		// window.location.reload();
		// await dispatch(sendVerificationEmail());
	};

	useLayoutEffect(() => {
	    if (layouts) {
	      setFormData({
	        ...formData,
	        name: layouts.name,
	        status: layouts.status,
	      });
	    }
	}, [layouts]);

	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        {/*<!--  BEGIN SIDEBAR  -->*/}
	        <div className="sidebar-wrapper sidebar-theme">

	            <nav id="sidebar">
	            {/*<CustomSidebar />
*/}
	                <div className="navbar-nav theme-brand flex-row  text-center">
	                    <div className="nav-logo">
	                        
	                        <div className="nav-item theme-text">
	                            <Link to="/crm" className="nav-link"> KBM </Link>
	                        </div>
	                    </div>
	                    <div className="nav-item sidebar-toggle">
	                        <div className="btn-toggle sidebarCollapse">
	                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevrons-left"><polyline points="11 17 6 12 11 7"></polyline><polyline points="18 17 13 12 18 7"></polyline></svg>
	                        </div>
	                    </div>
	                </div>
	                <div className="shadow-bottom"></div>
	                
	                <ul className="list-unstyled menu-categories" id="accordionExample">
	                <li className="mt-4">
	                	<h5><Link to="/crm/modules"><AiOutlineLeft size={18}/>Modules </Link></h5>
	                </li>
	                {
		        		moduleItems.map((item,index)=>{
		        			return (
	                    <li key={index} className="menu active">
	                        <Link to={`/crm/module/${item._id}`} className="dropdown-toggle mb-2">
	                            <div className="">
	                                <span title="{item.title}">{item.title}</span>
	                            </div>
	                        </Link>
	                        
	                    </li>
	                    )
		        		})
		        	}

	                </ul>
	                
	            </nav>

	        </div>
	       {/* <!--  END SIDEBAR  -->*/}


		        {/*<Sidebar />*/}

		        {/*<div className="moduleSideBar">
		        	<h5><Link to="/modules"><AiOutlineLeft size={18}/>Modules </Link></h5>
		        	<ul>
		        	{
		        		moduleItems.map((item,index)=>{
		        			return (
		        					<li key={index}><Link to={`/module/${item._id}`}>{item.title}</Link></li>
		        			)
		        		})
		        	}
		        	</ul>
		        </div>*/}

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">
		                    	
		                    	<div className="col-12">
		                    		<div className="d-flex align-items-center justify-content-between">
		                    			<h5 className="m-0">Edit Module Layout</h5>
		                    			<Link to={`/crm/module/${id}`} className="v-link btn btn-secondary _effect--ripple waves-effect waves-light">Back</Link>
		                    		</div>
		                    		<div className="statbox widget box box-shadow">
		                                <div className="widget-content widget-content-area">
		                                	<form className="form" onSubmit={layoutEdit}>
				                                <div className="row mt-4">
				                                    <div className="col-md-12">
				                                        <div className="form-group">
				                                            <input type="text" className="form-control mb-3" id="name" placeholder="Name" name="name" value={formData?.name} onChange={handleInputChange}/>
				                                        </div>
				                                    </div>
				                                    <div className="col-md-12">
				                                        <select value={formData?.status} onChange={handleInputChange} name="status" className="form-select form-control-sm mb-3">
												     		{/*<option>{formData?.status}</option>*/}
												     		<option value="true">True</option>
												     		<option value="false">False</option>
												     	</select>
				                                    </div>
				                                    <div className="col-md-12 mt-1">
				                                        <div className="form-group text-end">
				                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
				                                        </div>
				                                    </div>
				                                </div>
				                            </form>
		                                </div>
		                            </div>
		                    	</div>
		                    	
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		</>
	);
}

export default EditLayout