import React,{useEffect,useRef,useState,useMemo,useReducer} from 'react';
import ReactDOM from 'react-dom';
import { FaCheck } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit,AiOutlineEllipsis } from "react-icons/ai";
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { Link,useNavigate,useParams } from 'react-router-dom'; 
import { toast } from "react-toastify";
import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyUsers,
  // getAllUsers,
  deleteUser,
} from "../../redux/crm-features/auth/authSlice";

import {
  	getRoles
} 	from "../../redux/crm-features/userRole/roleSlice";

const CompanyDetailsById = () => {
	useRedirectLoggedOutUser("/crm/login");

  const { id } = useParams();
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { users, isLoading, isSuccess, message } = useSelector(
	    (state) => state.auth
	);

	useEffect(() => {
	    dispatch(getCompanyUsers(id));
	    // dispatch(getAllUsers());
	}, [dispatch, reducerValue]);

	const removeUser = async (id) => {
	    await dispatch(deleteUser(id));
	    dispatch(getCompanyUsers(id));
	};

	const confirmDelete = (id) => {
	    confirmAlert({
	      title: "Delete This Field",
	      message: "Are you sure to do delete this ?",
	      buttons: [
	        {
	          label: "Delete",
	          onClick: () => removeUser(id),
	        },
	        {
	          label: "Cancel",
	          // onClick: () => alert("Click No"),
	        },
	      ],
	    });
	};

const columns = useMemo(
    () => [
      
      {
        accessorFn: (row) => `${row.name}`, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'First Name',
        Header: () => <span>First Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          // <span className="textUppercase">{renderedCellValue}</span>
          <span className="d-flex align-items-center">{renderedCellValue}
		        <div className="dropdown">
						  <span className="dropdown-toggle mx-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						    <AiOutlineEllipsis />
						  </span>
						  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						    <li><Link className="dropdown-item" to={`/crm/edit-user/${row.original._id}`}>Edit</Link></li>
						    <li><Link className="dropdown-item" to="#" onClick={() => confirmDelete(row.original._id)}>Delete</Link></li>
						  </ul>
						</div>
		      </span>
        ),
      },
      {
        accessorFn: (row) => <span className="textUppercase">{row.lname}</span>, //alternate way
        id: 'lname', //id required if you use accessorFn instead of accessorKey
        header: 'Last Name',
        Header: () => <span>Last Name</span>, //optional custom header render
      },
      {
        accessorFn: (row) => <img width = '70px' src={row.photo}/>, //alternate way
        id: 'photo', //id required if you use accessorFn instead of accessorKey
        header: 'Photo',
        Header: () => <span>Photo</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.email, //alternate way
        id: 'email', //id required if you use accessorFn instead of accessorKey
        header: 'Email',
        Header: () => <span>Email</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.phone, //alternate way
        id: 'phone', //id required if you use accessorFn instead of accessorKey
        header: 'Phone',
        Header: () => <span>Phone</span>, //optional custom header render
      },
      
      
      // {
      //   accessorFn: (row) => <span>
      //                       <FaTrashAlt
      //                         size={18}
      //                         cursor="pointer"
      //                         color="rgb(0,0,0,.8)"
      //                         onClick={() => confirmDelete(row._id)}
      //                       />
      //                       &nbsp;
      //                       {/*<Link to={`/crm/edit-user/${row._id}`}>
	     //                        <AiFillEdit
	     //                          size={20}
	     //                          cursor="pointer"
	     //                          color="rgb(0,0,0,.8)"
	     //                        />
      //                       </Link>*/}
      //                     </span>, //alternate way
      //   id: 'action', //id required if you use accessorFn instead of accessorKey
      //   header: 'Action',
      //   Header: () => <span>Action</span>, //optional custom header render
      // },
    ],
    [],
  );

const isFirstRender = useRef(true);
	const [columnVisibility, setColumnVisibility] = useState({});

	useEffect(() => {
	    const columnVisibility = localStorage.getItem(
	      'mrt_columnVisibility_table_1',
	    );
	    
	    if (columnVisibility) {
	      setColumnVisibility(JSON.parse(columnVisibility));
	    }
	    
	    isFirstRender.current = false;
	  }, []);

	useEffect(() => {
	    if (isFirstRender.current) return;
	    localStorage.setItem(
	      'mrt_columnVisibility_table_1',
	      JSON.stringify(columnVisibility),
	    );
	  }, [columnVisibility]);

	return (
		<div>

		{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">

			                    {/*content*/}


			                    <div className="col-lg-12">
			                    	<div className="col-12">
			                    		<div className="d-flex align-items-center justify-content-between">
			                    			<h5 className="m-0">Companies Data</h5>
			                    			<Link className="btn btn-secondary" to={`/crm/company-data/${id}`}>Back</Link>
			                    			{/*<button onClick={() => { handlePostShow() }} className="v-link btn btn-secondary _effect--ripple waves-effect waves-light">Add Company</button>*/}
			                    		</div>
			                    	</div>
			                    	{/*<div className="row mt-4">
				                    	<div className="col-md-4 col-sm-6 col-12">
				                    		<div className="stateBox">
				                    		<Link to="/">
				                    			<h4>User</h4>
				                    			<span><b>2</b></span>
				                    		</Link>
				                    		</div>
				                    	</div>
				                    	<div className="col-md-4 col-sm-6 col-12">
				                    		<div className="stateBox">
				                    		<Link to="/">
				                    			<h4>Modules</h4>
				                    			<span><b>6</b></span>
				                    		</Link>
				                    		</div>
				                    	</div>
				                    	<div className="col-md-4 col-sm-6 col-12">
				                    		<div className="stateBox">
				                    		<Link to="/">
				                    			<h4>User</h4>
				                    			<span><b>2</b></span>
				                    		</Link>
				                    		</div>
				                    	</div>	                    		
			                    	</div>*/}
		                            <div className="statbox widget box box-shadow moduleData">
		                                <div className="widget-content widget-content-area">
		                                {users.length === 0 ? (
		                                	<p>Companies not found...</p>
		                                ):(
		                                	<MaterialReactTable 
		                                	columns={columns} 
		                                	data={users} 
		                                	onColumnVisibilityChange={setColumnVisibility}
		                            			state={{columnVisibility}}
		                                	// enableRowNumbers 
		                                	// rowNumberMode="original"
		                                	enableRowSelection={true}/>
		                                )}
		                                </div>
		                            </div>
	                        	</div>
	                        	

			                    {/*content*/}

		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		</div>
	)
}

export default CompanyDetailsById