import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import profileService from './profileService';

const initialState = {
	userProfile: null,
	userProfiles: [],
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: "",
};

// add user profile
export const addProfile = createAsyncThunk (
	"userProfile/addProfile",
	async (profileData, thunkAPI) => {
		try{
			return await profileService.addProfile(profileData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//get all users profiles
export const getProfiles = createAsyncThunk (
	"userProfile/getProfiles",
	async (_, thunkAPI) => {
		try{
			return await profileService.getProfiles();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)


// get user profile
export const getProfile = createAsyncThunk (
	"userProfile/getProfile",
	async (id, thunkAPI) => {
		try{
			return await profileService.getProfile(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// update user profile
export const updateProfile = createAsyncThunk (
	"userProfile/updateProfile",
	async ({id, profileData}, thunkAPI) => {
		try{
			return await profileService.updateProfile(id, profileData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//delete user profile
export const deleteProfile = createAsyncThunk (
	"userProfile/deleteProfile",
	async (id, thunkAPI) => {
		try{
			return await profileService.deleteProfile(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

const profileSlice = createSlice ({
	name: 'userProfile',
	initialState,
	reducers: {
		
	},
	extraReducers: (builder) =>{
		builder
			
			//add user profile
			.addCase(addProfile.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(addProfile.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.userProfiles = action.payload;
				toast.success('Profile Add Successful');
			})
			.addCase(addProfile.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get user profile
			.addCase(getProfile.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getProfile.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.userProfiles = action.payload;
			})
			.addCase(getProfile.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//update user profile
			.addCase(updateProfile.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(updateProfile.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.userProfile = action.payload;
				toast.success("Profile is Updated");
			})
			.addCase(updateProfile.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get all users profiles
			.addCase(getProfiles.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getProfiles.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.userProfiles = action.payload;
			})
			.addCase(getProfiles.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//delete user profile
			.addCase(deleteProfile.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(deleteProfile.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(deleteProfile.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

	}
});

export const selectUserProfile = (state) => state.userProfile.userProfile;

export default profileSlice.reducer;