import React,{useState,useEffect,useRef,useMemo} from 'react';
import { Link } from "react-router-dom";
import Footer from '../components/Footer';
import { AiOutlineSearch,AiOutlineEllipsis } from "react-icons/ai";
import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { Modal } from 'react-bootstrap';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";

const layouts = [
	{
		name: 'Vendors vs. Purchases',

		desc: 'Vendors Based on Purchases',

		folder: 'Vendor Reports',

		accessedDate: '44 min ago',
		
		createBy: '',
	},
];

const Reports = () => {

	useRedirectLoggedOutUser("/crm/login");

	const [ViewPost, SetPostShow] = useState(false)
    const handlePostShow = () => { SetPostShow(true) }
    const hanldePostClose = () => { SetPostShow(false) }

	const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'Report Name',
        Header: () => <span>Report Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          // <span className=""><Link to={`/crm/report/id`}><b>{renderedCellValue}</b></Link></span>
          <span className="d-flex align-items-center"><Link to={`/crm/report/id`}>{renderedCellValue}</Link>
            <div className="dropdown">
              <span className="dropdown-toggle mx-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <AiOutlineEllipsis />
              </span>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><Link className="dropdown-item" to={`#`}>Edit</Link></li>
                <li><Link className="dropdown-item" to="#">Delete</Link></li>
              </ul>
            </div>
          </span>
        ),
      },
      {
        accessorFn: (row) => row.desc, //alternate way
        id: 'desc', //id required if you use accessorFn instead of accessorKey
        header: 'Description',
        Header: () => <span>Description</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.folder, //alternate way
        id: 'folder', //id required if you use accessorFn instead of accessorKey
        header: 'Folder',
        Header: () => <span>Folder</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.accessedDate, //alternate way
        id: 'accessedDate', //id required if you use accessorFn instead of accessorKey
        header: 'Last Accessed Date',
        Header: () => <span>Last Accessed Date</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.createBy, //alternate way
        id: 'createBy', //id required if you use accessorFn instead of accessorKey
        header: 'Created By',
        Header: () => <span>Created By</span>, //optional custom header render
      },
    ],
    [],
  );

  const isFirstRender = useRef(true);
	const [columnVisibility, setColumnVisibility] = useState({});

	useEffect(() => {
	    const columnVisibility = localStorage.getItem(
	      'mrt_columnVisibility_table_1',
	    );
	    
	    if (columnVisibility) {
	      setColumnVisibility(JSON.parse(columnVisibility));
	    }
	    
	    isFirstRender.current = false;
	  }, []);

	useEffect(() => {
	    if (isFirstRender.current) return;
	    localStorage.setItem(
	      'mrt_columnVisibility_table_1',
	      JSON.stringify(columnVisibility),
	    );
	  }, [columnVisibility]);

	return (
		<>
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container reports_sec" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <div className="reportSidebar">
			        <h5>Folder</h5>
			      	<form className="custom-search mb-3">
								<div className="position-relative">
									<input type="text" placeholder="Search" className="form-control" />
									<button><AiOutlineSearch size={18}/></button>
								</div>
							</form>
							<div style={{ display: "flex", height: "100vh", position: 'sticky', top: 0 }}>

							<Sidebar className="">
				        <Menu>
				          <MenuItem component={<Link to="/crm/company-detail" className="link" />}> All Reports </MenuItem>
				          <MenuItem component={<Link to="/crm/company-detail" className="link" />}> My Reports </MenuItem>
				        </Menu>
				      </Sidebar>
								
							</div>
		        </div>

		      		

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">
					
		                <div className="middle-content container-xxl p-0">

		                    
		                    <div className="row layout-top-spacing">
		                    
		                    <div className="viewModule_rightSide">
		                    	<div className="d-flex justify-content-between align-items-baseline flex-wrap">
														<div className="col-md-4 col-12">
															<h5>All Reports</h5>
														</div>
														<button onClick={() => { handlePostShow() }} className="btn btn-secondary">Create Report</button>
													</div>
		                    	<div className="statbox widget box box-shadow moduleData">
                              <div className="widget-content widget-content-area">
                              
                              	<MaterialReactTable 
                              	onColumnVisibilityChange={setColumnVisibility}
                                      state={{columnVisibility}}
                                      enableRowSelection={true}
                              	columns={columns} 
                              	data={layouts} 
                              	// enableRowNumbers rowNumberMode="original"
                              	/>
                              
                              </div>
                          </div>
		                    </div>
		                     		
		                     	
		                    	
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    {/*<!-- Modal -->*/}
		    <Modal show={ViewPost}
                    onHide={hanldePostClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
								<h4 className="mb-3">Create New Report</h4>
								<h5>Select Primary Module</h5>
								<p>
									Select the module that you would like to create a report for. For example, if you want to see all Contacts and the Deals added to them in the last month, select Contacts as the primary module.
								</p>
								<div className="col-md-12">
                      <select className="form-select form-control-sm mb-3">
								     		<option>Select Primary Module</option>
								     		<option value="lead">Lead</option>
								     		<option value="contact">Contact</option>
								     	</select>
                </div>
			          <div className="col-md-12 mt-1">
                  <div className="form-group text-end">
                      <Link to="/crm/report/module" className="btn btn-secondary _effect--ripple waves-effect waves-light">Continue</Link>
											<button type="button" className="btn btn btn-light-dark mx-3" onClick={hanldePostClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                  </div>
                </div>
			</Modal>
		</>
	)
}

export default Reports