import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";

const PageSetting = () => {

	useRedirectLoggedOutUser("/crm/login");

	const navBgcolors = ['#660000','#990000','#D24143','#DE4F5D', '#ea4c88', '#993399', '#663399', '#07385D',
						 '#1e5598', '#2d72d9', '#366dc7', '#018EE0', '#0099cc', '#37a5a5', '#439454', '#336600',
						 '#165151', '#999900', '#E9A23F', '#996633', '#553A48', '#313949'];
	const bodyBgcolors = ['#660000','#990000','#D24143','#DE4F5D', '#ea4c88', '#993399', '#663399', '#07385D',
						 '#1e5598', '#2d72d9', '#366dc7', '#018EE0', '#0099cc', '#37a5a5', '#439454', '#336600',
						 '#165151', '#999900', '#E9A23F', '#996633', '#553A48', '#313949'];
	const bodyTextcolors = ['#660000','#990000','#D24143','#DE4F5D', '#ea4c88', '#993399', '#663399', '#07385D',
						 '#1e5598', '#2d72d9', '#366dc7', '#018EE0', '#0099cc', '#37a5a5', '#439454', '#336600',
						 '#165151', '#999900', '#E9A23F', '#996633', '#553A48', '#313949'];

	const setNavbg = (bgColor) =>{
		document.documentElement.style.setProperty('--bgNav-color',bgColor)
	}
	const setBodybg = (bodyColor) =>{
		document.documentElement.style.setProperty('--bodyBg-color',bodyColor)
	}
	const setBodytext = (bodyTextColor) =>{
		document.documentElement.style.setProperty('--bodyText-color',bodyTextColor)
	}

	useEffect(()=>{

		const currentBgColor = localStorage.getItem('bgColor');
		setNavbg(currentBgColor);
		console.log(currentBgColor);

		const currentBodyColor = localStorage.getItem('bodyColor');
		setBodybg(currentBodyColor);
		console.log(currentBodyColor);

		const currentBodyTextColor = localStorage.getItem('bodyTextColor');
		setBodytext(currentBodyTextColor);
		console.log(currentBodyTextColor);

	},[])

	const setNavBgColor = (event) =>{
		const currentBgColor = event.target.style.getPropertyValue('--bgNav-color');
		setNavbg(currentBgColor);
		localStorage.setItem('bgColor',currentBgColor);
		// console.log(currentColor);
	}

	const setBodyBgColor = (event) =>{
		const currentBodyColor = event.target.style.getPropertyValue('--bodyBg-color');
		setBodybg(currentBodyColor);
		localStorage.setItem('bodyColor',currentBodyColor);
		// console.log(currentColor);
	}

	const setBodyTextColor = (event) =>{
		const currentBodyTextColor = event.target.style.getPropertyValue('--bodyText-color');
		setBodytext(currentBodyTextColor);
		localStorage.setItem('bodyTextColor',currentBodyTextColor);
		// console.log(currentColor);
	}

	return (
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">
		                        
								<div className="col-12 mt-3 text-end">
									<Link to="/crm/home-customization" className="btn btn-secondary">Back</Link>
								</div>

								<div className="navBgColor">
									<h5>Navbar Color Change</h5>
									<div className="colors-list">
										{navBgcolors.map((bgColor,idx)=> 
											<div className="color-item" key={idx} bgcolor={bgColor} onClick={setNavBgColor} style={{'--bgNav-color':bgColor}}>
											</div>
										)}
									</div>
								</div>
											

								<div className="bodyBgColor">
									<h5>Body Color Change</h5>
									<div className="colors-list">
										{bodyBgcolors.map((bodyColor,idx)=> 
											<div className="color-item" key={idx} bodycolor={bodyColor} onClick={setBodyBgColor} style={{'--bodyBg-color':bodyColor}}>
											</div>
										)}
									</div>
								</div>

								<div className="bodyTextColor">
									<h5>Body Text Color Change</h5>
									<div className="colors-list">
										{bodyTextcolors.map((bodyTextColor,idx)=> 
											<div className="color-item" key={idx} bodytextcolor={bodyTextColor} onClick={setBodyTextColor} style={{'--bodyText-color':bodyTextColor}}>
											</div>
										)}
									</div>
								</div>						
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		</>
	)
}

export default PageSetting