import React,{useState,useEffect,useReducer} from 'react';
import KbmOneSidebar from '../components/KbmOneSidebar';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { Link,useNavigate } from 'react-router-dom';
import {AiOutlinePlus,AiOutlineMinus} from 'react-icons/ai';
import { toast } from "react-toastify";
import {RxCross2} from 'react-icons/rx';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import {
	addApp,
  	getApps,
  	deleteApp,
} 	from "../../redux/kbmone-features/userApp/appSlice";

const initialState ={
	name : '',
	url : '',
}

const AssignedApps = () => {

	useRedirectLoggedOutUser("/login");

	const unassignedApps = [
	{
		id: 0,
		name: 'CRM',
		url: '/crm',
		desc: 'Efficiently streamline your Contract Lifecycle Management (CLM) process',
		category: 'RECOMMENDED',
	},
	{
		id: 1,
		name: 'Contracts',
		desc: 'Efficiently streamline your Contract Lifecycle Management (CLM) process',
		category: 'RECOMMENDED',
	},
	{
		id: 2,
		name: 'Mails',
		desc: 'Email service for your business',
		category: 'RECOMMENDED',
	},
	{
		id: 3,
		name: 'Nootebook',
		desc: 'Beautiful note-taking app',
		category: 'RECOMMENDED',
	},
	{
		id: 4,
		name: 'Bigin',
		desc: 'Pipeline-centric CRM built and priced for small businesses',
		category: 'SALES',
	},
	{
		id: 5,
		name: 'Bookings',
		desc: 'Appointment Creation Software',
		category: 'SALES',
	},
	{
		id: 6,
		name: 'Commerce',
		desc: 'Make online selling simple with Zoho Commerces ecommerce platform.',
		category: 'SALES',
	},
	{
		id: 7,
		name: 'Backstage',
		desc: 'Event management software',
		category: 'MARKETING',
	},
	{
		id: 8,
		name: 'Campaigns',
		desc: 'Email marketing and campaign automation tool',
		category: 'MARKETING',
	},
	{
		id: 9,
		name: 'Forms',
		desc: 'Interactive online forms builder',
		category: 'MARKETING',
	},
	{
		id: 10,
		name: 'Cliq',
		desc: 'Instant messaging platform',
		category: 'EMAIL AND COLLABORATION',
	},
	{
		id: 11,
		name: 'Connect',
		desc: 'Next-generation social intranet software',
		category: 'EMAIL AND COLLABORATION',
	},
	{
		id: 12,
		name: 'Learn',
		desc: 'Knowledge management for teams',
		category: 'EMAIL AND COLLABORATION',
	},
	{
		id: 13,
		name: 'Books',
		desc: 'Online accounting software',
		category: 'FINANCE',
	},
	{
		id: 14,
		name: 'Checkout',
		desc: 'Online payment collection solution',
		category: 'FINANCE',
	},
	{
		id: 15,
		name: 'Expense',
		desc: 'Expense report management software',
		category: 'FINANCE',
	},
	{
		id: 16,
		name: 'People',
		desc: 'Human resource management system',
		category: 'HUMAN RESOURCES',
	},
	{
		id: 17,
		name: 'Recruit',
		desc: 'Applicant tracking and hiring software',
		category: 'HUMAN RESOURCES',
	},
]

	const { userApps, isSuccess, message } = useSelector(
	    (state) => state.userApp
	);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const dispatch = useDispatch();
	const [tabIndex, setTabIndex] = useState(0);

	const [windowHeight, setWindowHeight] = useState({ winHeight: window.innerHeight })

	const detectSize = () =>{
		setWindowHeight({
			winHeight: window.innerHeight 
		})
	}

	useEffect(() => {
	  window.addEventListener('resize', detectSize);
	  return () => {
	    window.removeEventListener('resize', detectSize)
	  }
	}, [windowHeight])

	const [appList, setAppList]=useState(unassignedApps);
	const [app, setApp] = useState([]);
  
  const addapp = async (item) => {
    setApp([...app, item]);
    setAppList(appList.filter((i) => i.id !== item.id));
    await dispatch(addApp(item));
    forceUpdate();
  };

  useEffect(() => {
	    dispatch(getApps());
	}, [dispatch, reducerValue]);

  const removeapp = async (id,item) => {
   //  setAppList([...appList, item]);
  	// setApp(app.filter((i) => i.id !== item.id));
    await dispatch(deleteApp(id));
    dispatch(getApps());
  };

  const confirmDelete = (id) => {
	    confirmAlert({
	      title: "Delete This App",
	      message: "Are you sure to do delete this app?",
	      buttons: [
	        {
	          label: "Delete",
	          onClick: () => removeapp(id),
	        },
	        {
	          label: "Cancel",
	          // onClick: () => alert("Click No"),
	        },
	      ],
	    });
	};

const categories = [...new Set(appList.map((item) => item.category))];

const [divVisibility, setDivVisibility] = useState(
    new Array(6).fill(false) // Initialize with true values for all divs
  );
var buttonText = divVisibility ? <AiOutlinePlus/> : <AiOutlineMinus/>;
const toggleDivVisibility = (index) => {
    setDivVisibility((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

	return (
		<>
			
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container kbmone" id="container">

		    		<div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <KbmOneSidebar />

		        <div id="content"  className="main-content">
		        	<div className="layout-px-spacing">
		          <div className="middle-content container-xxl p-0">      
		          <div className="row layout-top-spacing">
		        	<div className="assignedAppSec">
		        		<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
							    <TabList>
							      <Tab>More Apps</Tab>
							      <Tab>Unassigned Apps</Tab>
							    </TabList>
							    <TabPanel>
								    <div className="assignedApp">
								    	<h5>Assigned Apps</h5>
								        <ul>
								          {
								          	Array.isArray(userApps)
								          	? userApps.map((item, index) => {
								          		return (

								          				<li key={index}>
											            	<Link target="_blank" to={item.url}>
											              <span>{item.name}</span>
											              </Link>
											              <RxCross2 onClick={() => confirmDelete(item._id)} />
											            </li>

								          			)
								          })
								          : null
								          }
								        </ul>
								    </div>
								   
							    </TabPanel>
							    <TabPanel>
							      	<div className="unassignedApps-Sec" style={{height : windowHeight.winHeight -200, overflowY: 'auto' }}>
							      		<div className="unassignedApps">
							      			
							      			{categories.map((category,index) => (
											        <div key={index}>
											          <h5 onClick={() => toggleDivVisibility(index)}>{category} {divVisibility[index] ? <AiOutlineMinus/> : <AiOutlinePlus/>}</h5>
											          <ul>
											            {divVisibility[index] && appList
											              .filter((item) => item.category === category)
											              .map((item) => (
											              	
											                
											                	<li key={item.id}>
													      					<div>
													      					<span>{item.name}</span>
													      					<small>{item.desc}</small>
													      					</div>
													      					<button onClick={() => addapp(item)} className="btn btn-secondary" style={{fontSize: '12px'}}>Add</button>
													      				</li>
											                
																		  
											              ))}
											          </ul>
											        </div>
											      ))}
							      			
							      		</div>
							      	</div>
							    </TabPanel>
							</Tabs>
		        	</div>
		        	</div>
		        	</div>
		        	</div>
		        </div>

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		</>
	)
}

export default AssignedApps