import React,{useState,useEffect} from 'react';
// import Card from '../../components/Card';
import PasswordInput from '../../components/PasswordInput';
import { Link,useNavigate } from 'react-router-dom';
import {TiUserAddOutline} from 'react-icons/ti';
import {FaTimes} from 'react-icons/fa';
import {BsCheck2All} from 'react-icons/bs';
import { toast } from "react-toastify";
import { validateEmail } from "../../../redux/kbmone-features/auth/authService";
import { useDispatch, useSelector } from "react-redux";
import {
  register,
  RESET,
  sendVerificationEmail,
} from "../../../redux/kbmone-features/auth/authSlice";
// import Loader from "../../components/Loader";

const initialState ={
	name : '',
	email : '',
	password : '',
	password2 : '',
}

const Register = () => {

	const [formData, setFormData] = useState(initialState);
	const {name,email,password,password2} = formData;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {isLoading, isLoggedIn, isSuccess, message} = useSelector((state) => state.auth);

	const [uCase, setUCase] = useState(false);
	const [num, setNum] = useState(false);
	const [sChar, setSChar] = useState(false);
	const [passLenght, setPassLenght] = useState(false);

	const switchIcon = (condition) =>{
		if (condition){
			return (checkIcon)
		}
		return timeIcon;
	}

	const timeIcon = <FaTimes color='red' size={10}/>;
	const checkIcon = <BsCheck2All color='green' size={10}/>;

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setFormData({...formData, [name]: value});
	}

	useEffect(() => {
		if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
			setUCase (true);
		} else {
			setUCase (false);
		}
		if (password.match(/([0-9])/)) {
			setNum (true);
		} else {
			setNum (false);
		}
		if (password.match(/([!,@,#,$,%,^,&,*,?,_,~])/)) {
			setSChar (true);
		} else {
			setSChar (false);
		}
		if (password.length > 5) {
			setPassLenght (true);
		} else {
			setPassLenght (false);
		}
	}, [password])

	const registerUser = async (e) => {
		e.preventDefault();

		if (!name || !email || !password){
			return toast.error('All fields are required')
		}
		if (password.length < 6) {
	      	return toast.error("Password must be up to 6 characters");
	    }
		if (!validateEmail(email)){
			return toast.error('Please enter a valid email')
		}
		if (password !== password2){
			return toast.error('Password do not match')
		}

		const userData = {name, email, password, plateform:"kbmone"};
		// console.log(userData);
		await dispatch(register(userData));
		// await dispatch(sendVerificationEmail());
	};

	useEffect(() =>{
		if (isSuccess && isLoggedIn) {
	        navigate("/login");
	    }
	    dispatch(RESET());
	},[isLoggedIn, isSuccess, dispatch, navigate])

	return (
		<>
		
		{/*<div className="container">
			<Card>
				<TiUserAddOutline size={24} color='#3b3f5c'/>
				<h4>Register</h4>
				<form onSubmit={loginUser}>
				    <div className="row mb-3">
				        <div className="col-sm-12">
				            <input type="text" className="form-control" placeholder="Name *" name="name" value={name} onChange={handleInputChange} required/>
				        </div>
				    </div>
				    <div className="row mb-3">
				        <div className="col-sm-12">
				            <input type="email" className="form-control" placeholder="Email *" name="email" value={email} onChange={handleInputChange} required/>
				        </div>
				    </div>
				    <div className="row mb-3">
				        <div className="col-sm-12">
				        	<PasswordInput placeholder="Password *" name="password" value={password} onChange={handleInputChange} />
				        </div>
				    </div>
				    <div className="row mb-3">
				        <div className="col-sm-12">
				        	<PasswordInput placeholder="Confirm Password *" name="password2" value={password2} onChange={handleInputChange} />
				        </div>
				    </div>
				    <div className="row mb-3">
				        <div className="col-sm-12">
				        	<ul className="password_strenght">
				        		<li>
				        			<span>
				        				{switchIcon(uCase)} Lowercase & Uppercase
				        			</span>
				        		</li>
				        		<li>
				        			<span>

				        				{switchIcon(num)} Number (0-9)
				        			</span>
				        		</li>
				        		<li>
				        			<span>
				        				{switchIcon(sChar)} Special Character (!@#$%^&*()_+)
				        			</span>
				        		</li>
				        		<li>
				        			<span>
				        				{switchIcon(passLenght)} Atleast 6 Digits
				        			</span>
				        		</li>
				        	</ul>
				        </div>
				    </div>
				    <div className="col-12 mb-2">
				    	<button type="submit" className="btn btn-primary w-100">Register</button>
				    </div>
				    <div className="d-flex justify-content-between">
				        <div className="d-flex">
				        	<span className="mx-1">Already have an account !</span>
				            <Link to="/login">Login</Link>
				        </div>
				    </div>
				</form>
			</Card>
		</div>*/}

		<div className="auth-container d-flex">

        <div className="container mx-auto align-self-center">
    
            <div className="row">
    
                <div className="col-6 d-lg-flex d-none h-100 my-auto top-0 start-0 text-center justify-content-center flex-column">
                    <div className="auth-cover-bg-image"></div>
                    <div className="auth-overlay"></div>
                        
                    <div className="auth-cover">
    
                        <div className="position-relative">
    
                            <img src="../../src/assets/img/kbmlogo.png" alt="KBM_LOGO"/>
    
                            {/*<h2 className="mt-5 text-white font-weight-bolder px-2">Join the community of experts</h2>
                            <p className="text-white px-2">It is easy to setup with great customer experience.</p>*/}
                        </div>
                        
                    </div>

                </div>

                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-8 col-12 d-flex flex-column align-self-center ms-lg-auto me-lg-0 mx-auto">
                    <div className="card">
                        <div className="card-body">
    
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    
                                    <h2>Sign Up KBM-ONE</h2>
                                    <p>Enter email and password to register your company</p>
                                    
                                </div>

                                <form onSubmit={registerUser}>

                                <div className="col-md-12">
                                    <div className="mb-3">
                                    	<label>Name</label>
                                        <input type="text" className="form-control add-billing-address-input" placeholder="Name *" name="name" 
                                        value={name} onChange={handleInputChange}/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                    	<label>Email</label>
                                        <input type="email" className="form-control" placeholder="Email *" name="email" 
                                        value={email} onChange={handleInputChange}/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-3">
                                		<label>Password</label>
                                    	<PasswordInput placeholder="Password *" name="password" value={password} onChange={handleInputChange} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-3">
                                    	<label>Confirm Password</label>
                                    	<PasswordInput placeholder="Confirm Password *" name="password2" value={password2} onChange={handleInputChange}
                                    	onPaste={(e)=>{ e.preventDefault(); toast.error('Cannot paste into input field'); return false; }}
                                    	 />
                                    </div>
                                </div>

                                <div className="row mb-3">
							        <div className="col-sm-12">
							        	<ul className="password_strenght">
							        		<li>
							        			<span>
							        				{switchIcon(uCase)} Lowercase & Uppercase
							        			</span>
							        		</li>
							        		<li>
							        			<span>

							        				{switchIcon(num)} Number (0-9)
							        			</span>
							        		</li>
							        		<li>
							        			<span>
							        				{switchIcon(sChar)} Special Character (!@#$%^&*()_+)
							        			</span>
							        		</li>
							        		<li>
							        			<span>
							        				{switchIcon(passLenght)} Atleast 6 Digits
							        			</span>
							        		</li>
							        	</ul>
							        </div>
							    </div>
                                
                                <div className="col-12">
                                    <div className="mb-4">
                                        <button type="submit" className="btn btn-secondary w-100">SIGN UP</button>
                                    </div>
                                </div>

                                </form>
                                
                                {/*<div className="col-12 mb-4">
                                    <div className="">
                                        <div className="seperator">
                                            <hr/>
                                            <div className="seperator-text"> <span>Or continue with</span></div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-sm-12 col-12">
                                    <div className="mb-4">
                                        <button className="btn  btn-social-login w-100 ">
                                            <img src="https://designreset.com/cork/html/src/assets/img/google-gmail.svg" alt="" className="img-fluid"/>
                                            <span className="btn-text-inner">Google</span>
                                        </button>
                                    </div>
                                </div>*/}
    
                                <div className="col-12">
                                    <div className="text-center">
                                        <p className="mb-0">Already have an account ? <Link to="/login" className="text-warning">Sign in</Link></p>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>

    </div>
		</>
	)
}

export default Register