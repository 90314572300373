import React,{useEffect,useState,useLayoutEffect,useReducer} from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { Link,useNavigate,useParams } from 'react-router-dom'; 
import { toast } from "react-toastify";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { useDispatch, useSelector } from "react-redux";
import {
  getModule,
  selectModuleItem,
  getModules,
  updateModule,
} from "../../redux/crm-features/moduleItem/moduleSlice";

const EditModule = () => {
	useRedirectLoggedOutUser("/crm/login");
	
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { moduleItem, isLoading, isSuccess, message } = useSelector(
	    (state) => state.moduleItem
	);

	const initialState ={
		title: moduleItem?.title || "",
	  name: moduleItem?.name || "",
	  lastModify: moduleItem?.lastModify || "",
	}

	// const productEdit = useSelector(selectProduct);

	useEffect(() => {
	    dispatch(getModule(id));
	}, [dispatch, id]);

	const [formData, setFormData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const {title,name,lastModify} = formData;

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setFormData({...formData, [name]: value});
	}

	const moduleEdit = async (e) => {
		e.preventDefault();

		const moduleData = {
			title:formData.title, 
			name: formData.name,  
			lastModify: formData.lastModify, 
		};
		
		await dispatch(updateModule({id, moduleData}));
		await dispatch(getModules());
		forceUpdate();
		navigate('/crm/modules');
		// window.location.reload();
		// await dispatch(sendVerificationEmail());
	};

	// console.log(users);

	
	useLayoutEffect(() => {
	    if (moduleItem) {
	      setFormData({
	        ...formData,
	        title: moduleItem.title,
	        name: moduleItem.name,
	        lastModify: moduleItem.lastModify,
	        
	      });
	    }
	}, [moduleItem]);

	
	return (
		<div>

		{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">

			                    {/*content*/}


			                    <div className="col-lg-12">
			                    	<div className="col-12">
			                    		<div className="d-flex align-items-center justify-content-between">
			                    			<h5 className="m-0">Edit Module</h5>
			                    			<Link to="/crm/modules" className="v-link btn btn-secondary _effect--ripple waves-effect waves-light">Back</Link>
			                    		</div>
			                    	</div>
			                    	<div className="col-12 mt-4">
			                    		{/*<UserStats />*/}
			                    	</div>
		                            <div className="statbox widget box box-shadow">
		                                <div className="widget-content widget-content-area">
		                                	<form className="form" onSubmit={moduleEdit}>
				                                <div className="row mt-4">
				                                    <div className="col-md-12">
				                                        <div className="form-group">
				                                            <input type="text" className="form-control mb-3" id="title" placeholder="Title" name="title" value={formData?.title} onChange={handleInputChange}/>
				                                        </div>
				                                    </div>
				                                    <div className="col-md-12">
				                                        <div className="form-group">
				                                            <input type="text" className="form-control mb-3" id="name" placeholder="Module Name" name="name" value={formData?.name} onChange={handleInputChange}/>
				                                        </div>
				                                    </div>
				                                    <div className="col-md-12">
				                                        <div className="form-group">
				                                            <input type="text" className="form-control mb-3" id="lastModify" placeholder="Last Modified" name="lastModify" value={formData?.lastModify} onChange={handleInputChange}/>
				                                        </div>
				                                    </div>
				                                    <div className="col-md-12 mt-1">
				                                        <div className="form-group text-end">
				                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
				                                        </div>
				                                    </div>
				                                </div>
				                            </form>
		                                </div>
		                            </div>
	                        	</div>


			                    {/*content*/}

		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		</div>
	)
}

export default EditModule