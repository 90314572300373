import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/users/one/`;

// Validate email
export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

// register user
const register = async(userData) =>{
	const response = await axios.post(API_URL + "register", userData);
	return response.data;
};

// login user
const login = async(userData) =>{
	const response = await axios.post(API_URL + "login", userData);
	return response.data;
};

// logout user
const logout = async() =>{
	const response = await axios.get(API_URL + "logout");
	return response.data.message;
};

// get login status
const getLoginStatus = async() =>{
	const response = await axios.get(API_URL + "loginStatus");
	return response.data;
};

// add user
const addUser = async(userData) =>{
	const response = await axios.post(API_URL + "addUser", userData);
	return response.data;
};

// get user
const getUser = async() =>{
	const response = await axios.get(API_URL + "getUser");
	return response.data;
};

// update user
const updateUser = async(userData) =>{
	const response = await axios.patch(API_URL + "updateUser", userData);
	return response.data;
};

// send verification email
const sendVerificationEmail = async() =>{
	const response = await axios.post(API_URL + "sendVerificationEmail");
	return response.data.message;
};

// verify user
const verifyUser = async(verificationToken) =>{
	const response = await axios.patch(`${API_URL}verifyUser/${verificationToken}`);
	return response.data.message;
};

// change password
const changePassword = async(userData) =>{
	const response = await axios.patch(API_URL + "changePassword", userData);
	return response.data.message;
};

// forgot password
const forgotPassword = async(userData) =>{
	const response = await axios.post(API_URL + "forgotPassword", userData);
	return response.data.message;
};

// forgot password
const resetPassword = async(userData, resetToken) =>{
	const response = await axios.patch(`${API_URL}resetPassword/${resetToken}`, userData);
	return response.data.message;
};

// get all
const getAll = async() =>{
	const response = await axios.get(API_URL + "getAll");
	return response.data;
};

// get users
const getAllUsers = async() =>{
	const response = await axios.get(API_URL + "getAllUsers");
	return response.data;
};

// get users kbmone
const getAllUsersKbmOne = async() =>{
	const response = await axios.get(API_URL + "getAllUsersKbmOne");
	return response.data;
};

// get users active
const getAllUsersActive = async() =>{
	const response = await axios.get(API_URL + "getAllUsersActive");
	return response.data;
};

// get users inactive
const getAllUsersInActive = async() =>{
	const response = await axios.get(API_URL + "getAllUsersInActive");
	return response.data;
};

// get companies
const getAllCompanies = async() =>{
	const response = await axios.get(API_URL + "getAllCompanies");
	return response.data;
};

// delete user
const deleteUser = async(id) =>{
	const response = await axios.delete(API_URL + id);
	return response.data.message;
};

// upgrade user
const upgradeUser = async(userData) =>{
	const response = await axios.post(API_URL + "upgradeUser", userData);
	return response.data.message;
};

// sendLoginCode
const sendLoginCode = async(email) =>{
	const response = await axios.post(API_URL + `sendLoginCode/${email}`,);
	return response.data.message;
};

// login with code
const loginWithCode = async(code, email) =>{
	const response = await axios.post(API_URL + `loginWithCode/${email}`, code);
	return response.data;
};

// login with google
const loginWithGoogle = async(userToken) =>{
	const response = await axios.post(API_URL + "google/callback", userToken);
	return response.data;
};

// get single user id
const getSingleUserId = async(id) =>{
	const response = await axios.get(API_URL + id);
	return response.data;
};

// get company users
const getCompanyUsers = async(id) =>{
	const response = await axios.get(`${API_URL}company/${id}`);
	return response.data;
};

// update user id
const updateUserId = async(id, userData) =>{
	const response = await axios.patch(`${API_URL}${id}`, userData);
	return response.data;
};

const authService = {
	register,
	login,
	logout,
	getLoginStatus,
	addUser,
	getUser,
	updateUser,
	sendVerificationEmail,
	verifyUser,
	changePassword,
	forgotPassword,
	resetPassword,
	getAllUsers,
	getAllUsersKbmOne,
	deleteUser,
	upgradeUser,
	sendLoginCode,
	loginWithCode,
	loginWithGoogle,
	getAllCompanies,
	getSingleUserId,
	updateUserId,
	getAll,
	getCompanyUsers,
	getAllUsersInActive,
	getAllUsersActive,
}

export default authService;