import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { UserName } from "../pages/ProfileAccount";
import { ChatAllocate } from "../components/hiddenLinks";
import { useDispatch, useSelector } from "react-redux";
import { deleteEmsUsers, updateEmsUsers } from "../../redux/crm-features/auth/authSlice";

const Dashboard = () => {

	useRedirectLoggedOutUser("/crm/login");
	
	const dispatch = useDispatch();
	const removeEmsUsers = () => {
	    dispatch(deleteEmsUsers());
	};

	const changeEmsUsers = () => {
	    dispatch(updateEmsUsers());
	};

	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="" id="">

		        {/*<div className="overlay"></div>
		        <div className="search-overlay"></div>*/}

		        {/*<Sidebar />*/}

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="" className="">
		            <div className="layout-px-spacing custom_dashboard">

		                <div className="middle-content container-xxl p-0">

		                	<div className="d-flex">
		                		<h5>Welcome to </h5> &nbsp;
		                		<UserName />
		                	</div>

		                    <div className="row layout-top-spacing">
		                       
		                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 layout-spacing">
		                            <div className="widget widget-card-five">
		                                <div className="widget-content">
		                                <h4>General</h4>
		                                    <ul>
		                                    	<li className="active">
					                                <Link to="/crm" title="Dashboard"> Dashboard </Link>
					                            </li>
					                            <li>
					                                <Link to="/crm/personal-setting" title="Personal Settings"> Personal Settings </Link>
					                            </li>
					                            <li>
					                                <Link to="/crm/company-detail" title="Company Details"> Company Details </Link>
					                            </li>
		                                    </ul>
		                                </div>
		                            </div>
		                        </div>

		                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 layout-spacing">
		                            <div className="widget widget-card-five">
		                                <div className="widget-content">
		                                <h4>User and Control</h4>
		                                    <ul>
		                                    	<li>
					                                <Link to="/crm/user" title="Users"> Users </Link>
					                            </li>
					                            <li>
					                                <Link to="/crm/security-control" title="Security Control"> Security Control </Link>
					                            </li>
					                            <li>
					                                <Link to="/crm/compliance-setting" title="Compliance Settings"> Compliance Settings </Link>
					                            </li>
		                                    </ul>
		                                </div>
		                            </div>
		                        </div>

		                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 layout-spacing">
		                            <div className="widget widget-card-five">
		                                <div className="widget-content">
		                                <h4>Channels</h4>
		                                    <ul>
		                                    	<li>
					                                <Link to="/crm/email-settings" title="Email"> Email </Link>
					                            </li>
					                            <ChatAllocate>
					                            <li>
					                                <Link target="_blank" to="https://kbm-chat-a7c4b7fa8867.herokuapp.com/" title="Chat"> Chat  </Link>
					                            </li>
					                            </ChatAllocate>
												<li style={{color:'red', cursor:'pointer'}}>
													<div onClick={() => removeEmsUsers()}>Delete EMS Users</div>
												</li>
												<li style={{color:'blue', cursor:'pointer'}}>
													<div onClick={() => changeEmsUsers()}>Update EMS Users</div>
												</li>
		                                    </ul>
		                                </div>
		                            </div>
		                        </div>

		                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 layout-spacing">
		                            <div className="widget widget-card-five">
		                                <div className="widget-content">
		                                <h4>Customization</h4>
		                                    <ul>
		                                    	<li>
					                                <Link to="/crm/modules" title="Modules and Fields"> Modules and Fields </Link>
					                            </li>
					                            <li>
					                                <Link to="/crm" title="Templates"> Templates </Link>
					                            </li>
					                            <li>
					                                <Link to="/crm/home-customization" title="Customize Home Page"> Customize Home Page </Link>
					                            </li>
		                                    </ul>
		                                </div>
		                            </div>
		                        </div>

		                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 layout-spacing">
		                            <div className="widget widget-card-five">
		                                <div className="widget-content">
		                                <h4>Automation</h4>
		                                    <ul>
		                                    	<li>
					                                <Link to="/crm" title="Workflow Rules"> Workflow Rules </Link>
					                            </li>
					                            <li>
					                                <Link to="/crm" title="Action"> Action </Link>
					                            </li>
		                                    </ul>
		                                </div>
		                            </div>
		                        </div>

		                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 layout-spacing">
		                            <div className="widget widget-card-five">
		                                <div className="widget-content">
		                                <h4>Data Administration</h4>
		                                    <ul>
		                                    	<li>
					                                <Link to="/crm" title="Import"> Import </Link>
					                            </li>
					                            <li>
					                                <Link to="/crm" title="Export"> Export </Link>
					                            </li>
					                            <li>
					                                <Link to="/crm" title="Data Backup"> Data Backup </Link>
					                            </li>
					                            <li>
					                                <Link to="/crm" title="Remove Sample Data"> Remove Sample Data </Link>
					                            </li>
					                            <li>
					                                <Link to="/crm" title="Storage"> Storage </Link>
					                            </li>
					                            <li>
					                                <Link to="/crm" title="Recycle Bin"> Recycle Bin </Link>
					                            </li>
		                                    </ul>
		                                </div>
		                            </div>
		                        </div>

		                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 layout-spacing">
		                            <div className="widget widget-card-five">
		                                <div className="widget-content">
		                                <h4>Marketplace</h4>
		                                    <ul>
		                                    	<li>
					                                <Link to="/crm" title="KBM"> KBM </Link>
					                            </li>
					                            <li>
					                                <Link to="/crm" title="Microsoft"> Microsoft </Link>
					                            </li>
		                                    </ul>
		                                </div>
		                            </div>
		                        </div>

		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}
		</>
	);
}

export default Dashboard