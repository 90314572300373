import React,{useEffect,useState,useReducer} from 'react';
import ReactDOM from "react-dom";
import { Link,useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { toast } from "react-toastify";
import {AiFillSetting} from 'react-icons/ai';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from "react-redux";
import {
	addModule,
} 	from "../../redux/crm-features/moduleItem/moduleSlice";

const initialState ={
	title : '',
	name : '',
	lastModify : '',
}

const ModuleLayoutQuick = () => {
	
	useRedirectLoggedOutUser("/crm/login");

	const [expanded, setExpanded] = useState('panel1');
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const dispatch = useDispatch();
	const [moduleData, setModuleData] = useState(initialState);
	const {title,name,lastModify} = moduleData;
	const navigate = useNavigate();

  	const handleChange =
    	(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      	setExpanded(newExpanded ? panel : false);
    };

    const handleInputChange = (e) => {
		const {name, value} = e.target;
		setModuleData({...moduleData, [name]: value});
	}

	const moduleAdd = async (event) => {
		event.preventDefault();

		if (!title || !name){
			return toast.error('All fields are required')
		}

		const moduleData = {title, name, lastModify};
		// console.log(userData);
		await dispatch(addModule(moduleData));
		setModuleData({ title: "", name: "", lastModify: "" });
		forceUpdate();
		navigate('/crm/modules');
		// window.location.reload();
		// await dispatch(sendVerificationEmail());
	};

	
	return(
		<>

		<div className="moduleFormBuilderPage">
			<div className="container-fluid">
				<div className="module-layout-navbar">
					<ul>
						<li>
						
							<Link to='/crm'><i className="fa fa-long-arrow-left"></i><h5>Untitle</h5></Link>
							
						</li>
						<li className="d-flex">
							<h5>Standard</h5>
							<div className="dropdown">
							  <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
							    <AiFillSetting size={20}/>
							  </button>
							  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
							    <li><Link className="dropdown-item" to="/crm">Module Permission</Link></li>
							    <li><Link className="dropdown-item" to="/crm">Lead Conversion Mapping</Link></li>
							    <li><Link className="dropdown-item" to="/crm">Map Dependency Fields</Link></li>
							  </ul>
							</div>
						</li>
						<li>
							<Link>
								<div className="module-layout-navbar-btn">
									<button type="button" className="btn btn-secondary">Cancel</button>
									<button type="button" className="btn btn-secondary">Save and Close</button>
									<button type="button" className="btn btn-secondary">Save</button>
								</div>
							</Link>
						</li>
					</ul>
				</div>
				<div className="moduleFormBuilderSec">
					<div className="moduleFormBuilderLeftSide">
						<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
					        <AccordionSummary
					          expandIcon={<ExpandMoreIcon />}
					          aria-controls="panel1a-content"
					          id="panel1a-header"
					        >
					          <h6>Lead Information</h6>
					        </AccordionSummary>
					        <AccordionDetails>
					          	<div className="formBuilderMenu">
					            <ul>
					        		<li><span>Single Line</span></li>
					        		<li><span>Multi-Line</span></li>
					        		<li><span>Email</span></li>
					        		<li><span>Phone</span></li>
					        		<li><span>Pick List</span></li>
					        		<li><span>Multi-Select</span></li>
					        		<li><span>Date</span></li>
					        		<li><span>Date/Time</span></li>
					        		<li><span>Number</span></li>
					        		<li><span>Auto-Number</span></li>
					        		<li><span>Currency</span></li>
					        		<li><span>Decimal</span></li>
					        		<li><span>Percent</span></li>
					        		<li><span>Long Integer</span></li>
					        		<li><span>Checkbox</span></li>
					        		<li><span>URL</span></li>
					        		<li><span>Lockup</span></li>
					        		<li><span>Multi-User</span></li>
					        		<li><span>Formula</span></li>
					        		<li><span>User</span></li>
					        		<li><span>File Upload</span></li>
					        		<li><span>Image Upload</span></li>
					        		<li><span>NEW SECTION</span></li>
					        	</ul>
					          	</div>
					        </AccordionDetails>
					    </Accordion>
					    <Accordion>
					        <AccordionSummary
					          expandIcon={<ExpandMoreIcon />}
					          aria-controls="panel2a-content"
					          id="panel2a-header"
					        >
					          <h6>Address Information</h6>
					        </AccordionSummary>
					        <AccordionDetails>
					          	<div className="formBuilderMenu">
					            <ul>
					        		<li><span>Single Line</span></li>
					        		<li><span>Multi-Line</span></li>
					        	</ul>
					          	</div>
					        </AccordionDetails>
					    </Accordion>
					    <Accordion>
					        <AccordionSummary
					          expandIcon={<ExpandMoreIcon />}
					          aria-controls="panel3a-content"
					          id="panel3a-header"
					        >
					          <h6>Description Information</h6>
					        </AccordionSummary>
					        <AccordionDetails>
					          	<div className="formBuilderMenu">
					            <ul>
					        		<li><span>Single Line</span></li>
					        		<li><span>Multi-Line</span></li>
					        	</ul>
					          	</div>
					        </AccordionDetails>
					    </Accordion>
					</div>
					<div className="moduleFormBuilderRightSide">

						<div className="tabMenu d-flex justify-content-between">

                        	<ul>
                        		<li><Link className="btn btn-secondary" to="/crm/module-layout-setting">Create</Link></li>
                        		<li><Link className="btn btn-secondary active" to="/crm/module-layout-quick">Quick Create</Link></li>
                        		<li><Link className="btn btn-secondary" to="/crm/module-layout-view">Detail View</Link></li>
                        	</ul>
               
                        </div>

						<form className="form" onSubmit={moduleAdd}>
		            	<h5 className="modal-title">Add Modules</h5>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input type="text" className="form-control mb-3" id="title" placeholder="Title" name="title" value={title} onChange={handleInputChange}/>
                                    </div>
                                </div>
                               <div className="col-md-12">
                                    <div className="form-group">
                                        <input type="text" className="form-control mb-3" id="name" placeholder="Module Name" name="name" value={name} onChange={handleInputChange}/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input type="text" className="form-control mb-3" id="lastModify" placeholder="Last Modified" name="lastModify" value={lastModify} onChange={handleInputChange}/>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-1">
                                    <div className="form-group text-end">
                                        <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
					</div>
				</div>
			</div>
		</div>
		    

		    
		</>
	);
}

export default ModuleLayoutQuick