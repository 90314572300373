import React,{useEffect,useLayoutEffect,useRef,useMemo,useState,useReducer} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit,AiOutlineEllipsis } from "react-icons/ai";
import Footer from '../components/Footer';
import PasswordInput from '../components/PasswordInput';
import { toast } from "react-toastify";
import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { validateEmail } from "../../redux/crm-features/auth/authService";
import { useDispatch, useSelector } from "react-redux";
import ChangeRole from "../components/ChangeRole";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  getAllUsersInActive,
  deleteUser,
} from "../../redux/crm-features/auth/authSlice";
import {
  	getProfiles
} 	from "../../redux/crm-features/userProfile/profileSlice";
import {
  	getRoles
} 	from "../../redux/crm-features/userRole/roleSlice";

const UserInActive = () => {
	useRedirectLoggedOutUser("/crm/login");

	const { users, isLoading, isLoggedIn, isSuccess, message } = useSelector(
	    (state) => state.auth
	);

	const { userProfiles } = useSelector(
	    (state) => state.userProfile
	);

	const { userRoles } = useSelector(
	    (state) => state.userRole
	);
	
  const dispatch = useDispatch();
	
	useEffect(() => {
	    dispatch(getProfiles());
	    dispatch(getRoles());
	    dispatch(getAllUsersInActive());
	}, [dispatch]);

	
	const removeUser = async (id) => {
	    await dispatch(deleteUser(id));
	    dispatch(getAllUsersInActive());
	};

	const confirmDelete = (id) => {
	    confirmAlert({
	      title: "Delete This User",
	      message: "Are you sure to do delete this user?",
	      buttons: [
	        {
	          label: "Delete",
	          onClick: () => removeUser(id),
	        },
	        {
	          label: "Cancel",
	          // onClick: () => alert("Click No"),
	        },
	      ],
	    });
	};

	const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'First Name',
        Header: () => <span>First Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          // <Link to={`/crm/edit-user/${row.original._id}`}>{renderedCellValue}</Link>
          <span className="d-flex align-items-center"><Link to={`/crm/edit-user/${row.original._id}`}>{renderedCellValue}</Link>
            <div className="dropdown">
              <span className="dropdown-toggle mx-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <AiOutlineEllipsis />
              </span>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><Link className="dropdown-item" to={`/crm/edit-user/${row.original._id}`}>Edit</Link></li>
                <li><Link className="dropdown-item" to="#" onClick={() => confirmDelete(row.original._id)}>Delete</Link></li>
              </ul>
            </div>
          </span>
        ),
      },
      {
        accessorFn: (row) => `${row.lname}`, //alternate way
        id: 'lname', //id required if you use accessorFn instead of accessorKey
        header: 'Last Name',
        Header: () => <span>Last Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          <Link to={`/crm/edit-user/${row.original._id}`}>{renderedCellValue}</Link>
        ),
      },
      {
        accessorFn: (row) => <Link to={`/crm/edit-user/${row._id}`}><img src={row.photo} width="110" height="110" alt="user-img"/></Link>, //alternate way
        id: 'photo', //id required if you use accessorFn instead of accessorKey
        header: 'Photo',
        Header: () => <span>Photo</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.email, //alternate way
        id: 'email', //id required if you use accessorFn instead of accessorKey
        header: 'Email',
        Header: () => <span>Email Address</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.profile, //alternate way
        id: 'profile', //id required if you use accessorFn instead of accessorKey
        header: 'Profile',
        Header: () => <span>Profile</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.status, //alternate way
        id: 'status', //id required if you use accessorFn instead of accessorKey
        header: 'Status',
        Header: () => <span>Status</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.chatAllocate, //alternate way
        id: 'chatAllocate', //id required if you use accessorFn instead of accessorKey
        header: 'Chat Access',
        Header: () => <span>Chat Access</span>, //optional custom header render
      },
      {
        accessorFn: (row) => 
        <>
        <span>{row.role}</span>
        <ChangeRole _id={row._id} email={row.email}/>
        </>, //alternate way
        id: 'role', //id required if you use accessorFn instead of accessorKey
        header: 'Role',
        Header: () => <span>Role</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.phone, //alternate way
        id: 'phone', //id required if you use accessorFn instead of accessorKey
        header: 'Phone',
        Header: () => <span>Phone</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.fax, //alternate way
        id: 'fax', //id required if you use accessorFn instead of accessorKey
        header: 'Fax',
        Header: () => <span>Fax</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.website, //alternate way
        id: 'website', //id required if you use accessorFn instead of accessorKey
        header: 'Website',
        Header: () => <span>Website</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.mobile, //alternate way
        id: 'mobile', //id required if you use accessorFn instead of accessorKey
        header: 'Mobile',
        Header: () => <span>Mobile</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.state, //alternate way
        id: 'state', //id required if you use accessorFn instead of accessorKey
        header: 'State',
        Header: () => <span>State</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.dateOfBirth, //alternate way
        id: 'dateOfBirth', //id required if you use accessorFn instead of accessorKey
        header: 'Date of Birth',
        Header: () => <span>Date of Birth</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.street, //alternate way
        id: 'street', //id required if you use accessorFn instead of accessorKey
        header: 'Street',
        Header: () => <span>Street</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.zipCode, //alternate way
        id: 'zipCode', //id required if you use accessorFn instead of accessorKey
        header: 'Zip Code',
        Header: () => <span>Zip Code</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.city, //alternate way
        id: 'city', //id required if you use accessorFn instead of accessorKey
        header: 'City',
        Header: () => <span>City</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.country, //alternate way
        id: 'country', //id required if you use accessorFn instead of accessorKey
        header: 'Country',
        Header: () => <span>Country</span>, //optional custom header render
      },
      // {
      //   accessorFn: (row) => <span>
      //                       <FaTrashAlt
      //                         size={18}
      //                         cursor="pointer"
      //                         color="rgb(0,0,0,.8)"
      //                         onClick={() => confirmDelete(row._id)}
      //                       />
      //                       <Link to={`/crm/edit-user/${row._id}`}>
	     //                        <AiFillEdit
	     //                          size={20}
	     //                          cursor="pointer"
	     //                          color="rgb(0,0,0,.8)"
	     //                        />
      //                       </Link>
      //                     </span>, //alternate way
      //   id: 'action', //id required if you use accessorFn instead of accessorKey
      //   header: 'Action',
      //   Header: () => <span>Action</span>, //optional custom header render
      // },
    ],
    [],
  );

const isFirstRender = useRef(true);
	const [columnVisibility, setColumnVisibility] = useState({});

	useEffect(() => {
	    const columnVisibility = localStorage.getItem(
	      'mrt_columnVisibility_table_1',
	    );
	    
	    if (columnVisibility) {
	      setColumnVisibility(JSON.parse(columnVisibility));
	    }
	    
	    isFirstRender.current = false;
	  }, []);

	useEffect(() => {
	    if (isFirstRender.current) return;
	    localStorage.setItem(
	      'mrt_columnVisibility_table_1',
	      JSON.stringify(columnVisibility),
	    );
	  }, [columnVisibility]);
	
	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">
		                        
		                        <div className="tabMenu">
		                        	<ul>
		                        		{/*<li><Link className="btn btn-secondary" to="/user">Users</Link></li>*/}
		                        		<li><Link className="btn btn-secondary" to="/crm/user">Active Users</Link></li>
		                        		<li><Link className="btn btn-secondary active" to="/crm/user-inactive">Inactive Users</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/users-group">Groups</Link></li>
		                        	</ul>
		                        </div>

		                        
		                        <div className="active_user_sec">
		                        
		                        	<div className="col-12">
			                        	<div className="statbox widget box box-shadow moduleData">
			                                <div className="widget-content widget-content-area">
			                                {users.length === 0 ? (
			                                	<p>User not found...</p>
			                                ):(
			                                	<MaterialReactTable 
			                                	onColumnVisibilityChange={setColumnVisibility}
                                      state={{columnVisibility}}
                                      enableRowSelection={true}
			                                	columns={columns} data={users} 
			                                	// enableRowNumbers rowNumberMode="original"
			                                	/>
			                                )}
			                                </div>
			                            </div>
			                        </div>

															{/*<div className="theme_setting">
																<h5>Themes</h5>
																<div className="colors-list">
																	{navBgcolors.map((bgColor,idx)=> 
																		<div className="color-item" key={idx} bgcolor={bgColor} onClick={setNavBgColor} style={{'--bgNav-color':bgColor}}>
																		</div>
																	)}
																</div>
															</div>*/}
			                    	

		                        </div>
		                       
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		</>
	);
}

export default UserInActive
