import React,{useState,useEffect,useMemo,useRef} from 'react';
import { Link } from "react-router-dom";
import ModuleSideBar from './ModuleSideBar';
import Footer from '../components/Footer';
import { AiOutlineSearch,AiOutlineArrowLeft,AiOutlineEllipsis } from "react-icons/ai";
import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";

const layouts = [
	{
		
	},
];

const ReportDetail = () => {

	useRedirectLoggedOutUser("/crm/login");

const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'VENDOR NAME',
        Header: () => <span>VENDOR NAME</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          // <span className=""><Link to={`/crm/report/id`}><b>{renderedCellValue}</b></Link></span>
          <span className="d-flex align-items-center"><Link to={`/crm/report/id`}>{renderedCellValue}</Link>
            <div className="dropdown">
              <span className="dropdown-toggle mx-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <AiOutlineEllipsis />
              </span>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><Link className="dropdown-item" to="#">Edit</Link></li>
                <li><Link className="dropdown-item" to="#">Delete</Link></li>
              </ul>
            </div>
          </span>
        ),
      },
      {
        accessorFn: (row) => row.subject, //alternate way
        id: 'subject', //id required if you use accessorFn instead of accessorKey
        header: 'SUBJECT',
        Header: () => <span>SUBJECT</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.dueDate, //alternate way
        id: 'dueDate', //id required if you use accessorFn instead of accessorKey
        header: 'DUE DATE',
        Header: () => <span>DUE DATE</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.status, //alternate way
        id: 'status', //id required if you use accessorFn instead of accessorKey
        header: 'STATUS',
        Header: () => <span>STATUS</span>, //optional custom header render
      },
      {
        accessorFn: (row) => <span><Link to="/crm/report/id"><b>{row.name}</b></Link></span>, //alternate way
        id: 'name2', //id required if you use accessorFn instead of accessorKey
        header: 'VENDOR NAME',
        Header: () => <span>VENDOR NAME</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.phone, //alternate way
        id: 'phone', //id required if you use accessorFn instead of accessorKey
        header: 'PHONE',
        Header: () => <span>PHONE</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.email, //alternate way
        id: 'email', //id required if you use accessorFn instead of accessorKey
        header: 'EMAIL',
        Header: () => <span>EMAIL</span>, //optional custom header render
      },
    ],
    [],
  );

const isFirstRender = useRef(true);
	const [columnVisibility, setColumnVisibility] = useState({});

	useEffect(() => {
	    const columnVisibility = localStorage.getItem(
	      'mrt_columnVisibility_table_1',
	    );
	    
	    if (columnVisibility) {
	      setColumnVisibility(JSON.parse(columnVisibility));
	    }
	    
	    isFirstRender.current = false;
	  }, []);

	useEffect(() => {
	    if (isFirstRender.current) return;
	    localStorage.setItem(
	      'mrt_columnVisibility_table_1',
	      JSON.stringify(columnVisibility),
	    );
	  }, [columnVisibility]);

	return (
		<>
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container reports_sec" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		      	{/*<!--  BEGIN SIDEBAR  -->*/}
	        {/*<div className="sidebar-wrapper sidebar-theme">

	            <nav id="sidebar">
	            
	                <div className="navbar-nav theme-brand flex-row  text-center">
	                    <div className="nav-logo">
	                        
	                        <div className="nav-item theme-text">
	                            <Link to="" className="nav-link"> KBM </Link>
	                        </div>
	                    </div>
	                    <div className="nav-item sidebar-toggle">
	                        <div className="btn-toggle sidebarCollapse">
	                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevrons-left"><polyline points="11 17 6 12 11 7"></polyline><polyline points="18 17 13 12 18 7"></polyline></svg>
	                        </div>
	                    </div>
	                </div>
	                <div className="shadow-bottom"></div>
	                
	                <ul className="list-unstyled menu-categories" id="accordionExample">
	                <li className="mt-4">
	                	<h5>Folders </h5>
	                </li>
	                <li>
	                	<form className="custom-search mb-3">
							<div className="position-relative">
								<input type="text" placeholder="Search" className="form-control" />
								<button><AiOutlineSearch size={18}/></button>
							</div>
						</form>
	                </li>
	                <li className="menu active">
	                    <Link to="" className="dropdown-toggle mb-2">
                            <div className="">
                                <span title="All Reports">All Reports</span>
                            </div>
                        </Link>
                        
                    </li>
	                <li className="menu active">
	                    <Link to="" className="dropdown-toggle mb-2">
                            <div className="">
                                <span title="My Reports">My Reports</span>
                            </div>
                        </Link>
                        
                    </li>
	                </ul>
	                
	            </nav>

	        </div>*/}
	       {/* <!--  END SIDEBAR  -->*/}

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content reportDetail">
		            <div className="layout-px-spacing">
					
		                <div className="middle-content container-xxl p-0">

		                    
		                    <div className="row layout-top-spacing">
		                    
		                    <div className="viewModule_rightSide">
		                    	<div className="d-flex justify-content-between align-items-baseline flex-wrap">
									<div className="col-md-4 col-12">
										<h5><Link to="/crm/reports"><AiOutlineArrowLeft /> Vendors vs. Purchases</Link></h5>
									</div>
									<div className="col-md-4 col-12 text-end">
										<Link to="/crm/report/edit" className="btn btn-secondary mx-1">Edit</Link>
										<button className="btn btn-secondary mx-1" disabled>Create Chart</button>
									</div>
								</div>
								<div className="mt-4 reportFilterSec row align-items-center">
									<div className="col-1">
										<h5>FILTERS</h5>
									</div>
									<div className="col-2">
									<select className="form-select form-control-sm">
							     		<option>None</option>
							     		<option>Created Time</option>
							     		<option>Due Date</option>
							     		<option>Modified Time</option>
							     		<option>PO Date</option>
							     	</select>
							     	</div>
							     	<div className="col-2">
							     	<select className="form-select form-control-sm" disabled>
							     		<option>None</option>
							     		<option>Age in Days</option>
							     		<option>Due in Days</option>
							     		<option>Between</option>
							     		<option>is before</option>
							     		<option>is after</option>
							     		<option>Previous FY</option>
							     		<option>Current FY</option>
							     	</select>
							     	</div>
							     	<div className="col-1">
							     	<select className="form-select form-control-sm" disabled>
							     		<option> {`=`} </option>
							     		<option> {`!=`} </option>
							     		<option> {`<`} </option>
							     		<option> {`<=`} </option>
							     		<option> {`>`} </option>
							     		<option> {`>=`} </option>
							     	</select>
							     	</div>
							     	<div className="col-3">
							     	<div className="form-group">
	                                  <input type="text" className="form-control" disabled/>
	                              	</div>
	                              	</div>
	                              	<div className="col-2">
	                              	<button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Apply</button>
	                              	</div>
								</div>
		                    	<div className="statbox widget box box-shadow moduleData">
		                            <div className="widget-content widget-content-area">
		                              
		                              	<MaterialReactTable 
		                              	onColumnVisibilityChange={setColumnVisibility}
                                      state={{columnVisibility}}
		                              	columns={columns} 
		                              	enableRowSelection={true}
		                              	data={layouts} 
		                              	// enableRowNumbers rowNumberMode="original"
		                              	/>
		                              
		                            </div>
                          		</div>
		                    </div>
		                     		
		                     	
		                    	
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}
		</>
	)
}

export default ReportDetail