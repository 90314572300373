import React,{useState,useEffect} from 'react';
// import Card from '../../components/Card';
import PasswordInput from '../../components/PasswordInput';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {BiLogIn} from 'react-icons/bi';
import { validateEmail } from "../../../redux/kbmone-features/auth/authService";
import {
  login,
  loginWithGoogle,
  RESET,
  sendLoginCode,
} from "../../../redux/kbmone-features/auth/authSlice";
import { GoogleLogin } from "@react-oauth/google";

const initialState = {
  email: "",
  password: "",
};

const Login = () => {

	const [formData, setFormData] = useState(initialState);
  	const { email, password } = formData;

	const handleInputChange = (e) => {
	    const { name, value } = e.target;
	    setFormData({ ...formData, [name]: value });
	};

	const dispatch = useDispatch();
  	const navigate = useNavigate();

  	const { isLoading, isLoggedIn, isSuccess, message, twoFactor, isError } =
    useSelector((state) => state.auth);

	const loginUser = async (e) => {
		e.preventDefault();

		if (!email || !password) {
	      return toast.error("All fields are required");
	    }

	    if (!validateEmail(email)) {
	      return toast.error("Please enter a valid email");
	    }

	    const userData = {
	      email,
	      password,
	    };

	    // console.log(userData);
	    await dispatch(login(userData));
	}

	useEffect(() => {
	    if (isSuccess && isLoggedIn) {
	    	// window.location.reload();
	      	navigate("/crm");
	    }

	    if(isError && twoFactor){
	    	dispatch(sendLoginCode(email));
	    	navigate(`/loginWithCode/${email}`)
	    }

	    dispatch(RESET());
	}, [isLoggedIn, isSuccess, dispatch, navigate, twoFactor, isError, email]);

	const googleLogin = async (credentialResponse) => {
    console.log(credentialResponse);
	    await dispatch(
	    	loginWithGoogle({ userToken: credentialResponse.credential })
	    );
	};

	return (
		<>
		<div className="auth-container d-flex">

        <div className="container mx-auto align-self-center">
    
            <div className="row">
    
                <div className="col-6 d-lg-flex d-none h-100 my-auto top-0 start-0 text-center justify-content-center flex-column">
                    <div className="auth-cover-bg-image"></div>
                    <div className="auth-overlay"></div>
                        
                    <div className="auth-cover">
    
                        <div className="position-relative">
    
                            <img src="../../src/assets/img/kbmlogo.png" alt="KBM_LOGO"/>
    
                            {/*<h2 className="mt-5 text-white font-weight-bolder px-2">Join the community of experts</h2>
                            <p className="text-white px-2">It is easy to setup with great customer experience.</p>*/}
                        </div>
                        
                    </div>

                </div>

                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-8 col-12 d-flex flex-column align-self-center ms-lg-auto me-lg-0 mx-auto">
                    <div className="card">
                        <div className="card-body">
    
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    
                                    <h2>Sign In KBM-ONE</h2>
                                    <p>Enter email and password to login your company</p>
                                    
                                </div>
                                <form onSubmit={loginUser}>
	                                <div className="col-md-12">
	                                    <div className="mb-3">
	                                    	<label>Email</label>
	                                        <input placeholder="Email *" type="email" className="form-control" name="email" value={email} onChange={handleInputChange}/>
	                                    </div>
	                                </div>
	                                <div className="col-12">
	                                    <div className="mb-4">
	                                    	<label>Password</label>
	                                        <PasswordInput placeholder="Password *" name="password" value={password} onChange={handleInputChange} />
	                                    </div>
	                                </div>
	                                
	                                <div className="col-12">
	                                    <div className="mb-4">
	                                        <button type="submit" className="btn btn-secondary w-100">SIGN IN</button>
	                                    </div>
	                                </div>
                                </form>
                                
                                {/*<div className="col-12 mb-4">
                                    <div className="">
                                        <div className="seperator">
                                            <hr/>
                                            <div className="seperator-text"> <span>Or continue with</span></div>
                                        </div>
                                    </div>
                                </div>
                                */}
                                {/*<div className="col-sm-12 col-12">
                                    <div className="mb-4">
                                        <button className="btn  btn-social-login w-100 ">
                                            <img src="https://designreset.com/cork/html/src/assets/img/google-gmail.svg" alt="" className="img-fluid"/>
                                            <span className="btn-text-inner">Google</span>
                                        </button>
                                        <GoogleLogin
							              onSuccess={googleLogin}
							              onError={() => {
							                console.log("Login Failed");
							                toast.error("Login Failed");
							              }}
							            />
                                    </div>
                                </div>*/}
    							<div className="col-12">
                                    <div className="text-center">
                                        <Link to="/forgot" className="text-warning">Forgot Password</Link>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="text-center">
                                        <p className="mb-0">Dont't have an account ? <Link to="/register" className="text-warning">Sign Up</Link></p>
                                    </div>
                                </div>

                                
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>

    </div>
		{/*<div className="container">
			<Card>
				<BiLogIn size={24} color='#3b3f5c'/>
				<h4>Login</h4>
				<div className="col-12 mb-3 text-center">
			    	<button type="submit" className="btn btn-primary">Login with Google</button>
			    	<strong className="mt-3 d-flex justify-content-center"><b>or</b></strong>
			    </div>
				<form onSubmit={loginUser}>
				    <div className="row mb-3">
				        <div className="col-sm-12">
				            <input type="email" className="form-control" placeholder="Email *" name="email" value={email} onChange={handleInputChange} required/>
				        </div>
				    </div>
				    <div className="row mb-3">
				        <div className="col-sm-12">
				        	<PasswordInput placeholder="Password *" name="password" value={password} onChange={handleInputChange} />
				        </div>
				    </div>
				    <div className="col-12 mb-2">
				    	<button type="submit" className="btn btn-primary w-100">Login</button>
				    </div>
				    <div className="d-flex justify-content-between">
				        <div className="">
				            <Link to="/forgot">Forgot Password</Link>
				        </div>
				        <div className="d-flex">
				        	<span className="mx-1">Don't have an account ?</span>
				            <Link to="/register">Register</Link>
				        </div>
				    </div>
				</form>
			</Card>
		</div>*/}
		</>
	)
}

export default Login