import React,{useEffect,useState,useRef,useMemo,useReducer} from 'react';
import ReactDOM from 'react-dom';
import { Link,useNavigate } from 'react-router-dom';
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit,AiOutlineEllipsis } from "react-icons/ai";
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import PasswordInput from '../components/PasswordInput';
import { toast } from "react-toastify";
import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { validateEmail } from "../../redux/crm-features/auth/authService";
import { useDispatch, useSelector } from "react-redux";
import ChangeRole from "../components/ChangeRole";
import {
  deleteUser,
  getAllUsers,
  RESET,
  sendVerificationEmail,
} from "../../redux/crm-features/auth/authSlice";

const UserList = () => {
	
	useRedirectLoggedOutUser("/crm/login");

	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const [ViewPost, SetPostShow] = useState(false)
  const handlePostShow = () => { SetPostShow(true) }
  const hanldePostClose = () => { SetPostShow(false) }

  const dispatch = useDispatch();

	const { users, isLoading, isLoggedIn, isSuccess, message } = useSelector(
	    (state) => state.auth
	);

	useEffect(() => {
	    dispatch(getAllUsers());
	}, [dispatch, reducerValue]);

	const removeUser = async (id) => {
	    await dispatch(deleteUser(id));
	    dispatch(getAllUsers());
	};

	const confirmDelete = (id) => {
	    confirmAlert({
	      title: "Delete This User",
	      message: "Are you sure to do delete this user?",
	      buttons: [
	        {
	          label: "Delete",
	          onClick: () => removeUser(id),
	        },
	        {
	          label: "Cancel",
	          // onClick: () => alert("Click No"),
	        },
	      ],
	    });
	};

const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'Name',
        Header: () => <span>Full Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          <span className="d-flex align-items-center">{renderedCellValue}
            <div className="dropdown">
              <span className="dropdown-toggle mx-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <AiOutlineEllipsis />
              </span>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><Link className="dropdown-item" to="#" onClick={() => confirmDelete(row.original._id)}>Delete</Link></li>
              </ul>
            </div>
          </span>
        ),
      },
      {
        accessorFn: (row) => row.email, //alternate way
        id: 'email', //id required if you use accessorFn instead of accessorKey
        header: 'Email',
        Header: () => <span>Email Address</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.profile, //alternate way
        id: 'profile', //id required if you use accessorFn instead of accessorKey
        header: 'Profile',
        Header: () => <span>Profile</span>, //optional custom header render
      },
      {
        accessorFn: (row) => 
        <>
        <span>{row.role}</span>
        <ChangeRole _id={row._id} email={row.email}/>
        </>, //alternate way
        id: 'role', //id required if you use accessorFn instead of accessorKey
        header: 'Role',
        Header: () => <span>Role</span>, //optional custom header render
      },
      
      // {
      //   accessorFn: (row) => <span>
      //                       <FaTrashAlt
      //                         size={18}
      //                         cursor="pointer"
      //                         color="rgb(0,0,0,.8)"
      //                         onClick={() => confirmDelete(row._id)}
      //                       />
      //                     </span>, //alternate way
      //   id: 'action', //id required if you use accessorFn instead of accessorKey
      //   header: 'Action',
      //   Header: () => <span>Action</span>, //optional custom header render
      // },
    ],
    [],
  );

const isFirstRender = useRef(true);
	const [columnVisibility, setColumnVisibility] = useState({});

	useEffect(() => {
	    const columnVisibility = localStorage.getItem(
	      'mrt_columnVisibility_table_1',
	    );
	    
	    if (columnVisibility) {
	      setColumnVisibility(JSON.parse(columnVisibility));
	    }
	    
	    isFirstRender.current = false;
	  }, []);

	useEffect(() => {
	    if (isFirstRender.current) return;
	    localStorage.setItem(
	      'mrt_columnVisibility_table_1',
	      JSON.stringify(columnVisibility),
	    );
	  }, [columnVisibility]);


	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">
		                        
		                        <div className="tabMenu">
		                        	<ul>
		                        		<li><Link className="btn" to="/crm/user">User</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/users-list">User's List</Link></li>
		                        	</ul>
		                        </div>

		                        <div className="col-12">
		                        	<div className="statbox widget box box-shadow moduleData">
		                                <div className="widget-content widget-content-area">
		                                {users.length === 0 ? (
		                                	<p>User not found...</p>
		                                ):(
		                                	<MaterialReactTable 
		                                	onColumnVisibilityChange={setColumnVisibility}
                                      state={{columnVisibility}}
                                      enableRowSelection={true}
		                                	columns={columns} data={users} 
		                                	// enableRowNumbers rowNumberMode="original"
		                                	/>
		                                )}
		                                </div>
		                            </div>
		                        </div>
		                        
		                       
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}


		</>
	);
}

export default UserList