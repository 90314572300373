import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import leadService from './leadService';

const initialState = {
	lead: null,
	leads: [],
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: "",
};

//export File
export const exportFile = createAsyncThunk (
	"lead/exportFile",
	async (moduleData, thunkAPI) => {
		try{
			return await leadService.exportFile(moduleData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// add lead
export const importFile = createAsyncThunk (
	"lead/importFile",
	async (leadData, thunkAPI) => {
		try{
			return await leadService.importFile(leadData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// add lead
export const createLead = createAsyncThunk (
	"lead/createLead",
	async (leadData, thunkAPI) => {
		try{
			return await leadService.createLead(leadData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//get leads
export const getLeads = createAsyncThunk (
	"lead/getLeads",
	async (id, thunkAPI) => {
		try{
			return await leadService.getLeads(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)


// get user profile
export const getLead = createAsyncThunk (
	"lead/getLead",
	async (id, thunkAPI) => {
		try{
			return await leadService.getLead(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// update user profile
export const updateLead = createAsyncThunk (
	"lead/updateLead",
	async ({id, moduleData}, thunkAPI) => {
		try{
			return await leadService.updateLead(id, moduleData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//delete user profile
export const deleteLead = createAsyncThunk (
	"lead/deleteLead",
	async (id, thunkAPI) => {
		try{
			return await leadService.deleteLead(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

const leadSlice = createSlice ({
	name: 'lead',
	initialState,
	reducers: {
		
	},
	extraReducers: (builder) =>{
		builder
		
			//export file
			.addCase(exportFile.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(exportFile.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.leads = action.payload;
				toast.success('Data Exported Successfully.');
			})
			.addCase(exportFile.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//import file
			.addCase(importFile.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(importFile.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.leads = action.payload;
				toast.success('Data Imported Successfully.');
			})
			.addCase(importFile.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//add lead
			.addCase(createLead.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(createLead.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.leads = action.payload;
				toast.success('Record Created Successfully.');
			})
			.addCase(createLead.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get lead
			.addCase(getLead.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getLead.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.leads = action.payload;
			})
			.addCase(getLead.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//update lead
			.addCase(updateLead.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(updateLead.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.lead = action.payload;
				toast.success("Record Updated Successfully.");
			})
			.addCase(updateLead.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get all leads
			.addCase(getLeads.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getLeads.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.leads = action.payload;
			})
			.addCase(getLeads.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//delete lead
			.addCase(deleteLead.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(deleteLead.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(deleteLead.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

	}
});

export const selectLead = (state) => state.lead.lead;

export default leadSlice.reducer;