import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import roleService from './roleService';

const initialState = {
	userRole: null,
	userRoles: [],
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: "",
};

// add user role
export const addRole = createAsyncThunk (
	"userRole/addRole",
	async (roleData, thunkAPI) => {
		try{
			return await roleService.addRole(roleData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//get all users roles
export const getRoles = createAsyncThunk (
	"userRole/getRoles",
	async (_, thunkAPI) => {
		try{
			return await roleService.getRoles();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get user role
export const getRole = createAsyncThunk (
	"userRole/getRole",
	async (id, thunkAPI) => {
		try{
			return await roleService.getRole(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// update user role
export const updateRole = createAsyncThunk (
	"userRole/updateRole",
	async ({id, roleData}, thunkAPI) => {
		try{
			return await roleService.updateRole(id, roleData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//delete user role
export const deleteRole = createAsyncThunk (
	"userRole/deleteRole",
	async (id, thunkAPI) => {
		try{
			return await roleService.deleteRole(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

const roleSlice = createSlice ({
	name: 'userRole',
	initialState,
	reducers: {
		
	},
	extraReducers: (builder) =>{
		builder
			
			//add user role
			.addCase(addRole.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(addRole.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.userRoles = action.payload;
				toast.success('Role Add Successful');
			})
			.addCase(addRole.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get user role
			.addCase(getRole.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getRole.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.userRole = action.payload;
			})
			.addCase(getRole.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//update user role
			.addCase(updateRole.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(updateRole.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.userRole = action.payload;
				toast.success("Role is Updated");
			})
			.addCase(updateRole.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get all users roles
			.addCase(getRoles.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getRoles.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.userRoles = action.payload;
			})
			.addCase(getRoles.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//delete user role
			.addCase(deleteRole.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(deleteRole.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(deleteRole.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

	}
});

export const selectUserRole = (state) => state.userRole.userRole;

export default roleSlice.reducer;