import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../redux/crm-features/auth/authSlice';
import emailReducer from "../redux/crm-features/email/emailSlice";
import profileReducer from "../redux/crm-features/userProfile/profileSlice";
import roleReducer from "../redux/crm-features/userRole/roleSlice";
import moduleReducer from "../redux/crm-features/moduleItem/moduleSlice";
import layoutReducer from "../redux/crm-features/layout/layoutSlice";
import leadReducer from "../redux/crm-features/lead/leadSlice";
import kbmoneauthReducer from '../redux/kbmone-features/auth/authSlice';
import kbmoneemailReducer from "../redux/kbmone-features/email/emailSlice";
import kbmoneappReducer from "../redux/kbmone-features/userApp/appSlice";

export const store = configureStore ({
	reducer: {
		auth: authReducer,
		email: emailReducer,
		userProfile: profileReducer,
		userRole: roleReducer,
		moduleItem: moduleReducer,
		layout: layoutReducer,
		lead: leadReducer,
		auth: kbmoneauthReducer,
		email: kbmoneemailReducer,
		userApp: kbmoneappReducer,
	},
});