import React,{useState} from 'react';
import Breadcrumb from '../components/Breadcrumb';
import { Modal } from 'react-bootstrap';
import { Link,useNavigate } from 'react-router-dom';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

const UserGroup = () => {
	useRedirectLoggedOutUser("/crm/login");

	const [ViewPost, SetPostShow] = useState(false)
    const handlePostShow = () => { SetPostShow(true) }
    const hanldePostClose = () => { SetPostShow(false) }

    const newGroup = async (event) => {
		event.preventDefault();

	};

	return (
		<>
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">
		                        
		                        <div className="tabMenu">
		                        	<ul>
		                        		{/*<li><Link className="btn btn-secondary" to="/user">Users</Link></li>*/}
		                        		<li><Link className="btn btn-secondary" to="/crm/user">Active Users</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/user-inactive">Inactive Users</Link></li>
		                        		<li><Link className="btn btn-secondary active" to="/crm/users-group">Groups</Link></li>
		                        	</ul>
		                        </div>

		                        <div className="group_user_sec mt-4">

		                        	<div className="d-flex justify-content-between align-items-baseline">
										<div className="col-md-8 col-12">
											<h5>Groups</h5>
											<p>This page lists all the public groups created for your Organization.</p>
										</div>
										<Link to="/crm">Help</Link>
									</div>
									<button onClick={() => { handlePostShow() }} className="btn btn-secondary" type="button">Create Group</button>
		                        </div>
		                       
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}


		{/*<!-- Modal -->*/}
		    <Modal show={ViewPost}
                    onHide={hanldePostClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
			
			        <form className="form"  onSubmit={newGroup}>
			        	<h5 className="modal-title">Create New Group</h5>
			            <div className="col-md-12">
                          <div className="form-group">
                              <input type="text" className="form-control mb-3" id="groupName" placeholder="Group Name" name="name" />
                          </div>
                      	</div>
                      	<div className="col-md-12">
                          <div className="form-group">
                              <textarea type="text" className="form-control mb-3" id="groupDesc" placeholder="Description" name="desc" ></textarea>
                          </div>
                        </div>
                      	<div className="col-md-12 mt-1">
                          <div className="form-group text-end">
                              <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
        					  <button type="button" className="btn btn btn-light-dark mx-3" onClick={hanldePostClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                          </div>
                        </div>
                    </form>
			            
			</Modal>
		</>
	)
}

export default UserGroup