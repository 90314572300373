module.exports = {
  destPix: './pix',
  destFile: './files',
  // destPix: '../pix.whoraly.com/kbm/pix',
  // destFile: '../pix.whoraly.com/kbm/file',
  pixAddress: 'https://pqglobaledu.co.uk/pix/',
  fileAddress: 'https://pqglobaledu.c.uk/files/',

  objects: {
    section: 'New Section',
    singleLine: 'Single Line',
    multiLine: 'Multi Line',
    number: 'Number',
    email: 'Email',
    password: 'Password',
    checkbox: 'Checkbox',
    longInteger: 'Long Integer',
    date: 'Date',
    datetime: 'Date/Time',
    percent: 'Percent',
    currency: 'Currency',
    phone: 'Phone',
    url: 'URL',
    pickList: 'Pick List',
    multiSelect: 'Multi Select',
    imageUpload: 'Image Upload',
    fileUpload: 'File Upload',
    lookup: 'Lookup',
    user: 'User',
  },

  formBuilderBasicState: {
    w: window.innerWidth,
    h: window.innerHeight,
    idX: '',
    mode: 'builder',
    saveType: 'new',
    title: '',
    calendarCheck: false,
    formBoxScrollPos: 0,
    optionBoxScrollPos: 0,
    elmIdArr: {formBox: {type:'form'}},
    objArr: [],
    objectArr: [],
    objN: {
      section: 0,
      singleLine: 0,
      multiLine: 0,
      number: 0,
      email: 0,
      password: 0,
      checkbox: 0,
      longInteger: 0,
      date: 0,
      datetime: 0,
      percent: 0,
      currency: 0,
      phone: 0,
      url: 0,
      pickList: 0,
      multiSelect: 0,
      imageUpload: 0,
      fileUpload: 0,
      lookup: 0,
      user: 0,
    },
    formList: [],
    fCIndex: {},
    formConnectArr: [],
    formData: {},
    staticObj: {},
  },

}