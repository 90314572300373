import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";

const EditDataSharing = () => {
	
	useRedirectLoggedOutUser("/crm/login");

	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">
		                        
		                        <div className="tabMenu">
		                        	<ul>
		                        		<li><Link className="btn" to="/crm/security-control">Profiles</Link></li>
		                        		<li><Link className="btn" to="/crm/user-role">User Roles</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/data-sharing-setting">Data Sharing Settings</Link></li>
		                        		<li><Link className="btn" to="/crm">Zoho Mail Add-On Users</Link></li>
		                        	</ul>
		                        </div>

		                        <div className="col-12 text-end mt-4">
			                        <div className="d-flex align-items-center justify-content-between">
			                        	<h5>Edit Default Organization Permissions</h5>
			                        	<button className="btn btn-secondary">Save</button>
			                        </div>
		                        </div>

		                        
		                        <div className="customTable">
								    <table>
								        <thead>
								        </thead>
								        <tbody>
								            <tr>
								                <td><b>Lead</b></td>
								                <td>
									                <div class="form-check form-check-secondary form-check-inline">
													    <input class="form-check-input" type="radio" name="defaultAccess" id="defaultAccess1"/>
													    <label class="form-check-label" for="defaultAccess1">
													        Private
													    </label>
													</div>

													<div class="form-check form-check-secondary form-check-inline">
													    <input class="form-check-input" type="radio" name="defaultAccess" id="defaultAccess2"/>
													    <label class="form-check-label" for="defaultAccess2">
													        Public Read Only
													    </label>
													</div>

													<div class="form-check form-check-secondary form-check-inline">
													    <input class="form-check-input" type="radio" name="defaultAccess" id="defaultAccess3"/>
													    <label class="form-check-label" for="defaultAccess3">
													        Public Read/Write
													    </label>
													</div>

													<div class="form-check form-check-secondary form-check-inline">
													    <input class="form-check-input" type="radio" name="defaultAccess" id="defaultAccess4"/>
													    <label class="form-check-label" for="defaultAccess4">
													        Public Read/Write/Delete
													    </label>
													</div>
												</td>
								            </tr>
								            <tr>
								                <td><b>Contact</b></td>
								                <td>
									                <div class="form-check form-check-secondary form-check-inline">
													    <input class="form-check-input" type="radio" name="defaultAccess" id="defaultAccess5"/>
													    <label class="form-check-label" for="defaultAccess5">
													        Private
													    </label>
													</div>

													<div class="form-check form-check-secondary form-check-inline">
													    <input class="form-check-input" type="radio" name="defaultAccess" id="defaultAccess6"/>
													    <label class="form-check-label" for="defaultAccess6">
													        Public Read Only
													    </label>
													</div>

													<div class="form-check form-check-secondary form-check-inline">
													    <input class="form-check-input" type="radio" name="defaultAccess" id="defaultAccess7"/>
													    <label class="form-check-label" for="defaultAccess7">
													        Public Read/Write
													    </label>
													</div>

													<div class="form-check form-check-secondary form-check-inline">
													    <input class="form-check-input" type="radio" name="defaultAccess" id="defaultAccess8"/>
													    <label class="form-check-label" for="defaultAccess8">
													        Public Read/Write/Delete
													    </label>
													</div>
												</td>
								            </tr>
								        </tbody>
								    </table>
								</div>
		                       
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		</>
	);
}

export default EditDataSharing