import React,{useEffect, useLayoutEffect, useState} from 'react';
import Breadcrumb from '../components/Breadcrumb';
// import profileImg from "../../assets/avatarr.png";
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { Link,useParams } from 'react-router-dom';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getUser,
  selectUser,
  updateUser,
} from "../../redux/crm-features/auth/authSlice";
// import {profileImage} from "../src/assets/img/profile-3.jpg";
import Notification from "../components/Notification";

const cloud_name = process.env.REACT_APP_CLOUD_NAME;
const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;

export const shortenText = (text, n) => {
  if (text.length > n) {
    const shoretenedText = text.substring(0, n).concat("...");
    return shoretenedText;
  }
  return text;
};

const ProfileAccount = () => {
	useRedirectLoggedOutUser("/crm/login");

	const { isLoading, isLoggedIn, isSuccess, message, user } = useSelector(
	    (state) => state.auth
	);

	const initialState = {
	    name: user?.name || "",
	    email: user?.email || "",
	    phone: user?.phone || "",
	    desc: user?.desc || "",
	    photo: user?.photo || "",
	    role: user?.role || "",
	    isVerified: user?.isVerified || false,
	};

	// const { id } = useParams();
	const [profile, setProfile] = useState(initialState);
	const [profileImage, setProfileImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
	const dispatch = useDispatch();
	
	useEffect(() => {
	    dispatch(getUser());
	}, [dispatch]);

	const handleImageChange = (e) => {
		setProfileImage(e.target.files[0]);
    	setImagePreview(URL.createObjectURL(e.target.files[0]));
	}

	const handleInputChange = (e) => {
	    const { name, value } = e.target;
	    setProfile({ ...profile, [name]: value });
	};

	const saveProfile = async (e) =>{
		e.preventDefault();
		let imageURL;
		try{
			if (profileImage !== null &&(profileImage.type === "image/jpeg" || profileImage.type === "image/jpg" ||
				 profileImage.type === "image/png"))
			{

				const image = new FormData();
		        image.append("file", profileImage);
		        image.append("cloud_name", cloud_name);
		        image.append("upload_preset", upload_preset);

		        // Save image to Cloudinary
		        const response = await fetch(
		          "https://api.cloudinary.com/v1_1/kbm/image/upload",
		          { method: "post", body: image }
		        );
		        const imgData = await response.json();
		        // console.log(imgData);
		        imageURL = imgData.url.toString();

			}

			// Save profile to MongoDB
		    const userData = {
		        name: profile.name,
		        phone: profile.phone,
		        desc: profile.desc,
		        photo: profileImage ? imageURL : profile.photo,
		    };

			dispatch(updateUser(userData));
		}catch(error){
			toast.error(error.message);
		}
	}


	useLayoutEffect(() => {
	    if (user) {
	      setProfile({
	        ...profile,
	        name: user.name,
	        email: user.email,
	        phone: user.phone,
	        photo: user.photo,
	        desc: user.desc,
	        role: user.role,
	        isVerified: user.isVerified,
	      });
	    }
	}, [user]);

	return (
		<div>

		{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">
		

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">
		            {!profile.isVerified && <Notification />}
		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">

		                    {/*<!-- Content -->*/}
		                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
		                            <div className="user-profile">
		                                <div className="widget-content widget-content-area">
		                                    <div className="d-flex justify-content-between">
		                                        <h3 className="">Profile</h3>
		                                        {/*<Link to="user-account-settings.html" className="mt-2 edit-profile"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg></Link>*/}
		                                    </div>
		                                    <form className="form" onSubmit={saveProfile}>
			                                    <div className="text-center user-info">
			                                        <div className="profile_image_upload">
			                                        	<img src={imagePreview === null ? user?.photo : imagePreview} alt="avatar"/>
			                                        	<input type="file" accept="image/*" name="image" onChange={handleImageChange}/>
			                                        </div>
			                                        <p className="text-capitalize mb-0">{profile?.name}</p>
			                                        <span>{profile?.role}</span>
			                                    </div>
		                                    
	                                            <div className="row mt-4">
	                                                <div className="col-md-6">
	                                                    <div className="form-group">
	                                                        <input type="text" className="form-control mb-3" id="fullName" placeholder="Name" name="name" value={profile?.name} onChange={handleInputChange}/>
	                                                    </div>
	                                                </div>

	                                                <div className="col-md-6">
	                                                    <div className="form-group">
	                                                        <input type="email" className="form-control mb-3" id="email" name="email" placeholder="Email" value={profile?.email} onChange={handleInputChange}/>
	                                                    </div>
	                                                </div>
	                                                
	                                                <div className="col-md-12">
	                                                    <div className="form-group">
	                                                        <input type="text" className="form-control mb-3" name="phone" id="phone" placeholder="Phone Number" value={profile?.phone} onChange={handleInputChange}/>
	                                                    </div>
	                                                </div>                              
	                                                <div className="col-md-12">
	                                                    <div className="form-group">
	                                                        <textarea type="text" className="form-control mb-3" name="desc" cols="30" rows="10" placeholder="Description" value={profile?.desc} onChange={handleInputChange}></textarea>
	                                                    </div>
	                                                </div>

	                                                <div className="col-md-12 mt-1">
	                                                    <div className="form-group text-end">
	                                                        <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Update Profile</button>
	                                                    </div>
	                                                </div>
	                                                
	                                            </div>
	                                            
	                                        </form>
		                                </div>
		                            </div>
		                        </div>

		                        

		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}
			
		</div>
	)
}

export const UserProfile = () => {
  const profile = useSelector(selectUser);

  const userProfile = profile?.photo || "...";

  return <img alt="avatar" src={userProfile} className="rounded-circle"/>;
};

export const UserName = () => {
  const profile = useSelector(selectUser);

  const userName = profile?.name || "...";

  return <h5 className="mb-0 text-capitalize">{shortenText(userName,8)}</h5>;
};

export const UserRole = () => {
  const profile = useSelector(selectUser);

  const userRole = profile?.role || "...";

  return <span className="text-capitalize">{shortenText(userRole)}</span>;
};

export default ProfileAccount