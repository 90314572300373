import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import appService from './appService';

const initialState = {
	userApp: null,
	userApps: [],
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: "",
};

// add user app
export const addApp = createAsyncThunk (
	"userApp/addApp",
	async (item, thunkAPI) => {
		try{
			return await appService.addApp(item);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//get all users apps
export const getApps = createAsyncThunk (
	"userApp/getApps",
	async (_, thunkAPI) => {
		try{
			return await appService.getApps();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)


// get user app
export const getApp = createAsyncThunk (
	"userApp/getApp",
	async (id, thunkAPI) => {
		try{
			return await appService.getApp(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// update user role
export const updateApp = createAsyncThunk (
	"userApp/updateApp",
	async ({id, item}, thunkAPI) => {
		try{
			return await appService.updateApp(id, item);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//delete user role
export const deleteApp = createAsyncThunk (
	"userApp/deleteApp",
	async (id, thunkAPI) => {
		try{
			return await appService.deleteApp(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

const appSlice = createSlice ({
	name: 'userApp',
	initialState,
	reducers: {
		
	},
	extraReducers: (builder) =>{
		builder
			
			//add user app
			.addCase(addApp.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(addApp.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.userApps = action.payload;
				toast.success('App Add Successful');
			})
			.addCase(addApp.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get user app
			.addCase(getApp.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getApp.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.userApp = action.payload;
			})
			.addCase(getApp.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//update user app
			.addCase(updateApp.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(updateApp.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.userApp = action.payload;
				toast.success("App is Updated");
			})
			.addCase(updateApp.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get all users apps
			.addCase(getApps.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getApps.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.userApps = action.payload;
			})
			.addCase(getApps.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//delete user app
			.addCase(deleteApp.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(deleteApp.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(deleteApp.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

	}
});

export const selectUserApp = (state) => state.userApp.userApp;

export default appSlice.reducer;