import React,{useEffect,useLayoutEffect,useRef,useMemo,useState,useReducer} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit,AiOutlineEllipsis } from "react-icons/ai";
import Footer from '../components/Footer';
import PasswordInput from '../components/PasswordInput';
import { toast } from "react-toastify";
import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { validateEmail } from "../../redux/crm-features/auth/authService";
import { useDispatch, useSelector } from "react-redux";
import ChangeRole from "../components/ChangeRole";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  addUser,
  RESET,
  getAllUsers,
  getAllUsersActive,
  deleteUser,
  sendVerificationEmail,
} from "../../redux/crm-features/auth/authSlice";
import {
  	getProfiles
} 	from "../../redux/crm-features/userProfile/profileSlice";
import {
  	getRoles
} 	from "../../redux/crm-features/userRole/roleSlice";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// const cloud_name = process.env.REACT_APP_CLOUD_NAME;
// const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;

const initialState = {
	    name : '',
			lname : '',
			photo: '',
			email : '',
			password : '',
			phone : '',
			profile: '',
			role: '',
			fax: '',
			website: '',
			mobile: '',
			state: '',
			dateOfBirth: '',
			street: '',
			zipCode: '',
			city: '',
			country: '',
			status: '',
	};

const Users = () => {
	useRedirectLoggedOutUser("/crm/login");

	const { users, isLoading, isLoggedIn, isSuccess, message } = useSelector(
	    (state) => state.auth
	);

	const { userProfiles } = useSelector(
	    (state) => state.userProfile
	);

	const { userRoles } = useSelector(
	    (state) => state.userRole
	);

	const navBgcolors = ['#660000','#990000','#D24143','#DE4F5D', '#ea4c88', '#993399', '#663399', '#07385D',
						 '#1e5598', '#2d72d9', '#366dc7', '#018EE0', '#0099cc', '#37a5a5', '#439454', '#336600',
						 '#165151', '#999900', '#E9A23F', '#996633', '#553A48', '#313949'];

	const setNavbg = (bgColor) =>{
		document.documentElement.style.setProperty('--bgNav-color',bgColor)
	}

	useEffect(()=>{

		const currentBgColor = localStorage.getItem('bgColor');
		setNavbg(currentBgColor);
		// console.log(currentBgColor);

	},[])

	const setNavBgColor = (event) =>{
		const currentBgColor = event.target.style.getPropertyValue('--bgNav-color');
		setNavbg(currentBgColor);
		localStorage.setItem('bgColor',currentBgColor);
		// console.log(currentColor);
	}

	const [formData, setFormData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const {name,lname,email,password,phone,profile,role,chatAllocate,photo,fax,website,mobile,state,street,zipCode,city,country,dateOfBirth,status} = formData;
	const [ViewPost, SetPostShow] = useState(false)
  const handlePostShow = () => { SetPostShow(true) }
  const hanldePostClose = () => { SetPostShow(false) }

    const dispatch = useDispatch();
	const navigate = useNavigate();

	const [imagePreview, setImagePreview] = useState(null);
	const [profileImage, setProfileImage] = useState(null);
	const [show,setShow]=useState(true);

	var buttonText = show ? "Hide Details" : "Show Details";

	useEffect(() => {
	    dispatch(getProfiles());
	    dispatch(getRoles());
	    dispatch(getAllUsersActive());
	    // dispatch(getAllUsers());

	}, [dispatch, reducerValue]);

	const handleImageChange = (e) => {
		setProfileImage(e.target.files[0]);
    	setImagePreview(URL.createObjectURL(e.target.files[0]));
	}

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setFormData({...formData, [name]: value});
	}

	const userAdd = async (event) => {
		event.preventDefault();

		if (!name || !email || !password || !phone || !profile || !role || !chatAllocate){
			return toast.error('All fields are required')
		}
		if (password.length < 6) {
	      	return toast.error("Password must be up to 6 characters");
	    }
		// if (!validateEmail(email)){
		// 	return toast.error('Please enter a valid email')
		// }

		const userData = {name, lname, email, password, phone, profile, role,chatAllocate, userType:"kbmcrmuser",status,
											fax,mobile,state,street,zipCode,website,city,country,dateOfBirth};
		// console.log(userData);
		await dispatch(addUser(userData));
		setFormData({ name: "", lname:"", email: "", password: "", phone: "", role: "", chatAllocate: "", profile: "", fax: '', mobile: '', state: '',
									street: '', zipCode: '', website: '', city: '', country: '', dateOfBirth: '', status: '' });
		SetPostShow(false);
		forceUpdate();
		navigate('/crm/user');
		// window.location.reload();
		// await dispatch(sendVerificationEmail());
	};

	const removeUser = async (id) => {
	    await dispatch(deleteUser(id));
	    dispatch(getAllUsersActive());
	};

	const confirmDelete = (id) => {
	    confirmAlert({
	      title: "Delete This User",
	      message: "Are you sure to do delete this user?",
	      buttons: [
	        {
	          label: "Delete",
	          onClick: () => removeUser(id),
	        },
	        {
	          label: "Cancel",
	          // onClick: () => alert("Click No"),
	        },
	      ],
	    });
	};

	const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'First Name',
        Header: () => <span>First Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          /*<Link to={`/crm/edit-user/${row.original._id}`}>{renderedCellValue}</Link>*/
          <span className="d-flex align-items-center"><Link to={`/crm/edit-user/${row.original._id}`}>{renderedCellValue}</Link>
            <div className="dropdown">
              <span className="dropdown-toggle mx-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <AiOutlineEllipsis />
              </span>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><Link className="dropdown-item" to={`/crm/edit-user/${row.original._id}`}>Edit</Link></li>
                <li><Link className="dropdown-item" to="#" onClick={() => confirmDelete(row.original._id)}>Delete</Link></li>
              </ul>
            </div>
          </span>
        ),
      },
      {
        accessorFn: (row) => `${row.lname}`, //alternate way
        id: 'lname', //id required if you use accessorFn instead of accessorKey
        header: 'Last Name',
        Header: () => <span>Last Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          <Link to={`/crm/edit-user/${row.original._id}`}>{renderedCellValue}</Link>
        ),
      },
      {
        accessorFn: (row) => <Link to={`/crm/edit-user/${row._id}`}><img src={row.photo} width="60" height="60" alt="user-img"/></Link>, //alternate way
        id: 'photo', //id required if you use accessorFn instead of accessorKey
        header: 'Photo',
        Header: () => <span>Photo</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.email, //alternate way
        id: 'email', //id required if you use accessorFn instead of accessorKey
        header: 'Email',
        Header: () => <span>Email Address</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.profile, //alternate way
        id: 'profile', //id required if you use accessorFn instead of accessorKey
        header: 'Profile',
        Header: () => <span>Profile</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.chatAllocate, //alternate way
        id: 'chatAllocate', //id required if you use accessorFn instead of accessorKey
        header: 'Chat Access',
        Header: () => <span>Chat Access</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.status, //alternate way
        id: 'status', //id required if you use accessorFn instead of accessorKey
        header: 'Status',
        Header: () => <span>Status</span>, //optional custom header render
      },
      {
        accessorFn: (row) => 
        <>
        <span>{row.role}</span>
        <ChangeRole _id={row._id} email={row.email}/>
        </>, //alternate way
        id: 'role', //id required if you use accessorFn instead of accessorKey
        header: 'Role',
        Header: () => <span>Role</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.phone, //alternate way
        id: 'phone', //id required if you use accessorFn instead of accessorKey
        header: 'Phone',
        Header: () => <span>Phone</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.fax, //alternate way
        id: 'fax', //id required if you use accessorFn instead of accessorKey
        header: 'Fax',
        Header: () => <span>Fax</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.website, //alternate way
        id: 'website', //id required if you use accessorFn instead of accessorKey
        header: 'Website',
        Header: () => <span>Website</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.mobile, //alternate way
        id: 'mobile', //id required if you use accessorFn instead of accessorKey
        header: 'Mobile',
        Header: () => <span>Mobile</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.state, //alternate way
        id: 'state', //id required if you use accessorFn instead of accessorKey
        header: 'State',
        Header: () => <span>State</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.dateOfBirth, //alternate way
        id: 'dateOfBirth', //id required if you use accessorFn instead of accessorKey
        header: 'Date of Birth',
        Header: () => <span>Date of Birth</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.street, //alternate way
        id: 'street', //id required if you use accessorFn instead of accessorKey
        header: 'Street',
        Header: () => <span>Street</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.zipCode, //alternate way
        id: 'zipCode', //id required if you use accessorFn instead of accessorKey
        header: 'Zip Code',
        Header: () => <span>Zip Code</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.city, //alternate way
        id: 'city', //id required if you use accessorFn instead of accessorKey
        header: 'City',
        Header: () => <span>City</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.country, //alternate way
        id: 'country', //id required if you use accessorFn instead of accessorKey
        header: 'Country',
        Header: () => <span>Country</span>, //optional custom header render
      },
      // {
      //   accessorFn: (row) => <span>
      //                       <FaTrashAlt
      //                         size={18}
      //                         cursor="pointer"
      //                         color="rgb(0,0,0,.8)"
      //                         onClick={() => confirmDelete(row._id)}
      //                       />
      //                       <Link to={`/crm/edit-user/${row._id}`}>
	     //                        <AiFillEdit
	     //                          size={20}
	     //                          cursor="pointer"
	     //                          color="rgb(0,0,0,.8)"
	     //                        />
      //                       </Link>
      //                     </span>, //alternate way
      //   id: 'action', //id required if you use accessorFn instead of accessorKey
      //   header: 'Action',
      //   Header: () => <span>Action</span>, //optional custom header render
      // },
    ],
    [],
  );

const isFirstRender = useRef(true);
	const [columnVisibility, setColumnVisibility] = useState({});

	useEffect(() => {
	    const columnVisibility = localStorage.getItem(
	      'mrt_columnVisibility_table_1',
	    );
	    
	    if (columnVisibility) {
	      setColumnVisibility(JSON.parse(columnVisibility));
	    }
	    
	    isFirstRender.current = false;
	  }, []);

	useEffect(() => {
	    if (isFirstRender.current) return;
	    localStorage.setItem(
	      'mrt_columnVisibility_table_1',
	      JSON.stringify(columnVisibility),
	    );
	  }, [columnVisibility]);
	
	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">
		                        
		                        <div className="tabMenu">
		                        	<ul>
		                        		{/*<li><Link className="btn btn-secondary " to="/user">Users</Link></li>*/}
		                        		<li><Link className="btn btn-secondary active" to="/crm/user">Active Users</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/user-inactive">Inactive Users</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/users-group">Groups</Link></li>
		                        	</ul>
		                        </div>

		                        <div className="col-12 text-end mt-4">
		                        	<button onClick={() => { handlePostShow() }} className="btn btn-secondary" type="button">Add User</button>
		                        </div>

		                        <div className="active_user_sec">
		                        
		                        	<div className="col-12">
			                        	<div className="statbox widget box box-shadow moduleData">
			                                <div className="widget-content widget-content-area">
			                                {users.length === 0 ? (
			                                	<p>User not found...</p>
			                                ):(
			                                	<MaterialReactTable 
			                                	onColumnVisibilityChange={setColumnVisibility}
                                      state={{columnVisibility}}
			                                	columns={columns} 
			                                	enableRowSelection={true}
			                                	data={users} 
			                                	// enableRowNumbers rowNumberMode="original"
			                                	/>
			                                )}
			                                </div>
			                            </div>
			                        </div>

															{/*<div className="theme_setting">
																<h5>Themes</h5>
																<div className="colors-list">
																	{navBgcolors.map((bgColor,idx)=> 
																		<div className="color-item" key={idx} bgcolor={bgColor} onClick={setNavBgColor} style={{'--bgNav-color':bgColor}}>
																		</div>
																	)}
																</div>
															</div>*/}
			                    	

		                        </div>
		                       
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		     {/*<!-- Modal -->*/}
		    <Modal show={ViewPost}
                    onHide={hanldePostClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
			
			            	<form className="form" onSubmit={userAdd}>
			            	<h5 className="modal-title">Add User</h5>
			            	<Tabs>
									    <TabList>
									      <Tab>Simple Form</Tab>
									      <Tab>Detail Form</Tab>
									    </TabList>

									    <TabPanel>
									      <div className="row mt-4">
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>First Name</label>
                                  <input type="text" className="form-control mb-3" id="fullName" placeholder="James" name="name" value={name} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Last Name</label>
                                  <input type="text" className="form-control mb-3" id="lastName" placeholder="Arthur" name="lname" value={lname} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Email</label>
                                  <input type="email" className="form-control mb-3" id="email" name="email" placeholder="abc@example.com" value={email} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group mb-3">
                                  <label>Password</label>
                                  <PasswordInput placeholder="Password *" name="password" value={password} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Phone</label>
                                  <input type="text" className="form-control mb-3" name="phone" id="phone" placeholder="12345678" value={phone} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <label>Profile</label>
                          	<select value={profile} onChange={handleInputChange} name="profile" className="form-select form-control-sm mb-3">
											     		<option>Select Profile</option>
											     		{
											     			userProfiles && userProfiles.map((item) => {
											     				return(
											     						<option key={item._id} value={item.name}>{item.name}</option>
											     					)
											     			})
											     		}
											     		
											     	</select>
                          </div>
                          <div className="col-md-12">
                              <label>Role</label>
                          	<select value={role} onChange={handleInputChange} name="role" className="form-select form-control-sm mb-3">
											     		<option>Select Role</option>
											     		{
											     			userRoles && userRoles.map((item) => {
											     				return(
											     						<option key={item._id} value={item.name}>{item.name}</option>
											     					)
											     			})
											     		}
											     	</select>
                          </div>
                          <div className="col-md-12">
                              <label>Chat Allocate</label>
                          	<select value={chatAllocate} onChange={handleInputChange} name="chatAllocate" className="form-select form-control-sm mb-3">
											     		<option disabled>Chat Allocate</option>
											     		<option value="yes">Yes</option>
											     		<option value="no">No</option>
											     	</select>
                          </div>
                          <div className="col-md-12">
                              <label>Chat Stages</label>
                          	<select name="chatStages" className="form-select form-control-sm mb-3">
											     		<option disabled>Chat Stages</option>
											     		<option value="student">student</option>
											     		<option value="staff">Staff</option>
											     		<option value="user">User</option>
											     	</select>
                          </div>
                          <div className="col-md-12">
                              <label>Status</label>
                          	<select value={status} onChange={handleInputChange} name="status" className="form-select form-control-sm mb-3">
											     		<option>Select Status</option>
											     		<option value="active">Active</option>
											     		<option value="inactive">In-active</option>
											     	</select>
                          </div>         

                          <div className="col-md-12 mt-1">
                              <div className="form-group text-end">
                                  <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
            											<button type="button" className="btn btn btn-light-dark mx-3" onClick={hanldePostClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                              </div>
                          </div>
                      </div>
									    </TabPanel>
									    <TabPanel>
									      <div className="row mt-4">
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>First Name</label>
                                  <input type="text" className="form-control mb-3" id="fullName" placeholder="First Name" name="name" value={name} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Last Name</label>
                                  <input type="text" className="form-control mb-3" id="lastName" placeholder="Last Name" name="lname" value={lname} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Email</label>
                                  <input type="email" className="form-control mb-3" id="email" name="email" placeholder="Email Address*" value={email} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group mb-3">
                                  <label>Password</label>
                                  <PasswordInput placeholder="Password *" name="password" value={password} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Phone Number</label>
                                  <input type="text" className="form-control mb-3" name="phone" id="phone" placeholder="Phone Number" value={phone} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <label>Profile</label>
                          	<select value={profile} onChange={handleInputChange} name="profile" className="form-select form-control-sm mb-3">
											     		<option>Select Profile</option>
											     		{
											     			userProfiles && userProfiles.map((item) => {
											     				return(
											     						<option key={item._id} value={item.name}>{item.name}</option>
											     					)
											     			})
											     		}
											     		
											     	</select>
                          </div>
                          <div className="col-md-12">
                              <label>Role</label>
                          	<select value={role} onChange={handleInputChange} name="role" className="form-select form-control-sm mb-3">
											     		<option>Select Role</option>
											     		{
											     			userRoles && userRoles.map((item) => {
											     				return(
											     						<option key={item._id} value={item.name}>{item.name}</option>
											     					)
											     			})
											     		}
											     	</select>
                          </div>
                          <div className="col-md-12">
                              <label>Chat Allocate</label>
                          	<select value={chatAllocate} onChange={handleInputChange} name="chatAllocate" className="form-select form-control-sm mb-3">
											     		<option>Chat Allocate</option>
											     		<option value="yes">Yes</option>
											     		<option value="no">No</option>
											     	</select>
                          </div>
                          <div className="col-md-12">
                              Status
                          	<select value={status} onChange={handleInputChange} name="status" className="form-select form-control-sm mb-3">
											     		<option>Select Status</option>
											     		<option value="active">Active</option>
											     		<option value="inactive">In-active</option>
											     	</select>
                          </div>         
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Date of Birth</label>
                                  <input type="datetime" className="form-control mb-3" name="dateOfBirth" id="dateOfBirth" placeholder="Date of Birth" value={dateOfBirth} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Fax No.</label>
                                  <input type="text" className="form-control mb-3" name="fax" id="fax" placeholder="Fax" value={fax} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Website</label>
                                  <input type="text" className="form-control mb-3" name="website" id="website" placeholder="Website" value={website} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Mobile Number</label>
                                  <input type="text" className="form-control mb-3" name="mobile" id="mobile" placeholder="Mobile" value={mobile} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <label>Street</label>
                              <div className="form-group">
                                  <input type="text" className="form-control mb-3" name="street" id="street" placeholder="Street" value={street} onChange={handleInputChange}/>
                              </div>
                          </div>                              
                          <div className="col-md-12">
                              <label>Zip Code</label>
                              <div className="form-group">
                                  <input type="text" className="form-control mb-3" name="zipCode" id="zipCode" placeholder="Zip Code" value={zipCode} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <label>State/Province</label>
                              <div className="form-group">
                                  <input type="text" className="form-control mb-3" name="state" id="state" placeholder="State" value={state} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <label>City</label>
                              <div className="form-group">
                                  <input type="text" className="form-control mb-3" name="city" id="city" placeholder="city" value={city} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <label>Country</label>
                              <div className="form-group">
                                  <input type="text" className="form-control mb-3" name="country" id="country" placeholder="Country" value={country} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12 mt-1">
                              <div className="form-group text-end">
                                  <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
            											<button type="button" className="btn btn btn-light-dark mx-3" onClick={hanldePostClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                              </div>
                          </div>
                      </div>
									    </TabPanel>
									  </Tabs>
                                
                    </form>
			            
			</Modal>
		</>
	);
}

export default Users
