import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import {FaBars} from 'react-icons/fa';
import { SuperAdminLink } from "./hiddenLinks";
import {AiOutlineHome,AiOutlineEllipsis,AiOutlineArrowLeft,AiOutlineArrowRight,AiOutlineUser} from 'react-icons/ai';

const KbmOneSidebar = () => {
	const { collapseSidebar } = useProSidebar();
	const [icon, setIcon] = useState(false);
	const toggleIcon = () =>{
		;
	}
	return (
		<>
		<div style={{ display: "flex", height: "100vh", position: 'sticky', top: 0, marginTop: '50px'}}>
		<Sidebar className="">
			<div className="toggleBtn" onClick={() => {collapseSidebar(setIcon(!icon));}}>
			{icon ? (<AiOutlineArrowRight size={20}/>) : (<AiOutlineArrowLeft size={20}/>)}
				
			</div>
	        <Menu>
	            <MenuItem icon={<AiOutlineHome size={18}/>} component={<Link to="/" className="link" />}> Home </MenuItem>
	            <MenuItem icon={<AiOutlineUser size={18}/>} component={<Link to="/user" className="link" />}> Users </MenuItem>
	            <SuperAdminLink>
	            <MenuItem icon={<AiOutlineEllipsis size={18}/>} component={<Link to="/more-apps" className="link" />}> More Apps </MenuItem>
	          	</SuperAdminLink>
	        </Menu>
	      </Sidebar>
			
		</div>
		</>
	)
}

export default KbmOneSidebar