import React,{useEffect, useLayoutEffect, useState} from 'react';
import { Link } from "react-router-dom";
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import Notification from "../components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getUser,
  selectUser,
  updateUser,
} from "../../redux/crm-features/auth/authSlice";
import {
  	getProfiles
} 	from "../../redux/crm-features/userProfile/profileSlice";
import {
  	getRoles
} 	from "../../redux/crm-features/userRole/roleSlice";

const cloud_name = process.env.REACT_APP_CLOUD_NAME;
const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;

const PersonalSetting = () => {

	useRedirectLoggedOutUser("/crm/login");

	const { isLoading, isLoggedIn, isSuccess, message, user } = useSelector(
	    (state) => state.auth
	);

	const { userProfiles } = useSelector(
	    (state) => state.userProfile
	);

	const { userRoles } = useSelector(
	    (state) => state.userRole
	);

	const initialState = {
	    name: user?.name || "",
	    lname: user?.lname || "",
	    email: user?.email || "",
	    photo: user?.photo || "",
	    role: user?.role || "",
	    profile: user?.profile || "",
	    phone: user?.phone || "",
	    fax: user?.fax || "",
	    website: user?.website || "",
	    mobile: user?.mobile || "",
	    dateOfBirth: user?.dateOfBirth || "",
	    state: user?.state || "",
	    street: user?.street || "",
	    zipCode: user?.zipCode || "",
	    city: user?.city || "",
	    country: user?.country || "",
	    isVerified: user?.isVerified || false,
	};

	const [profile, setProfile] = useState(initialState);
	const [imagePreview, setImagePreview] = useState(null);
	const [profileImage, setProfileImage] = useState(null);
	const [show,setShow]=useState(true);
	const dispatch = useDispatch();

	var buttonText = show ? "Hide Details" : "Show Details";

	useEffect(() => {
	    dispatch(getUser());
	    dispatch(getProfiles());
	    dispatch(getRoles());
	}, [dispatch]);
	
	const handleImageChange = (e) => {
		setProfileImage(e.target.files[0]);
    	setImagePreview(URL.createObjectURL(e.target.files[0]));
	}

	const handleInputChange = (e) => {
	    const { name, value } = e.target;
	    setProfile({ ...profile, [name]: value });
	};

	const saveProfile = async (e) =>{
		e.preventDefault();
		let imageURL;
		try{
			if (profileImage !== null &&(profileImage.type === "image/jpeg" || profileImage.type === "image/jpg" ||
				 profileImage.type === "image/png"))
			{

				const image = new FormData();
		        image.append("file", profileImage);
		        image.append("cloud_name", cloud_name);
		        image.append("upload_preset", upload_preset);

		        // Save image to Cloudinary
		        const response = await fetch(
		          "https://api.cloudinary.com/v1_1/kbm/image/upload",
		          { method: "post", body: image }
		        );
		        const imgData = await response.json();
		        // console.log(imgData);
		        imageURL = imgData.url.toString();

			}

			// Save profile to MongoDB
		    const userData = {
		        name: profile.name,
		        lname: profile.lname,
		        phone: profile.phone,
		        email: profile.email,
		        role: profile.role,
		        profile: profile.profile,
		        mobile: profile.mobile,
		        fax: profile.fax,
		        website: profile.website,
		        dateOfBirth: profile.dateOfBirth,
		        state: profile.state,
		        street: profile.street,
		        city: profile.city,
		        zipCode: profile.zipCode,
		        country: profile.country,
		        website: profile.website,
		        photo: profileImage ? imageURL : profile.photo,
		    };

			dispatch(updateUser(userData));
		}catch(error){
			toast.error(error.message);
		}
	}

	useLayoutEffect(() => {
	    if (user) {
	      setProfile({
	        ...profile,
	        name: user.name,
	        lname: user.lname,
	        email: user.email,
	        phone: user.phone,
	        photo: user.photo,
	        profile: user.profile,
	        role: user.role,
	        mobile: user.mobile,
	        fax: user.fax,
	        dateOfBirth: user.dateOfBirth,
	        website: user.website,
	        isVerified: user.isVerified,
	        city: user.city,
	        zipCode: user.zipCode,
	        street: user.street,
	        country: user.country,
	        state: user.state,
	      });
	    }
	}, [user]);

	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">
		            {!profile.isVerified && <Notification />}
		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">

		                    	<div className="personal_setting_sec">
		                    		<div className="user-info">
	                                    <div className="profile_image_upload">
	                                    	<img src={imagePreview === null ? user?.photo : imagePreview} alt="avatar"/>
	                                    	<input type="file" accept="image/*" name="image" onChange={handleImageChange}/>
	                                    </div>
	                                    <div className="profile_content">
	                                    	<h5>{profile?.name}<sup className="btn-secondary">{profile?.role}</sup></h5>
	                                    	<span>{profile?.profile}</span>
	                                    	<span>{profile?.email}</span>
	                                    </div>
	                                </div>
	                                <span onClick={()=>setShow(!show)} className="openToggle-div">{buttonText}</span>
	                                <form onSubmit={saveProfile}>
									{
									show?

	                                <table className="toggle-div">
	                                	<thead>
	                                		<tr>
	                                			<th>User Information</th>
	                                			<th></th>
	                                		</tr>
	                                	</thead>
	                                	<tbody>
	                                		<tr>
												<td>First Name</td>
												<td><input name="name" type="text" value={profile?.name} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Last Name</td>
												<td><input name="lname" type="text" value={profile?.lname} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Email</td>
												<td><input name="email" type="email" value={profile?.email} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Role</td>
												<td>
													<select value={profile?.role} onChange={handleInputChange} name="role" className="form-select form-control-sm mb-3">
										     		<option>Select Role</option>
										     		{
										     			userRoles && userRoles.map((item) => {
										     				return(
										     						<option key={item._id} value={item.name}>{item.name}</option>
										     					)
										     			})
										     		}
										     	</select>
												</td>

												{/*<td><input name="role" type="text" value={profile?.role} onChange={handleInputChange}/></td>*/}
											</tr>
											<tr>
												<td>Profile</td>
												<td>
													<select value={profile?.profile} onChange={handleInputChange} name="profile" className="form-select form-control-sm mb-3">
										     		<option>Select Profile</option>
										     		{
										     			userProfiles && userProfiles.map((item) => {
										     				return(
										     						<option key={item._id} value={item.name}>{item.name}</option>
										     					)
										     			})
										     		}
										     	</select>
												</td>
												{/*<td><input name="profile" type="text" value={profile?.profile} onChange={handleInputChange}/></td>*/}
											</tr>
											<tr>
												<td>Added By</td>
												<td>Aamna Hassan</td>
											</tr>
											<tr>
												<td>Alias</td>
												<td></td>
											</tr>
											<tr>
												<td>Phone</td>
												<td><input name="phone" type="text" value={profile?.phone} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Mobile</td>
												<td><input name="mobile" type="text" value={profile?.mobile} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Fax</td>
												<td><input name="fax" type="text" value={profile?.fax} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Website</td>
												<td><input name="website" type="text" value={profile?.website} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Date of Birth</td>
												<td><input name="dateOfBirth" type="text" value={profile?.dateOfBirth} onChange={handleInputChange}/></td>
											</tr>
	                                	</tbody>
	                                </table> 

	                                :null
									}
									<table>
										<thead>
											<tr>
												<th>Address Information</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Street</td>
												<td><input name="street" type="text" value={profile?.street} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>City</td>
												<td><input name="city" type="text" value={profile?.city} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>State</td>
												<td><input name="state" type="text" value={profile?.state} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Zip Code</td>
												<td><input name="zipCode" type="text" value={profile?.zipCode} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Country</td>
												<td><input name="country" type="text" value={profile?.country} onChange={handleInputChange}/></td>
											</tr>
										</tbody>
									</table>
									<table>
										<thead>
											<tr>
												<th>Social Profiles</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Twitter</td>
												<td><Link to="/crm">add</Link></td>
											</tr>
										</tbody>
									</table>
									<table>
										<thead>
											<tr>
												<th>Locale Information</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Language</td>
												<td>English (United States)</td>
											</tr>
											<tr>
												<td>Country Locale</td>
												<td>United States</td>
											</tr>
											<tr>
												<td>Date Format</td>
												<td>MMM D, YYYY</td>
											</tr>
											<tr>
												<td>Time Format</td>
												<td>12 Hours</td>
											</tr>
											<tr>
												<td>Time Zone</td>
												<td>(GMT 5:0) Pakistan Time (Asia/Karachi)</td>
											</tr>
											<tr>
												<td>Currency</td>
												<td>United States</td>
											</tr>
											<tr>
												<td>Number Format</td>
												<td>123,456.789</td>
											</tr>
										</tbody>
									</table>

									<div className="col-md-12 mt-1">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Update Prersonal Settings</button>
                                        </div>
                                    </div>

									</form>
		                    	</div>
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}
		</>
	);
}

export default PersonalSetting