import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/apps/one/`;


// add user app
const addApp = async(item) =>{
	const response = await axios.post(API_URL + "addApp", item);
	return response.data;
};

// Get all apps
const getApps = async () => {
  const response = await axios.get(API_URL + "getApps");
  return response.data;
};

// get user app
const getApp = async(id) =>{
	const response = await axios.get(API_URL + id);
	return response.data;
};

// update user app
const updateApp = async(id, item) =>{
	const response = await axios.patch(`${API_URL}${id}`, item);
	return response.data;
};

// delete user profile
const deleteApp = async(id) =>{
	const response = await axios.delete(API_URL + id);
	return response.data.message;
};


const appService = {
	addApp,
	getApps,
	getApp,
	updateApp,
	deleteApp,
}

export default appService;