import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import moduleService from './moduleService';

const initialState = {
	targetModule: {form:[]},
	moduleItem: null,
	moduleItems: [],
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: "",
};

// delete form
export const deleteForm = createAsyncThunk (
	"moduleItem/deleteForm",
	async (id, thunkAPI) => {
		try{
			return await moduleService.deleteForm(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//find findFormDataCount
export const findFormDataCount = createAsyncThunk (
	"moduleItem/findFormDataCount",
	async (_, thunkAPI) => {
		try{
			return await moduleService.findFormDataCount();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// add form data
export const addFormData = createAsyncThunk (
	"moduleItem/addFormData",
	async (moduleData, thunkAPI) => {
		try{
			return await moduleService.addFormData(moduleData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// find form data
export const findFormData = createAsyncThunk (
	"moduleItem/findFormData",
	async (moduleData, thunkAPI) => {
		try{
			return await moduleService.findFormData(moduleData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// update form data
export const updateFormData = createAsyncThunk (
	"moduleItem/updateFormData",
	async ({id, moduleData}, thunkAPI) => {
		try{
			return await moduleService.updateFormData(id, moduleData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// delete form data
export const deleteFormData = createAsyncThunk (
	"moduleItem/deleteFormData",
	async (id, thunkAPI) => {
		try{
			return await moduleService.deleteFormData(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get form data
export const getFormData = createAsyncThunk (
	"moduleItem/getFormData",
	async (id, thunkAPI) => {
		try{
			return await moduleService.getFormData(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// save image
export const savePix = createAsyncThunk (
	"moduleItem/savePix",
	async (moduleData, thunkAPI) => {
		try{
			return await moduleService.savePix(moduleData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// save file
export const saveFile = createAsyncThunk (
	"moduleItem/saveFile",
	async (moduleData, thunkAPI) => {
		try{
			return await moduleService.saveFile(moduleData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// rename file
export const renameFile = createAsyncThunk (
	"moduleItem/renameFile",
	async (moduleData, thunkAPI) => {
		try{
			return await moduleService.renameFile(moduleData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// delete file
export const deleteFile = createAsyncThunk (
	"moduleItem/deleteFile",
	async (moduleData, thunkAPI) => {
		try{
			return await moduleService.deleteFile(moduleData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)



// target module
export const targetModule = createAsyncThunk (
	"moduleItem/targetModule",
	(moduleData) => {
		return moduleService.targetModule(moduleData);
	}
)

// add module
export const addModule = createAsyncThunk (
	"moduleItem/addModule",
	async (moduleData, thunkAPI) => {
		try{
			return await moduleService.addModule(moduleData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//get all modules
export const getModules = createAsyncThunk (
	"moduleItem/getModules",
	async (_, thunkAPI) => {
		try{
			return await moduleService.getModules();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get module
export const getModule = createAsyncThunk (
	"moduleItem/getModule",
	async (id, thunkAPI) => {
		try{
			return await moduleService.getModule(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// update module
export const updateModule = createAsyncThunk (
	"moduleItem/updateModule",
	async (moduleData, thunkAPI) => {
		try{
			return await moduleService.updateModule(moduleData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//delete module
export const deleteModule = createAsyncThunk (
	"moduleItem/deleteModule",
	async (id, thunkAPI) => {
		try{
			return await moduleService.deleteModule(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

const moduleSlice = createSlice ({
	name: 'moduleItem',
	initialState,
	reducers: {
		
	},
	extraReducers: (builder) =>{
		builder
			
			//delete form
			.addCase(deleteForm.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(deleteForm.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(deleteForm.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})
			
			//find formDataCount
			.addCase(findFormDataCount.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(findFormDataCount.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.moduleItems = action.payload;
			})
			.addCase(findFormDataCount.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})
			
			//add form data
			.addCase(addFormData.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(addFormData.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.moduleItems = action.payload;
				toast.success('Form Data Add Successful');
			})
			.addCase(addFormData.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})
			
			//find form Data
			.addCase(findFormData.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(findFormData.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.moduleItems = action.payload;
			})
			.addCase(findFormData.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})
			
			//update form data
			.addCase(updateFormData.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(updateFormData.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.moduleItem = action.payload;
				toast.success("FormData is Updated");
			})
			.addCase(updateFormData.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//delete form data
			.addCase(deleteFormData.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(deleteFormData.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(deleteFormData.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})
			
			//save image
			.addCase(savePix.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(savePix.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.moduleItems = action.payload;
				toast.success('Image saved Successfully');
			})
			.addCase(savePix.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})
			
			//save file
			.addCase(saveFile.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(saveFile.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.moduleItems = action.payload;
				toast.success('File saved Successfully');
			})
			.addCase(saveFile.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})
			
			//rename file
			.addCase(renameFile.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(renameFile.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.moduleItems = action.payload;
				toast.success('File renamed Successfully');
			})
			.addCase(renameFile.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})
			
			//delete file
			.addCase(deleteFile.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(deleteFile.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.moduleItems = action.payload;
				toast.success('File renamed Successfully');
			})
			.addCase(deleteFile.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})



			//target module
			.addCase(targetModule.fulfilled, (state, action)=>{
				state.targetModule = action.payload;
			})

			//add module
			.addCase(addModule.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(addModule.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.moduleItems = action.payload;
				toast.success('Module Add Successful');
			})
			.addCase(addModule.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get module
			.addCase(getModule.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getModule.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.moduleItem = action.payload;
			})
			.addCase(getModule.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//update module
			.addCase(updateModule.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(updateModule.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.moduleItem = action.payload;
				toast.success("Module is Updated");
			})
			.addCase(updateModule.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get all modules
			.addCase(getModules.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getModules.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.moduleItems = action.payload;
			})
			.addCase(getModules.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//delete module
			.addCase(deleteModule.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(deleteModule.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(deleteModule.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

	}
});

export const selectModuleItem = (state) => state.moduleItem.moduleItem;

export default moduleSlice.reducer;