import React,{useMemo} from 'react';
import { Link } from "react-router-dom";
import ModuleSideBar from './ModuleSideBar';
import Footer from '../components/Footer';
import { AiOutlineSearch,AiOutlineArrowLeft } from "react-icons/ai";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";

const ReportModule = () => {

	useRedirectLoggedOutUser("/crm/login");


	return (
		<>
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container reports_sec" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		      	{/*<!--  BEGIN SIDEBAR  -->*/}
	        {/*<div className="sidebar-wrapper sidebar-theme">

	            <nav id="sidebar">
	            
	                <div className="navbar-nav theme-brand flex-row  text-center">
	                    <div className="nav-logo">
	                        
	                        <div className="nav-item theme-text">
	                            <Link to="" className="nav-link"> KBM </Link>
	                        </div>
	                    </div>
	                    <div className="nav-item sidebar-toggle">
	                        <div className="btn-toggle sidebarCollapse">
	                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevrons-left"><polyline points="11 17 6 12 11 7"></polyline><polyline points="18 17 13 12 18 7"></polyline></svg>
	                        </div>
	                    </div>
	                </div>
	                <div className="shadow-bottom"></div>
	                
	                <ul className="list-unstyled menu-categories" id="accordionExample">
	                <li className="mt-4">
	                	<h5>Folders </h5>
	                </li>
	                <li>
	                	<form className="custom-search mb-3">
							<div className="position-relative">
								<input type="text" placeholder="Search" className="form-control" />
								<button><AiOutlineSearch size={18}/></button>
							</div>
						</form>
	                </li>
	                <li className="menu active">
	                    <Link to="" className="dropdown-toggle mb-2">
                            <div className="">
                                <span title="All Reports">All Reports</span>
                            </div>
                        </Link>
                        
                    </li>
	                <li className="menu active">
	                    <Link to="" className="dropdown-toggle mb-2">
                            <div className="">
                                <span title="My Reports">My Reports</span>
                            </div>
                        </Link>
                        
                    </li>
	                </ul>
	                
	            </nav>

	        </div>*/}
	       {/* <!--  END SIDEBAR  -->*/}

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content reportDetail">
		            <div className="layout-px-spacing">
					
		                <div className="middle-content container-xxl p-0">

		                    
		                    <div className="row layout-top-spacing">
		                    
		                    <div className="viewModule_rightSide">
		                    	<div className="d-flex justify-content-between align-items-baseline flex-wrap">
														<div className="col-md-4 col-12">
															<h4><Link to="/crm/reports"> Untitled Report</Link></h4>
														</div>
														<div className="col-md-4 col-12 text-end">
															<Link to="/crm/reports" className="btn btn-secondary mx-1">Cancel</Link>
															<button className="btn btn-secondary mx-1">Continue</button>
														</div>
													</div>
									
													<div className="relatedModule">
														<h6>Select Related Modules</h6>
														<div className="inputModule">
															<input className="form-control" type="text" value="Lead" disabled />
														</div>
													</div>
		                    	
		                    </div>
		                     		
		                     	
		                    	
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}
		</>
	)
}

export default ReportModule