import React,{useState} from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import PasswordInput from '../components/PasswordInput';
import { Link,useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import {
  changePassword,
  logout,
  RESET,
} from "../../redux/crm-features/auth/authSlice";
import { sendAutomatedEmail } from "../../redux/crm-features/email/emailSlice";

const initialState ={
	oldPassword: '',
	password: '',
	password2: '',
}

const ChangePassword = () => {
	useRedirectLoggedOutUser("/crm/login");

	const [formData, setFormData] = useState(initialState);

	const {oldPassword, password, password2} = formData;

	const { isLoading, user } = useSelector((state) => state.auth);

	const dispatch = useDispatch();
  	const navigate = useNavigate();

	const handleInputChange = (e) => {
	    const { name, value } = e.target;
	    setFormData({ ...formData, [name]: value });
	};

	const updatePassword = async (e) => {
	    e.preventDefault();

	    if (!oldPassword || !password || !password2) {
	      return toast.error("All fields are required");
	    }

	    if (password !== password2) {
	      return toast.error("Passwords do not match");
	    }

	    const userData = {
	      oldPassword,
	      password,
	    };

	    const emailData = {
	      subject: "Password Changed - KBM",
	      send_to: user.email,
	      reply_to: "noreply@kbm",
	      template: "changePassword",
	      url: "/crm/forgot",
	    };

	    await dispatch(changePassword(userData));
	    await dispatch(sendAutomatedEmail(emailData));
	    await dispatch(logout());
	    await dispatch(RESET(userData));
	    navigate("/crm/login");
	};

	return (
		<div>

		{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">

		                    {/*<!-- Content -->*/}
		                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
		                            <div className="user-profile">
		                                <div className="widget-content widget-content-area">
		                                    <div className="d-flex justify-content-between mb-4">
		                                        <h3 className="">Change Password</h3>
		                                    </div>
		                                    <form onSubmit={updatePassword}>
			                                <div className="col-md-8 col-12">
			                                    <div className="mb-4">
			                                        <PasswordInput placeholder="Current Password *" name="oldPassword" value={oldPassword} onChange={handleInputChange} />
			                                    </div>
			                                </div>
			                                <div className="col-md-8 col-12">
			                                    <div className="mb-4">
			                                        <PasswordInput placeholder="New Password *" name="password" value={password} onChange={handleInputChange} />
			                                    </div>
			                                </div>
			                                <div className="col-md-8 col-12">
			                                    <div className="mb-4">
			                                        <PasswordInput placeholder="Confirm New Password *" name="password2" value={password2} onChange={handleInputChange} />
			                                    </div>
			                                </div>
			                                
			                                <div className="col-12">
			                                    <div className="mb-4">
			                                        <button type="submit" className="btn btn-secondary">Change</button>
			                                    </div>
			                                </div>
			                                </form>
		                                </div>
		                            </div>
		                        </div>

		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}
			
		</div>
	)
}

export default ChangePassword