import React from 'react';
import { Link } from 'react-router-dom';
import KbmOneHeader from '../components/KbmOneHeader';

const KbmOneLayout = ({children}) => {
	return (
		<>

            <KbmOneHeader />
            {children}
			
		</>
	)
}

export default KbmOneLayout