import React,{useMemo} from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import MaterialReactTable from 'material-react-table';

const customPage = [
	{
		name: "Manager's Home",

		shareWith: 'CEO',

		desc: '',

		lastModified: '',
		
		status: '',
	},
];

const CustomizationPage = () => {

	useRedirectLoggedOutUser("/crm/login");

	const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'Name',
        Header: () => <span>Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          <span className=""><Link to={`/crm/customization-page`}>{renderedCellValue}</Link></span>
        ),
      },
      {
        accessorFn: (row) => row.shareWith, //alternate way
        id: 'shareWith', //id required if you use accessorFn instead of accessorKey
        header: 'Shared With',
        Header: () => <span>Shared With</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.desc, //alternate way
        id: 'desc', //id required if you use accessorFn instead of accessorKey
        header: 'Description',
        Header: () => <span>Description</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.lastModified, //alternate way
        id: 'lastModified', //id required if you use accessorFn instead of accessorKey
        header: 'Last Modified',
        Header: () => <span>Last Modified</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.status, //alternate way
        id: 'status', //id required if you use accessorFn instead of accessorKey
        header: 'Status',
        Header: () => <span>Status</span>, //optional custom header render
      },
    ],
    [],
  );

	return (
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">
		                        
		                     	<div className="d-flex justify-content-between align-items-baseline">
									<div className="col-md-8 col-12">
										<h5>Customize Home page</h5>
										<p>You can create custom homepage layouts for each role, making it easier for employees to complete their daily task efficiently.</p>
									</div>
									<Link to="/crm">Help</Link>
								</div>
								<div className="col-12 mt-3 text-end">
									<button type="button" className="btn btn-secondary">Add New Home Page</button>
								</div>

								<div className="statbox widget box box-shadow">
	                              <div className="widget-content widget-content-area">
	                              
	                              	<MaterialReactTable columns={columns} data={customPage} enableRowSelection={true}/>
	                              
	                              </div>
	                          	</div>
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		</>
	)
}

export default CustomizationPage