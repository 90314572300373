import React from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import {FaBars} from 'react-icons/fa';

const Sidebar2 = () => {
	const { collapseSidebar } = useProSidebar();
	return (
		<>
		<div style={{ display: "flex", height: "100vh", position: 'sticky', top: 0 }}>
		<Sidebar className="custom_sidebar">
	        <Menu>
	          <SubMenu  label="General" icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>}>
	          <MenuItem component={<Link to="/crm" className="link" />}> Dashboard </MenuItem>
	          <MenuItem component={<Link to="/crm/personal-setting" className="link" />}> Personal Settings </MenuItem>
	          <MenuItem component={<Link to="/crm/company-detail" className="link" />}> Company Detail </MenuItem>
	          </SubMenu>
	          <SubMenu label="User and Control" icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-cpu"><rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect><rect x="9" y="9" width="6" height="6"></rect><line x1="9" y1="1" x2="9" y2="4"></line><line x1="15" y1="1" x2="15" y2="4"></line><line x1="9" y1="20" x2="9" y2="23"></line><line x1="15" y1="20" x2="15" y2="23"></line><line x1="20" y1="9" x2="23" y2="9"></line><line x1="20" y1="14" x2="23" y2="14"></line><line x1="1" y1="9" x2="4" y2="9"></line><line x1="1" y1="14" x2="4" y2="14"></line></svg>}>
	            <MenuItem component={<Link to="/crm/user" className="link" />}> Users </MenuItem>
	            <MenuItem component={<Link to="/crm/security-control" className="link" />}> Security Control </MenuItem>
	            <MenuItem component={<Link to="/crm/compliance-setting" className="link" />}> Compliance Settings </MenuItem>
	          </SubMenu>
	          <SubMenu label="Channels" icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-airplay"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path><polygon points="12 15 17 21 7 21 12 15"></polygon></svg>}>
	            <MenuItem component={<Link to="/crm/email-settings" className="link" />}> Email </MenuItem>
	            <MenuItem component={<Link to="/crm/visit" className="link" />}> Chat </MenuItem>
	          </SubMenu>
	          {/*<MenuItem> Transactions </MenuItem>*/}
	          <SubMenu label="Customization" icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>}>
	            <MenuItem component={<Link to="/crm/modules" className="link" />}> Modules and Fields </MenuItem>
	            <MenuItem component={<Link to="/crm" className="link" />}> Templates </MenuItem>
	            <MenuItem component={<Link to="/crm/home-customization" className="link" />}> Customize Home Page </MenuItem>
	          </SubMenu>
	          <SubMenu label="Automation" icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>}>
	            <MenuItem component={<Link to="/crm" className="link" />}> Workflow Rules </MenuItem>
	            <MenuItem component={<Link to="/crm" className="link" />}> Action </MenuItem>
	          </SubMenu>
	          <SubMenu label="Data Administration" icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>}>
	            <MenuItem component={<Link to="/crm" className="link" />}> Import </MenuItem>
	            <MenuItem component={<Link to="/crm" className="link" />}> Export </MenuItem>
	            <MenuItem component={<Link to="/crm" className="link" />}> Data Backup </MenuItem>
	            <MenuItem component={<Link to="/crm" className="link" />}> Remove Sample Data </MenuItem>
	            <MenuItem component={<Link to="/crm" className="link" />}> Storage </MenuItem>
	            <MenuItem component={<Link to="/crm" className="link" />}> Recycle Bin </MenuItem>
	          </SubMenu>
	          <SubMenu label="Marketplace" icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-terminal"><polyline points="4 17 10 11 4 5"></polyline><line x1="12" y1="19" x2="20" y2="19"></line></svg>}>
	            <MenuItem component={<Link to="/crm" className="link" />}> Zoho </MenuItem>
	            <MenuItem component={<Link to="/crm" className="link" />}> Microsoft </MenuItem>
	          </SubMenu>
	        </Menu>
	      </Sidebar>
			
		</div>
		</>
	)
}

export default Sidebar2