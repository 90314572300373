import React,{useEffect} from 'react';
import InfoBox from './InfoBox';
import {FaUsers} from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";
import {BiUserCheck,BiUserMinus,BiUserX} from 'react-icons/bi';
import { CALC_SUSPENDED_USER, CALC_VERIFIED_USER } from "../../redux/crm-features/auth/authSlice";

const icon1 = <FaUsers size={20} color='#fff' />
const icon2 = <BiUserCheck size={20} color='#fff' />
const icon3 = <BiUserMinus size={20} color='#fff' />
const icon4 = <BiUserX size={20} color='#fff' />

const UserStats = () => {

	const dispatch = useDispatch();
	const { users, verifiedUsers, suspendedUsers } = useSelector(
	    (state) => state.auth
	);

	const unverifiedUsers = users.length - verifiedUsers;

	useEffect(() => {
	    dispatch(CALC_VERIFIED_USER());
	    dispatch(CALC_SUSPENDED_USER());
	}, [dispatch, users]);

	return (
		<div className="userStats_summary">
			<InfoBox icon={icon1} title={'Total Companies'} count={users.length} bgColor='card1'/>
			<InfoBox icon={icon2} title={'Active Companies'} count={verifiedUsers} bgColor='card2'/>
			<InfoBox icon={icon3} title={'InActives Companies'} count={unverifiedUsers} bgColor='card3'/>
			<InfoBox icon={icon4} title={'Suspended Companies'} count={suspendedUsers} bgColor='card4'/>
		</div>
	)
}

export default UserStats