import React,{useEffect,useState,useLayoutEffect,useReducer} from 'react';
import ReactDOM from 'react-dom';
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { Link,useNavigate,useParams } from 'react-router-dom'; 
import { destPix, destFile, pixAddress, fileAddress } from '../../formBuilder/srcSet';
import { toast } from "react-toastify";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { useDispatch, useSelector } from "react-redux";
import { previewArrMap, setValue, seperator, setMultiSelect, getPattern, 
  	pixSave, fileDelete, fileSave, setImageUpload, setFileUpload, setType, shortName,
	connectSection, connectField, removeElm,
} from '../../formBuilder/FormHelper';

import {
	getLead,
	selectLead,
	getLeads,
	updateLead,
} from "../../redux/crm-features/lead/leadSlice";
import {
	findFormDataCount, addFormData, findFormData, updateFormData, deleteFormData, getFormData, 
  } from "../../redux/crm-features/moduleItem/moduleSlice";

const EditModuleRecord = () => {
	useRedirectLoggedOutUser("/crm/login");
	
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [modulePreview, setModulePreview] = useState([]);
	const [moduleArr, setModuleArr] = useState([]);
	const [error, setError] = useState([])
	const [fileUploadArr, setFileUploadArr] = useState([]);
	const [imageUploadArr, setImageUploadArr] = useState([]);


	const { leads, isLoading, isSuccess, message } = useSelector(
	    (state) => state.lead
	);

	const { targetModule } = useSelector(
		(state) => state.moduleItem
	);

	const initialState = {
		name: leads?.name || "",
		company: leads?.company || "",
		email: leads?.email || "",
		phone: leads?.phone || "",
		leadSource: leads?.leadSource || "",
		leadOwner: leads?.leadOwner || "",
	}

	// const productEdit = useSelector(selectProduct);

	useEffect(() => {
		var objectArr = JSON.parse(JSON.stringify(targetModule.form))
	    dispatch(getLead(id)).then(async res => {
			// console.log(res.payload.moduleData)
			await setFormData(objectArr, res.payload.moduleData)
			previewArrMap(objectArr, false, dispatch).then(res => {
				const { formPreview, error, multiSelectArr, imageUploadArr, fileUploadArr, objectArr } = res
				setTimeout(() => {
					setValue(objectArr)
					setMultiSelect(multiSelectArr)
					setImageUpload(imageUploadArr)
					setFileUpload(fileUploadArr)
				}, 100);
				setModulePreview(formPreview)
				setModuleArr(objectArr)
			})

		});
	}, [dispatch, id]);

	// const [formData, setFormData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);

	const setFormData = async (arr, moduleData) => {

		var pickListArr = []
		for(let f=0; f<moduleData.length; f++) {
		  if(moduleData[f].type==='pickList') pickListArr.push(moduleData[f])
		}
		// const arr = JSON.parse(JSON.stringify(objectArr))
		// this.setState({ objectArr: arr })
	
		for(let i=0; i<arr.length; i++) {
		  const arr2 = arr[i].components
		  for(let i2=0; i2<arr2.length; i2++) {
			const arr3 = arr2[i2].components
			for(let i3=0; i3<arr3.length; i3++) {
			  var field = arr3[i3]
			  field.value = getValue(moduleData, field.key)
			  if(['pickList', 'multiSelect'].includes(field.type)) {
				var options = field.options
				if(field.type==='pickList') {
				  for(let x=0; x<options.length; x++) {
					if(options[x].defaultValue) options[x].defaultValue = undefined
				  }
				  for(let p=0; p<pickListArr.length; p++) {
					const target = options[pickListArr[p].optionId]
					if(target && pickListArr[p].key===field.key) target.defaultValue = true
				  }
				}
			  }
			  if(['imageUpload', 'fileUpload'].includes(field.type)) {
				field.deletedItems = []
			  }
			}
		  }
		}
	}

	const getValue = (moduleData, key) => {
		for(let i=0; i<moduleData.length; i++) {
		  if(moduleData[i].key===key) return moduleData[i].value
		}
	}

	const onSaveFormData = async() => {
		var pattern = getPattern(moduleArr)
		var formData = pattern.formData

		await previewArrMap(moduleArr, false, dispatch).then(res => {
		  	const { formPreview, error, imageUploadArr, fileUploadArr, objectArr } = res
			setModulePreview(formPreview)
			setFileUploadArr(fileUploadArr)
			setImageUploadArr(imageUploadArr)
			setModuleArr(objectArr)

			for(let x=0; x<formData.length; x++) {
			  var field = formData[x]
			  if(['pickList', 'multiSelect', 'imageUpload'].includes(field.type)) {
				var options = field.options
				if(field.type==='pickList') {
				  for(let a=0; a<options.length; a++) {
					if(options[a].defaultValue) {
					  field.optionId = a
					  field.value = options[a].label
					}
				  }
				} else if(field.type==='multiSelect') {
				  var selectionArr = []
				  for(let a=0; a<options.length; a++) {
					var targetKey = options[a].key
					const option = document.getElementById(targetKey + '-selected')
					if(option) selectionArr.push(targetKey)
				  }
				  field.value = selectionArr
				  // field.value = selectionArr.join(', ');
				} else if(field.type==='imageUpload') {
				  // console.log('imageUploadXXXX')
				}
			  }
			}
		
			// Deep Copy
			const newFormData = JSON.parse(JSON.stringify(formData));
			for(let x=0; x<newFormData.length; x++) {
				var field = newFormData[x]
				if(['pickList', 'multiSelect', 'imageUpload'].includes(field.type)) {
					field.options = undefined
				}
			}
		
			const data = {
				idX: id,
				formId: targetModule._id,
				moduleData: newFormData
			}
	
			if(!error) {
				dispatch(updateLead({id, moduleData:data}))
				.then(async res => {
					if(imageUploadArr.length>0) {
						const dataId = res.payload._id
						for(let m=0; m<imageUploadArr.length; m++) {
							const { elm, objectArr, i, ic, icc } = imageUploadArr[m]
							const fileBArr = elm.options
							const value = elm.value
							const deletedItems = elm.deletedItems
							for(let ix=0; ix<fileBArr.length; ix++) {
								// console.log(fileBArr[ix].type)
								const type = fileBArr[ix].type
								if(type) {
									const name = value[ix].id // dataId + "-" + value[ix].id
									await pixSave(fileBArr[ix], `${1000}|${name}|${destPix.replaceAll("/", "@")}`, dispatch)
								}
							}
							for(let d=0; d<deletedItems.length; d++) {
								const name = deletedItems[d].id + '.jpeg' // dataId + "-" + deletedItems[d].id + '.jpeg'
								await fileDelete({dest: destPix + "/" + name}, dispatch)
							}
						}
					}
					if(fileUploadArr.length>0) {
						// console.log(fileUploadArr)
						const dataId = res.payload._id
						for(let m=0; m<fileUploadArr.length; m++) {
							const { elm, objectArr, i, ic, icc } = fileUploadArr[m]
							const fileBArr = elm.options
							const value = elm.value
							const deletedItems = elm.deletedItems
							for(let ix=0; ix<fileBArr.length; ix++) {
								const type = fileBArr[ix].type
								const nameX = fileBArr[ix].name
								// console.log(dataId)
								if(type) {
									const name = value[ix].id //dataId + "-" + value[ix].id
									await fileSave(fileBArr[ix], `${name}|${destFile.replaceAll("/", "@")}`, nameX, dataId, destFile, dispatch)
								}
							}
							for(let d=0; d<deletedItems.length; d++) {
								const name = `${deletedItems[d].id}.${deletedItems[d].type}` //`${dataId}-${deletedItems[d].name}.${deletedItems[d].type}`
								await fileDelete({dest: destFile + "/" + name}, dispatch)
							}
						}
					}
					field.deletedItems = undefined
				})
			}
		})
		navigate(`/crm/module-record/${targetModule._id}`);

	}
	

	return (
		<div>

		{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">

			                    {/*content*/}


			                    <div className="col-lg-12">
			                    	<div className="col-12">
			                    		<div className="d-flex align-items-center justify-content-between">
			                    			<h5 className="m-0">Edit Record</h5>
			                    			<Link to={`/crm/module-record/${targetModule._id}`} className="v-link btn btn-secondary _effect--ripple waves-effect waves-light">Back</Link>
			                    		</div>
			                    	</div>
			                    	<div className="col-12 mt-4">
			                    		{/*<UserStats />*/}
			                    	</div>
		                            <div className="statbox widget box box-shadow">
		                                <div className="widget-content widget-content-area">
											<div className="row mt-4">
												{modulePreview}
												<div className="col-md-12 mt-1">
													<div className="form-group text-end">
														<button className="btn btn-secondary _effect--ripple waves-effect waves-light" onClick={() => onSaveFormData()}>Save</button>
													</div>
												</div>
											</div>
		                                </div>
		                            </div>
	                        	</div>

			                    {/*content*/}

		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		</div>
	)
}

export default EditModuleRecord