import React,{useEffect,useState,useLayoutEffect,useReducer} from 'react';
import axios from "axios";
import ReactDOM from 'react-dom';
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import PasswordInput from '../components/PasswordInput';
import Footer from '../components/Footer';
import UserStats from '../components/UserStats';
import { Link,useNavigate,useParams } from 'react-router-dom'; 
import { toast } from "react-toastify";
import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
// import { deleteUser,getAllUsers } from "../redux/crm-features/auth/authSlice";
// import { shortenText } from "./Profile";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { validateEmail } from "../../redux/crm-features/auth/authService";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleUserId,
  // selectUser,
  getAllUsers,
  updateUserId,
  RESET,
  sendVerificationEmail,
} from "../../redux/crm-features/auth/authSlice";
import {
  	getProfiles
} 	from "../../redux/crm-features/userProfile/profileSlice";
import {
  	getRoles
} 	from "../../redux/crm-features/userRole/roleSlice";

// import imagePreview from "https://i.pinimg.com/736x/1f/51/03/1f51038bd5af319dd132a3fab9ee3b57.jpg";
const cloud_name = process.env.REACT_APP_CLOUD_NAME;
const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;

const EditCompany = () => {
	useRedirectLoggedOutUser("/crm/login");

	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [show,setShow]=useState(true);

	var buttonText = show ? "Hide Details" : "Show Details";

	const { userProfiles } = useSelector(
	    (state) => state.userProfile
	);

	const { userRoles } = useSelector(
	    (state) => state.userRole
	);

	const { users, isLoading, isSuccess, message } = useSelector(
	    (state) => state.auth
	);

	const initialState ={
		name: users?.name || "",
	    lname: users?.lname || "",
	    email: users?.email || "",
	    phone: users?.phone || "",
	    desc: users?.desc || "",
	    photo: users?.photo || "",
	    role: users?.role || "",
	    profile: users?.profile || "",
	    fax: users?.fax || "",
	    mobile: users?.mobile || "",
	    website: users?.website || "",
	    street: users?.street || "",
	    state: users?.state || "",
	    city: users?.city || "",
	    zipCode: users?.zipCode || "",
	    country: users?.country || "",
	    isVerified: users?.isVerified || false,
	}

	useEffect(() => {
	    dispatch(getSingleUserId(id));
	    dispatch(getProfiles());
	    dispatch(getRoles());
	}, [dispatch]);

	const [formInfoData, setFormInfoData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const [imagePreview, setImagePreview] = useState(null);
	const [profileImage, setProfileImage] = useState(null);
	// const {name,lname,email} = formData;

	const handleImageChange = (e) => {
		setProfileImage(e.target.files[0]);
    	setImagePreview(URL.createObjectURL(e.target.files[0]));
	}

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setFormInfoData({...formInfoData, [name]: value});
	}

	const userEdit = async (e) => {
		e.preventDefault();

		let imageURL;

		if (profileImage !== null &&(profileImage.type === "image/jpeg" || profileImage.type === "image/jpg" ||
				 profileImage.type === "image/png"))
			{

				const image = new FormData();
		        image.append("file", profileImage);
		        image.append("cloud_name", cloud_name);
		        image.append("upload_preset", upload_preset);

		        // Save image to Cloudinary
		        const response = await fetch(
		          "https://api.cloudinary.com/v1_1/kbm/image/upload",
		          { method: "post", body: image }
		        );
		        const imgData = await response.json();
		        // console.log(imgData);
		        imageURL = imgData.url.toString();

			}

		// if (!name || !email || !password || !phone || !desc || !role){
		// 	return toast.error('All fields are required')
		// }
		// if (password.length < 6) {
	 //      	return toast.error("Password must be up to 6 characters");
	 //    }
		// if (!validateEmail(email)){
		// 	return toast.error('Please enter a valid email')
		// }

		const userData = {
			name: formInfoData.name, 
			lname: formInfoData.lname,  
			email: formInfoData.email,  
      phone: formInfoData.phone,
      role: formInfoData.role,
      profile: formInfoData.profile,
      mobile: formInfoData.mobile,
      fax: formInfoData.fax,
      state: formInfoData.state,
      street: formInfoData.street,
      city: formInfoData.city,
      zipCode: formInfoData.zipCode,
      country: formInfoData.country,
      website: formInfoData.website,
      photo: profileImage ? imageURL : formInfoData.photo,
		};
		
		await dispatch(updateUserId({id, userData}));
		await dispatch(getAllUsers());
		forceUpdate();
		navigate('/crm/company-detail');
		// window.location.reload();
		// await dispatch(sendVerificationEmail());
	};

	

	useLayoutEffect(() => {
	    if (users) {
	      setFormInfoData({
	        ...formInfoData,
	        name: users.name,
	        lname: users.lname,
	        email: users.email,
	        phone: users.phone,
	        role: users.role,
	        profile: users.profile,
	        mobile: users.mobile,
	        fax: users.fax,
	        state: users.state,
	        street: users.street,
	        city: users.city,
	        zipCode: users.zipCode,
	        country: users.country,
	        website: users.website,
	        photo: users.photo,
	      });
	    }
	    
	}, [users]);

	return (
		<div>

		{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">

			                    {/*content*/}


			                    <div className="col-lg-12">
			                    	<div className="col-12">
			                    		<div className="d-flex align-items-center justify-content-between">
			                    			<h5 className="m-0">Edit Company</h5>
			                    			{/*<Link to="/user" className="v-link btn btn-secondary _effect--ripple waves-effect waves-light">Back</Link>*/}
			                    		</div>
			                    	</div>
			                    	
		                            {/*<div className="statbox widget box box-shadow">
		                                <div className="widget-content widget-content-area">
		                                	<form className="form" onSubmit={userEdit}>
				                                <div className="text-center user-info">
				                                    <div className="profile_image_upload">
				                                    	<label>User Picture</label>
				                                    	<img src={imagePreview === null ? user?.photo : imagePreview} alt="avatar"/>
				                                    	<input type="file" accept="image/*" name="image" onChange={handleImageChange}/>
				                                    </div>
				                                    <p className="text-capitalize mb-0">{formInfoData?.name}</p>
				                                    <span>{userinfo.role}</span>
				                                </div>                            
				                                <div className="row mt-4">
				                                    <div className="col-md-12">
				                                        <div className="form-group">
				                                            <input type="text" className="form-control mb-3" id="fullName" placeholder="First Name" name="name" value={formInfoData?.name} onChange={handleInputChange}/>
				                                        </div>
				                                    </div>
				                                    <div className="col-md-12">
				                                        <div className="form-group">
				                                            <input type="text" className="form-control mb-3" id="lastName" placeholder="Last Name" name="lname" value={formInfoData?.lname} onChange={handleInputChange}/>
				                                        </div>
				                                    </div>
				                                    <div className="col-md-12">
				                                        <div className="form-group">
				                                            <input type="email" className="form-control mb-3" id="email" name="email" placeholder="Email *" value={formInfoData?.email} onChange={handleInputChange}/>
				                                        </div>
				                                    </div>
				                                    
				                                    <div className="col-md-12 mt-1">
				                                        <div className="form-group text-end">
				                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
				                                        </div>
				                                    </div>
				                                </div>
				                            </form>
		                                </div>
		                            </div>*/}
	                        	</div>

	                        	<div className="personal_setting_sec">
		                    		<div className="user-info">
	                                    <div className="profile_image_upload">
	                                    	<img src={imagePreview === null ? formInfoData?.photo : imagePreview} alt="avatar"/>
	                                    	<input type="file" accept="image/*" name="image" onChange={handleImageChange}/>
	                                    </div>
	                                    <div className="profile_content">
	                                    	<h5>{formInfoData?.name}<sup className="btn-secondary">{formInfoData?.role}</sup></h5>
	                                    	<span>{formInfoData?.profile}</span>
	                                    	<span>{formInfoData?.email}</span>
	                                    </div>
	                                </div>
	                                <span onClick={()=>setShow(!show)} className="openToggle-div">{buttonText}</span>
	                                <form onSubmit={userEdit}>
									{
									show?

	                                <table className="toggle-div">
	                                	<thead>
	                                		<tr>
	                                			<th>Company Information</th>
	                                			<th></th>
	                                		</tr>
	                                	</thead>
	                                	<tbody>
	                                		<tr>
												<td>Company Name</td>
												<td><input name="name" type="text" value={formInfoData?.name} onChange={handleInputChange}/></td>
											</tr>
											
											<tr>
												<td>Company Email</td>
												<td><input name="email" type="email" value={formInfoData?.email} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Company Role</td>
												<td>
													<select value={formInfoData?.role} onChange={handleInputChange} name="role" className="form-select form-control-sm mb-3">
										     		<option>Select Role</option>
										     		{
										     			userRoles && userRoles.map((item) => {
										     				return(
										     						<option key={item._id} value={item.name}>{item.name}</option>
										     					)
										     			})
										     		}
										     	</select>
												</td>

												{/*<td><input name="role" type="text" value={profile?.role} onChange={handleInputChange}/></td>*/}
											</tr>
											
											{/*<tr>
												<td>Added By</td>
												<td>Aamna Hassan</td>
											</tr>*/}
											{/*<tr>
												<td>Alias</td>
												<td></td>
											</tr>*/}
											<tr>
												<td>Company Phone</td>
												<td><input name="phone" type="text" value={formInfoData?.phone} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Mobile</td>
												<td><input name="mobile" type="text" value={formInfoData?.mobile} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Fax</td>
												<td><input name="fax" type="text" value={formInfoData?.fax} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Website</td>
												<td><input name="website" type="text" value={formInfoData?.website} onChange={handleInputChange}/></td>
											</tr>
											
	                                	</tbody>
	                                </table> 

	                                :null
									}
									<table>
										<thead>
											<tr>
												<th>Address Information</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Street</td>
												<td><input name="street" type="text" value={formInfoData?.street} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>City</td>
												<td><input name="city" type="text" value={formInfoData?.city} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>State</td>
												<td><input name="state" type="text" value={formInfoData?.state} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Zip Code</td>
												<td><input name="zipCode" type="text" value={formInfoData?.zipCode} onChange={handleInputChange}/></td>
											</tr>
											<tr>
												<td>Country</td>
												<td><input name="country" type="text" value={formInfoData?.country} onChange={handleInputChange}/></td>
											</tr>
										</tbody>
									</table>
									<table>
										<thead>
											<tr>
												<th>Social Profiles</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Twitter</td>
												<td><Link to="/crm">add</Link></td>
											</tr>
										</tbody>
									</table>
									<table>
										<thead>
											<tr>
												<th>Locale Information</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Language</td>
												<td>English (United States)</td>
											</tr>
											<tr>
												<td>Country Locale</td>
												<td>United States</td>
											</tr>
											<tr>
												<td>Date Format</td>
												<td>MMM D, YYYY</td>
											</tr>
											<tr>
												<td>Time Format</td>
												<td>12 Hours</td>
											</tr>
											<tr>
												<td>Time Zone</td>
												<td>(GMT 5:0) Pakistan Time (Asia/Karachi)</td>
											</tr>
											<tr>
												<td>Currency</td>
												<td>United States</td>
											</tr>
											<tr>
												<td>Number Format</td>
												<td>123,456.789</td>
											</tr>
										</tbody>
									</table>

									<div className="col-md-12 mt-1">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Update</button>
                                        </div>
                                    </div>

									</form>
		                    	</div>


			                    {/*content*/}

		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		</div>
	)
}

export default EditCompany