import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/modules/crm/`;

// delete forms
const deleteForm = async(id) =>{
	const response = await axios.delete(API_URL + /form/ + id)
	return response.data.message;
};

// find formDataCount
const findFormDataCount = async() =>{
	const response = await axios.get(API_URL + "findFormDataCount")
	return response.data;
};

// add form data
const addFormData = async(moduleData) =>{
	const response = await axios.post(API_URL + "addFormData", moduleData);
	return response.data;
};

// find form data
const findFormData = async(moduleData) =>{
	const response = await axios.post(API_URL + "findFormData", moduleData);
	return response.data;
};

// update form data
const updateFormData = async(id, moduleData) =>{
	const response = await axios.patch(`${API_URL}/formData/${id}`, moduleData);
	return response.data;
};

// delete form data
const deleteFormData = async(id) =>{
	const response = await axios.delete(API_URL + /formData/ + id)
	return response.data.message;
};

// get form data
const getFormData = async(id) =>{
	const response = await axios.get(API_URL + /formData/ + id);
	return response.data;
};

// save image
const savePix = async(moduleData) =>{
	const response = await axios.post(API_URL + "savePix", moduleData);
	return response.data;
};

// save file
const saveFile = async(moduleData) =>{
	const response = await axios.post(API_URL + "saveFile", moduleData);
	return response.data;
};

// rename file
const renameFile = async(moduleData) =>{
	const response = await axios.post(API_URL + "renameFile", moduleData);
	return response.data;
};

// delete file
const deleteFile = async(moduleData) =>{
	const response = await axios.post(API_URL + "deleteFile", moduleData);
	return response.data;
};



// target module
const targetModule = (targetModule) => targetModule;

// add module
const addModule = async(moduleData) =>{
	const response = await axios.post(API_URL + "addModule", moduleData);
	return response.data;
};

// Get all modules
const getModules = async () => {
  const response = await axios.get(API_URL + "getModules");
  return response.data;
};

// get module
const getModule = async(id) =>{
	const response = await axios.get(API_URL + id);
	return response.data;
};

// update module
const updateModule = async(moduleData) =>{
	const response = await axios.patch(API_URL + "updateModule", moduleData);
	return response.data;
};

// delete module
const deleteModule = async(id) =>{
	const response = await axios.delete(API_URL + id);
	return response.data.message;
};

const moduleService = {
	deleteForm,
	findFormDataCount,
	addFormData,
	findFormData,
	updateFormData,
	deleteFormData,
	getFormData,
	savePix,
	saveFile,
	renameFile,
	deleteFile,

	targetModule,
	addModule,
	getModules,
	getModule,
	updateModule,
	deleteModule,
}

export default moduleService;