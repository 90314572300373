import React,{useEffect,useRef,useLayoutEffect,useMemo,useState,useReducer} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import KbmOneSidebar from '../components/KbmOneSidebar';
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import PasswordInput from '../components/PasswordInput';
import { toast } from "react-toastify";
import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { validateEmail } from "../../redux/kbmone-features/auth/authService";
import { useDispatch, useSelector } from "react-redux";
// import ChangeRole from "../components/ChangeRole";
import { SuperAdminLink } from "../components/hiddenLinks";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  addUser,
  RESET,
  getAllUsersKbmOne,
  deleteUser,
  sendVerificationEmail,
} from "../../redux/kbmone-features/auth/authSlice";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// const cloud_name = process.env.REACT_APP_CLOUD_NAME;
// const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;

const initialState = {
	    name : '',
			lname : '',
			email : '',
			password : '',
			status: '',
			role: '',
	};

const Users = () => {
	useRedirectLoggedOutUser("/login");

	const { users, isLoading, isLoggedIn, isSuccess, message } = useSelector(
	    (state) => state.auth
	);

	const [formData, setFormData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const {name,lname,email,password,status,role} = formData;
	const [ViewPost, SetPostShow] = useState(false)
  const handlePostShow = () => { SetPostShow(true) }
  const hanldePostClose = () => { SetPostShow(false) }

  const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
	    // dispatch(getProfiles());
	    // dispatch(getRoles());
	    // dispatch(getAllUsersActive());
	    dispatch(getAllUsersKbmOne());

	}, [dispatch, reducerValue]);

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setFormData({...formData, [name]: value});
	}

	const userAdd = async (event) => {
		event.preventDefault();

		if (!name || !email || !password || !status){
			return toast.error('All fields are required')
		}
		if (password.length < 6) {
	      	return toast.error("Password must be up to 6 characters");
	    }
		// if (!validateEmail(email)){
		// 	return toast.error('Please enter a valid email')
		// }

		const userData = {name, lname, email, password, userType:"kbmoneuser", status, role, plateform:"kbmone"};
		// console.log(userData);
		await dispatch(addUser(userData));
		setFormData({ name: "", lname:"", email: "", password: "", status: '', role: '' });
		SetPostShow(false);
		forceUpdate();
		navigate('/user');
		// window.location.reload();
		// await dispatch(sendVerificationEmail());
	};

	const removeUser = async (id) => {
	    await dispatch(deleteUser(id));
	    dispatch(getAllUsersKbmOne());
	};

	const confirmDelete = (id) => {
	    confirmAlert({
	      title: "Delete This User",
	      message: "Are you sure to do delete this user?",
	      buttons: [
	        {
	          label: "Delete",
	          onClick: () => removeUser(id),
	        },
	        {
	          label: "Cancel",
	          // onClick: () => alert("Click No"),
	        },
	      ],
	    });
	};

	const columns = useMemo(
    () => [
      {
        accessorFn: (row) => <Link to={`/edit-user/${row._id}`}>{row.name}</Link>, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'First Name',
        Header: () => <span>First Name</span>, //optional custom header render
      },
      {
        accessorFn: (row) => <Link to={`/edit-user/${row._id}`}>{row.lname}</Link>, //alternate way
        id: 'lname', //id required if you use accessorFn instead of accessorKey
        header: 'Last Name',
        Header: () => <span>Last Name</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.email, //alternate way
        id: 'email', //id required if you use accessorFn instead of accessorKey
        header: 'Email',
        Header: () => <span>Email Address</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.status, //alternate way
        id: 'status', //id required if you use accessorFn instead of accessorKey
        header: 'Status',
        Header: () => <span>Status</span>, //optional custom header render
      },
      {
        accessorFn: (row) => <span>
                            <FaTrashAlt
                              size={18}
                              cursor="pointer"
                              color="rgb(0,0,0,.8)"
                              onClick={() => confirmDelete(row._id)}
                            />
                            <Link to={`/edit-user/${row._id}`}>
	                            <AiFillEdit
	                              size={20}
	                              cursor="pointer"
	                              color="rgb(0,0,0,.8)"
	                            />
                            </Link>
                          </span>, //alternate way
        id: 'action', //id required if you use accessorFn instead of accessorKey
        header: 'Action',
        Header: () => <span>Action</span>, //optional custom header render
      },
    ],
    [],
  );

  const isFirstRender = useRef(true);
	const [columnVisibility, setColumnVisibility] = useState({});

	useEffect(() => {
	    const columnVisibility = sessionStorage.getItem(
	      'mrt_columnVisibility_table_1',
	    );
	    
	    if (columnVisibility) {
	      setColumnVisibility(JSON.parse(columnVisibility));
	    }
	    
	    isFirstRender.current = false;
	  }, []);

	useEffect(() => {
	    if (isFirstRender.current) return;
	    sessionStorage.setItem(
	      'mrt_columnVisibility_table_1',
	      JSON.stringify(columnVisibility),
	    );
	  }, [columnVisibility]);
	
	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container kbmone" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        
		        <KbmOneSidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    {/*<Breadcrumb />*/}
		                    
		                    <div className="row layout-top-spacing">
		                       

		                        <div className="col-12 text-end mt-4">
		                        	<button onClick={() => { handlePostShow() }} className="btn btn-secondary" type="button">Add User</button>
		                        </div>

		                        <div className="active_user_sec">
		                        
		                        	<div className="col-12">
			                        	<div className="statbox widget box box-shadow">
			                                <div className="widget-content widget-content-area">
			                                {users.length === 0 ? (
			                                	<p>User not found...</p>
			                                ):(
			                                	<MaterialReactTable 
			                                	onColumnVisibilityChange={setColumnVisibility}
                                      	state={{columnVisibility}}
			                                	columns={columns} data={users} enableRowNumbers rowNumberMode="original"/>
			                                )}
			                                </div>
			                            </div>
			                        </div>

															
		                        </div>
		                       
		                    </div>

		                </div>

		            </div>

		          {/* <Footer />*/}

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		     {/*<!-- Modal -->*/}
		    <Modal show={ViewPost}
                    onHide={hanldePostClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
			
			            	<form className="form" onSubmit={userAdd}>
			            	<h5 className="modal-title">Add User</h5>
			            	{/*<Tabs>
									    <TabList>
									      <Tab>Simple Form</Tab>
									    </TabList>

									    <TabPanel>*/}
									      <div className="row mt-4">
                          <div className="col-md-12">
                              <div className="form-group">
                                  <input type="text" className="form-control mb-3" id="fullName" placeholder="Full Name" name="name" value={name} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <input type="text" className="form-control mb-3" id="lastName" placeholder="Last Name" name="lname" value={lname} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <input type="email" className="form-control mb-3" id="email" name="email" placeholder="Email Address*" value={email} onChange={handleInputChange}/>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group mb-3">
                                  <PasswordInput placeholder="Password *" name="password" value={password} onChange={handleInputChange}/>
                              </div>
                          </div>
                          
                          <div className="col-md-12">
                          	<select value={status} onChange={handleInputChange} name="status" className="form-select form-control-sm mb-3">
											     		<option>Select Status</option>
											     		<option value="active">Active</option>
											     		<option value="inactive">In-active</option>
											     	</select>
                          </div>

                          <SuperAdminLink>
                          <div className="col-md-12">
                          	<select value={role} onChange={handleInputChange} name="role" className="form-select form-control-sm mb-3">
											     		<option>Select Role</option>
											     		<option value="superAdmin">Super Admin</option>
											     		<option value="admin">Admin</option>
											     	</select>
                          </div>  
                          </SuperAdminLink>        

                          <div className="col-md-12 mt-1">
                              <div className="form-group text-end">
                                  <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
            											<button type="button" className="btn btn btn-light-dark mx-3" onClick={hanldePostClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                              </div>
                          </div>
                      </div>
									    {/*</TabPanel>
									    
									  </Tabs>*/}
                                
                    </form>
			            
			</Modal>
		</>
	);
}

export default Users
