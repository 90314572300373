import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RESET, verifyUser } from "../../../redux/kbmone-features/auth/authSlice";

const Verify = () => {

	const dispatch = useDispatch();
  	const { verificationToken } = useParams();

  	const { isLoading } = useSelector((state) => state.auth);

	const verifyAccount = async () => {
	    await dispatch(verifyUser(verificationToken));
	    await dispatch(RESET());
	};

	return (
		<div>
			
		        <div className="verify_sec">
                    <div className="mt-5">
                    	<div className="col-md-12 mb-3">
                                    
                            <h3>Verification Account KBM-ONE</h3>
                            <p>To verify your account, click the button.</p>
                            
                        </div>
                        <button onClick={verifyAccount} type="submit" className="btn btn-secondary">Verify Account</button>
                    </div>
                </div>

		</div>
	)
}

export default Verify