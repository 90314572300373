import React,{useState} from 'react';
// import Card from '../../components/Card';
import PasswordInput from '../../components/PasswordInput';
import { Link } from 'react-router-dom';
import {AiOutlineMail} from 'react-icons/ai';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { validateEmail } from "../../../redux/kbmone-features/auth/authService";
import { forgotPassword, RESET } from "../../../redux/kbmone-features/auth/authSlice";

const Forgot = () => {

	const [email, setEmail] = useState('');
	const dispatch = useDispatch();
	
	const forgot = async (e) => {
		e.preventDefault();

		if (!email) {
	      return toast.error("Please enter an email");
	    }

	    if (!validateEmail(email)) {
	      return toast.error("Please enter a valid email");
	    }

	    const userData = {
	      email,
	    };

	    await dispatch(forgotPassword(userData));
    	await dispatch(RESET(userData));
	};

	return (
		<>
		{/*<div className="container">
			<Card>
				<AiOutlineMail size={24} color='#3b3f5c'/>
				<h4>Forgot Password</h4>
				<form onSubmit={loginUser}>
				    <div className="row mb-3">
				        <div className="col-sm-12">
				            <input type="email" className="form-control" placeholder="Email *" name="email" value={email} onChange={handleInputChange} required/>
				        </div>
				    </div>
				    <div className="col-12 mb-2">
				    	<button type="submit" className="btn btn-primary w-100">Get Reset Email</button>
				    </div>
				    <div className="d-flex justify-content-between">
				        <div className="d-flex">
				        	<span className="mx-1">Don't have an account ?</span>
				            <Link to="/login">Login</Link>
				        </div>
				    </div>
				</form>
			</Card>
		</div>*/}

		<div className="auth-container d-flex h-100">

        <div className="container mx-auto align-self-center">
    
            <div className="row">
    
                <div className="col-6 d-lg-flex d-none h-100 my-auto top-0 start-0 text-center justify-content-center flex-column">
                    <div className="auth-cover-bg-image"></div>
                    <div className="auth-overlay"></div>
                        
                    <div className="auth-cover">
    
                        <div className="position-relative">
    
                            <img src="../../src/assets/img/kbmlogo.png" alt="KBM_LOGO"/>
    
                            {/*<h2 className="mt-5 text-white font-weight-bolder px-2">Join the community of experts</h2>
                            <p className="text-white px-2">It is easy to setup with great customer experience.</p>*/}
                        </div>
                        
                    </div>

                </div>

                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-8 col-12 d-flex flex-column ms-lg-auto  align-self-center me-lg-0 mx-auto">
                    <div className="card">
                        <div className="card-body">
    
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    
                                    <h2>Recover Password KBM-ONE</h2>
                                    <p>Enter your email to recover your ID</p>
                                    
                                </div>
                                <form onSubmit={forgot}>
                                <div className="col-md-12">
                                    <div className="mb-4">
                                    	<label>Email</label>
                                        <input type="email" className="form-control" placeholder="Email *" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                                    </div>
                                </div>
                                
                                <div className="col-12">
                                    <div className="mb-4">
                                        <button type="submit" className="btn btn-secondary w-100">Recover</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                            <div className="d-flex justify-content-between">
						        <div className="d-flex">
						        	<span className="mx-1">Don't have an account ?</span>
						            <Link to="/login">Login</Link>
						        </div>
						    </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>

    </div>
    
		</>
	)
}

export default Forgot