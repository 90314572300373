import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/profiles/crm/`;

// add user profile
const addProfile = async(profileData) =>{
	const response = await axios.post(API_URL + "addProfile", profileData);
	return response.data;
};

// Get all profiles
const getProfiles = async () => {
  const response = await axios.get(API_URL + "getProfiles");
  return response.data;
};

// get user profile
const getProfile = async(id) =>{
	const response = await axios.get(API_URL + id);
	return response.data;
};

// update user profile
const updateProfile = async(id, profileData) =>{
	const response = await axios.patch(`${API_URL}${id}`, profileData);
	return response.data;
};

// delete user profile
const deleteProfile = async(id) =>{
	const response = await axios.delete(API_URL + id);
	return response.data.message;
};


const profileService = {
	addProfile,
	getProfiles,
	getProfile,
	updateProfile,
	deleteProfile,
}

export default profileService;