import React from 'react';
import { MinChatProvider, useMinChat, useMessages, useChats  } from '@minchat/react';


const currentUser = {
        id: "8843223323423other user" ,
        name: "Micheal Saunders" ,
        avatar: "urltoavatar.com/avatar.jpg" //optional
}

const otherUser = {
        id: "fc3323d3423" ,
        name: "Mercy Wells" ,
        avatar: "urltoavatar.com/mercyavatar.jpg" //optional
}

const ChatSystem = () => {

	const minchat = useMinChat();

	const chat = minchat.chat(otherUser)

	const { messages, loading , error, sendMessage , paginate , paginateLoading } = useMessages(chat);
	const { chats } = useChats();


	return (
		<div>
			<MinChatProvider
		        apiKey={'https://minchat.dirtservers.com/api/v1'}
		        user={currentUser}>
			                
			                {/* your chat app components  */}

			</MinChatProvider>
		</div>
	)
}

export default ChatSystem