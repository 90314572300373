import React,{useEffect, useState} from 'react';
import Card from '../../components/Card';
import { useDispatch, useSelector } from "react-redux";
import PasswordInput from '../../components/PasswordInput';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {MdPassword} from 'react-icons/md';
import { RESET, resetPassword } from "../../../redux/crm-features/auth/authSlice";

const initialState ={
	password : '',
	password2 : '',
}

const Reset = () => {

	const [formData, setFormData] = useState(initialState);

	const {password,password2} = formData;
	const { resetToken } = useParams();
  	// console.log(resetToken);

  	const { isLoading, isLoggedIn, isSuccess, message } = useSelector(
	    (state) => state.auth
	);

  	const dispatch = useDispatch();
  	const navigate = useNavigate();

	const handleInputChange = (e) => {
	    const { name, value } = e.target;
	    setFormData({ ...formData, [name]: value });
	};

	const reset = async (e) => {
	    e.preventDefault();

	    if (password.length < 6) {
	      return toast.error("Password must be up to 6 characters");
	    }
	    if (password !== password2) {
	      return toast.error("Passwords do not match");
	    }

	    const userData = {
	      password,
	    };

	    await dispatch(resetPassword({ userData, resetToken }));
	};

	useEffect(() => {
	    if (isSuccess && message.includes("Reset Successful")) {
	      navigate("/crm/login");
	    }

	    dispatch(RESET());
	}, [dispatch, navigate, message, isSuccess]);

	return (
		<>
		{/*<div className="container">
			<Card>
				<MdPassword size={24} color='#3b3f5c'/>
				<h4>Reset Password</h4>
				<form onSubmit={loginUser}>
				    <div className="row mb-3">
				        <div className="col-sm-12">
				        	<PasswordInput placeholder="Password *" name="password" value={password} onChange={handleInputChange} />
				        </div>
				    </div>
				    <div className="row mb-3">
				        <div className="col-sm-12">
				        	<PasswordInput placeholder="Confirm Password *" name="password2" value={password2} onChange={handleInputChange} />
				        </div>
				    </div>
				    <div className="col-12 mb-2">
				    	<button type="submit" className="btn btn-primary w-100">Reset Password</button>
				    </div>
				    <div className="d-flex justify-content-between">
				        <div className="">
				            <Link to="/forgot">Forgot Password</Link>
				        </div>
				        <div className="d-flex">
				            <Link to="/login">Login</Link>
				        </div>
				    </div>
				</form>
			</Card>
		</div>*/}

		<div className="auth-container d-flex h-100">

        <div className="container mx-auto align-self-center">
    
            <div className="row">
    
                <div className="col-6 d-lg-flex d-none h-100 my-auto top-0 start-0 text-center justify-content-center flex-column">
                    <div className="auth-cover-bg-image"></div>
                    <div className="auth-overlay"></div>
                        
                    <div className="auth-cover">
    
                        <div className="position-relative">
    
                            <img src="../../src/assets/img/kbmlogo.png" alt="KBM_LOGO"/>
    
                            {/*<h2 className="mt-5 text-white font-weight-bolder px-2">Join the community of experts</h2>
                            <p className="text-white px-2">It is easy to setup with great customer experience.</p>*/}
                        </div>
                        
                    </div>

                </div>

                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-8 col-12 d-flex flex-column ms-lg-auto  align-self-center me-lg-0 mx-auto">
                    <div className="card">
                        <div className="card-body">
    
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    
                                    <h2>Reset Password</h2>
                                    <p>Enter your email to recover your ID</p>
                                    
                                </div>

                                <form onSubmit={reset}>
                                <div className="col-md-12">
                                    <div className="mb-4">
                                    	<label>Password</label>
                                        <PasswordInput placeholder="Password *" name="password" value={password} onChange={handleInputChange} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-4">
                                    	<label>Confirm Password</label>
                                        <PasswordInput placeholder="Confirm Password *" name="password2" value={password2} onChange={handleInputChange} />
                                    </div>
                                </div>
                                
                                <div className="col-12">
                                    <div className="mb-4">
                                        <button type="submit" className="btn btn-secondary w-100">Reset Password</button>
                                    </div>
                                </div>
                                </form>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>

    </div>
    
		</>
	)
}

export default Reset