import { useSelector } from "react-redux";
import {
  selectIsLoggedIn,
  selectUser,
} from "../../redux/crm-features/auth/authSlice";

export const ShowOnLogin = ({ children }) =>{
	const isLoggedIn = useSelector(selectIsLoggedIn);

	if (isLoggedIn) {
	    return <>{children}</>;
	}
	return null;
};

export const ShowOnLogout = ({ children }) =>{
	const isLoggedIn = useSelector(selectIsLoggedIn);

	if (!isLoggedIn) {
	    return <>{children}</>;
	}
	return null;
};

export const SuperAdminLink = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);

  if (isLoggedIn && (user?.role === "superAdmin")) {
    return <>{children}</>;
  }
  return null;
};

export const AdminLink = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);

  if (isLoggedIn && (user?.role === "admin")) {
    return <>{children}</>;
  }
  return null;
};

export const ChatAllocate = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);

  if (isLoggedIn && (user?.chatAllocate === "yes")) {
    return <>{children}</>;
  }
  return null;
};