import React,{useState} from 'react'
import {AiOutlineEyeInvisible, AiOutlineEye} from 'react-icons/ai';

const PasswordInput = ({placeholder, value, name, onChange, onPaste}) => {

		const [showpassword, setShowPassword] = useState(false);
		const togglePassword = () =>{
			setShowPassword(!showpassword);
		}
		return (
		<div>
			<div className="password_input">
				<input type={showpassword ? 'text' : 'password'} className="form-control" placeholder={placeholder} name={name} 
				value={value} onChange={onChange} onPaste={onPaste} required/>
				<div className="password_icon" onClick={togglePassword}>
					{showpassword ? (<AiOutlineEyeInvisible size={20} />) : (<AiOutlineEye size={20}/>)}
				</div>
			</div>
		</div>
	)
}

export default PasswordInput