import React,{useState,useEffect,Component} from 'react';
import { Link } from "react-router-dom";
import ModuleSideBar from './ModuleSideBar';
import Footer from '../components/Footer';
import { AiOutlineSearch,AiOutlineArrowLeft,AiOutlineEllipsis } from "react-icons/ai";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

const ModuleRecordDetail = () => {

	useRedirectLoggedOutUser("/crm/login");

	const areas = document.querySelectorAll(".tab_overview_sec");

	const observer = new IntersectionObserver(entries => {
	    
	    entries.forEach(entry => {
	        entry.target.classList.toggle("show", entry.isIntersecting);

	        const id = entry.target.getAttribute('id');
	        
	        // if (entry.isIntersecting) {  
	        //     document.querySelector('a[href="#'+ id +'"]').classList.toggle("active-link");
	        // } else {
	        //     document.querySelector('a[href="#'+ id +'"]').classList.remove("active-link");
	        // }
	    
	    });
	});

	const [activeLink, setActiveLink] = useState(null);

	  const handleLinkClick = (index) => {
	    setActiveLink(index);
	  };

	  const [tabIndex, setTabIndex] = useState(0);
	const [tabIndexSec, setTabIndexSec] = useState(0);
	const [toggle, setToggle] = useState(false);
	const [show,setShow]=useState(true);

	const toggler = ()=>{
		toggle?setToggle(false):setToggle(true);
	}

	
	return (
		<>
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container reports_sec" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		      	{/*<!--  BEGIN SIDEBAR  -->*/}
	        {/*<div className="sidebar-wrapper sidebar-theme">

	            <nav id="sidebar">
	            
	                <div className="navbar-nav theme-brand flex-row  text-center">
	                    <div className="nav-logo">
	                        
	                        <div className="nav-item theme-text">
	                            <Link to="" className="nav-link"> KBM </Link>
	                        </div>
	                    </div>
	                    <div className="nav-item sidebar-toggle">
	                        <div className="btn-toggle sidebarCollapse">
	                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevrons-left"><polyline points="11 17 6 12 11 7"></polyline><polyline points="18 17 13 12 18 7"></polyline></svg>
	                        </div>
	                    </div>
	                </div>
	                <div className="shadow-bottom"></div>
	                
	                <ul className="list-unstyled menu-categories" id="accordionExample">
	                <li className="mt-4">
	                	<h5>Folders </h5>
	                </li>
	                <li>
	                	<form className="custom-search mb-3">
							<div className="position-relative">
								<input type="text" placeholder="Search" className="form-control" />
								<button><AiOutlineSearch size={18}/></button>
							</div>
						</form>
	                </li>
	                <li className="menu active">
	                    <Link to="" className="dropdown-toggle mb-2">
                            <div className="">
                                <span title="All Reports">All Reports</span>
                            </div>
                        </Link>
                        
                    </li>
	                <li className="menu active">
	                    <Link to="" className="dropdown-toggle mb-2">
                            <div className="">
                                <span title="My Reports">My Reports</span>
                            </div>
                        </Link>
                        
                    </li>
	                </ul>
	                
	            </nav>

	        </div>*/}
	       {/* <!--  END SIDEBAR  -->*/}

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content reportDetail module-record-detail">
		            <div className="layout-px-spacing">
					
		                <div className="middle-content container-xxl p-0">

		                    
		                    <div className="row layout-top-spacing">
		                    
		                    <div className="viewModule_rightSide">
		                    	<div className="d-flex justify-content-between align-items-baseline flex-wrap">
														<div className="">
															<div className="d-flex align-items-center">
																<Link to="/crm/module-record"><AiOutlineArrowLeft size={18} /></Link>
																<img className="avatar" src="../src/assets/img/avatar.png"/>
																<h4>Ms. Carissa Kidman (Sample)</h4>
															</div>
														</div>
														<div className="d-flex align-items-center">
															<button className="btn btn-secondary mx-1">Send Mail</button>
															<button className="btn btn-secondary mx-1">Convert</button>
															<button className="btn btn-secondary mx-1">Edit</button>
															<div className="nav-item dropdown">
									              <Link className="dropdown-toggle btn btn-secondary" to="/crm" role="button" data-bs-toggle="dropdown"><AiOutlineEllipsis size={25}/></Link>
									              <ul className="dropdown-menu custom-dropdown-menu" style={{listStyleType : 'none'}}>
									              	<li><Link className="dropdown-item" to="">Clone</Link></li>
															    <li><Link className="dropdown-item" to="">Delete</Link></li>
															    <li><Link className="dropdown-item" to="">Share</Link></li>
															    <li><Link className="dropdown-item" to="">Print Preview</Link></li>
															    <li><Link className="dropdown-item" to="">Mail Merge</Link></li>
									              </ul>
									            </div>
														</div>
													</div>
													<div className="mt-4 d-flex justify-content-between align-items-baseline flex-wrap">

														<div className="col-md-3 col-12">
															<div className="moduleRecordDetailBodySidebar">
																<div className="moduleRecordDetailSidebarContent">
																<h5>Related List</h5>
																	<ul>
																		<li className={activeLink === 0 ? 'active' : ''} onClick={() => handleLinkClick(0)}>
																			<a href="#notes">Notes</a>
																		</li>
																		<li className={activeLink === 0 ? 'active' : ''} onClick={() => handleLinkClick(1)}>
																			<a href="#attachments">Attachments</a>
																		</li>
																		<li className={activeLink === 0 ? 'active' : ''} onClick={() => handleLinkClick(2)}>
																			<a href="#email">Email</a>
																		</li>
																		<li className={activeLink === 0 ? 'active' : ''} onClick={() => handleLinkClick(3)}>
																			<a href="#test">Test</a>
																		</li>
																		<li>
																			<a href="">Add Related List</a>
																		</li>
																	</ul>
																</div>
																<div className="moduleRecordDetailSidebarContent">
																<h5>Links</h5>
																	<ul>
																		
																		<li>
																			<a href="">Add Related List</a>
																		</li>
																	</ul>
																</div>
															</div>
														</div>	
			                    	<div className="col-md-9 col-12">
	                            <div className="moduleRecordDetailBodyRightSide">
	                            	<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
														    <TabList className="d-flex">
														        <Tab>Overview</Tab>
														        <Tab>Timeline</Tab>
														    </TabList>
											                <TabPanel>
												                <div className="layout-tab">
												                 	<div className="tab_overview_sec">
												                 		<div className="tab_panel">
												                 			<div className="row">
												                 				<div className="tab_panel_inputFields col-sm-6 col-12">
													                 				<div className="inputFields_row">
													                 					<label>Lead Owner</label>
													                 					<input className="form-control" type="text" defaultValue="Demo"/>
													                 				</div>
													                 				<div className="inputFields_row">
													                 					<label>Email</label>
													                 					<input className="form-control" type="email" defaultValue="demo@demmo.com"/>
													                 				</div>
													                 				<div className="inputFields_row">
													                 					<label>Phone</label>
													                 					<input className="form-control" type="email" defaultValue="555-555-5555"/>
													                 				</div>
													                 				<div className="inputFields_row">
													                 					<label>Modified By</label>
													                 					<input className="form-control" type="text" defaultValue=""/>
													                 				</div>
													                 			</div>
												                 			</div>
												                 		</div>
												                 	</div>
												                 	<div className="tab_overview_sec">
												                 	<span onClick={()=>setShow(!show)} className="openToggle-div">{ show ? "Hide Details" : "Show Details"}</span>
												                 	{

												                 		show?

												                 		<div className="toggle-div">
												                 			<div className="tab_panel">
													                 			<h5>Lead Information</h5>
													                 			<div className="row">
													                 				<div className="tab_panel_inputFields col-sm-6 col-12">
														                 				<div className="inputFields_row">
														                 					<label>Lead Owner</label>
														                 					<input className="form-control" type="text" defaultValue="Demo"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Email</label>
														                 					<input className="form-control" type="email" defaultValue="demo@demmo.com"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Fax</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Website</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>URL 1</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Multi-Select Lookup 1</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>test</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 			</div>
														                 			<div className="tab_panel_inputFields col-sm-6 col-12">
														                 				<div className="inputFields_row">
														                 					<label>Company</label>
														                 					<input className="form-control" type="text" defaultValue="Oh My Goodknits Inc"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Lead Name</label>
														                 					<input className="form-control" type="text" defaultValue="Ms. Carissa Kidman (Sample)"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Title</label>
														                 					<input className="form-control" type="text" defaultValue="Director of Sales"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Phone</label>
														                 					<input className="form-control" type="text" defaultValue="555-555-5555"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Lead Source</label>
														                 					<input className="form-control" type="text" defaultValue="Advertisement"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Industry</label>
														                 					<input className="form-control" type="text" defaultValue="Data/Telecom OEM"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Annual Revenue</label>
														                 					<input className="form-control" type="text" defaultValue="£ 200,000.00"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Rating</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 			</div>
													                 			</div>
													                 		</div>
													                 		<div className="tab_panel">
													                 			<h5>Address Information</h5>
													                 			<div className="row">
													                 				<div className="tab_panel_inputFields col-sm-6 col-12">
														                 				<div className="inputFields_row">
														                 					<label>Street</label>
														                 					<input className="form-control" type="text" defaultValue="5 Boston Ave #88"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Province</label>
														                 					<input className="form-control" type="text" defaultValue="SD"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Country</label>
														                 					<input className="form-control" type="text" defaultValue="United States"/>
														                 				</div>
														                 				{/*<div className="inputFields_row">
														                 					<label>Contact Owner</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Description</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Employment Status</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>First Name</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Image</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Leads Source</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Next to Kin</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Next to Kin Phone</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>NI Number</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Post Code 1</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>*/}
														                 			</div>
														                 			<div className="tab_panel_inputFields col-sm-6 col-12">
														                 				<div className="inputFields_row">
														                 					<label>City</label>
														                 					<input className="form-control" type="text" defaultValue="Sioux Falls"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Postal Code</label>
														                 					<input className="form-control" type="text" defaultValue="57105"/>
														                 				</div>
														                 				{/*<div className="inputFields_row">
														                 					<label>City</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Date of Birth</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>User 1</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Email Id</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>EMS Registered Date</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Ethnicity</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Gender</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Last Name</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Mobile</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Next to Kin (Email)</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Next to Kin Relation</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Phone</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Sector</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>*/}
														                 			</div>
													                 			</div>
													                 		</div>
													                 		<div className="tab_panel">
													                 			<h5>Description Information</h5>
													                 			<div className="row">
													                 				<div className="tab_panel_inputFields col-sm-6 col-12">
														                 				<div className="inputFields_row">
														                 					<label>Description</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				{/*<div className="inputFields_row">
														                 					<label>Apprenticeship Employer</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Apprenticeship Start Date</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>CV Review Date</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Employer phone</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>IAG</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>ILP and Timetable</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Registration Date</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Skills Scan Completed</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Start Claimed Date</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>*/}
														                 			</div>
														                 			{/*<div className="tab_panel_inputFields col-sm-6 col-12">
														                 				<div className="inputFields_row">
														                 					<label>Agreement Signed</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Apprenticeship Standard</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Apprenticeship Status</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Docs Missing</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Funding Provision</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>IAG Date</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Induction Date</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Ref Provided Date</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Screeners / IA</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Start Category</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 			</div>*/}
													                 			</div>
													                 		</div>
													                 		<div className="tab_panel">
													                 			<h5>Visit Summary</h5>
													                 			<div className="row">
													                 				<div className="tab_panel_inputFields col-sm-6 col-12">
														                 				<div className="inputFields_row">
														                 					<label>Most Recent Visit</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Average Time Spent (Minutes)</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Referrer</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>First Visit</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 			</div>
														                 			<div className="tab_panel_inputFields col-sm-6 col-12">
														                 				<div className="inputFields_row">
														                 					<label>First Page Visited</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Number Of Chats</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Visitor Score</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Days Visited</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 			</div>
													                 			</div>
													                 		</div>
													                 		<div className="tab_panel">
													                 			<h5>Section 4</h5>
													                 			<div className="row">
													                 				<div className="tab_panel_inputFields col-sm-6 col-12">
														                 				<div className="inputFields_row">
														                 					<label>End Category</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Review/Testimonial</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Support - 2nd Call</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Support - 4th Call</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Testimonial</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Traineeship Employer</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 			</div>
														                 			<div className="tab_panel_inputFields col-sm-6 col-12">
														                 				<div className="inputFields_row">
														                 					<label>End Survey Completed On</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Support - 1st Call</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Support - 3rd Call</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Support - 5th Call</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Timesheets</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Traineeship Progress Review</label>
														                 					<input className="form-control" type="text" defaultValue="--"/>
														                 				</div>
														                 			</div>
													                 			</div>
													                 		</div>
													                 		<div className="tab_panel">
													                 			<h5>Section 5</h5>
													                 			<div className="row">
													                 				<div className="tab_panel_inputFields col-sm-6 col-12">
														                 				<div className="inputFields_row">
														                 					<label>Learning Aims</label>
														                 					<input className="form-control" type="text" defaultValue="Asad"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Number of Evidences in Portfolio</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Off-the-job hours planned</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Prior Attainment</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 			</div>
														                 			<div className="tab_panel_inputFields col-sm-6 col-12">
														                 				<div className="inputFields_row">
														                 					<label>Learning Method</label>
														                 					<input className="form-control" type="text" defaultValue="Asad"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Off-the-job hours completed</label>
														                 					<input className="form-control" type="email" defaultValue="--"/>
														                 				</div>
														                 				<div className="inputFields_row">
														                 					<label>Performance Review Date</label>
														                 					<input className="form-control" type="text" defaultValue=""/>
														                 				</div>
														                 			</div>
													                 			</div>
													                 		</div>	
												                 		</div>
												                 		
												                 		:null
												                 	}
												                 	</div>
												                 	<div id="notes" className="tab_overview_sec">
												                 		<div className="tab_panel">
												                 			<h5>Notes</h5>
												                 			<div className="row">
												                 				<div className="tab_panel_inputFields col-sm-12 col-12">
													                 				<div className="inputFields_row">
													                 					<textarea className="form-control" type="text" defaultValue="" placeholder="Add a note"></textarea>
													                 				</div>
													                 				<div className="inputFields_row justify-content-end">
													                 					<button className="btn btn-secondary">Save</button>
													                 					<button className="btn btn-secondary">Cancel</button>
													                 				</div>
													                 			</div>
												                 			</div>
												                 		</div>
												                 	</div>
												                 	<div id="attachments" className="tab_overview_sec">
												                 		<div className="tab_panel">
												                 			<h5>Attachments</h5>
												                 			<div className="row">
												                 				<div className="tab_panel_inputFields col-sm-12 col-12">
													                 				<div className="inputFields_row">
													                 					<input className="form-control" type="file" defaultValue="" placeholder="Add a attachment"/>
													                 				</div>
													                 				<div className="inputFields_row justify-content-end">
													                 					<button className="btn btn-secondary">Save</button>
													                 					<button className="btn btn-secondary">Cancel</button>
													                 				</div>
													                 			</div>
												                 			</div>
												                 		</div>
												                 	</div>
												                 	<div id="email" className="tab_overview_sec">
												                 		<div className="tab_panel">
												                 			<h5>Emails</h5>
												                 			<Tabs>
												                 				<TabList className="d-flex">
																			        <Tab>Mails</Tab>
																			        <Tab>Drafts</Tab>
																			        <Tab>Scheduled</Tab>
																			    </TabList>
																			    <TabPanel>
																			    	<div className="layout-tab">
																			    		<div className="row">
															                 				<div className="tab_panel_inputFields col-sm-12 col-12">
																                 				<div className="inputFields_row">
																                 					<small>No Records 1</small>
																                 				</div>
																                 			</div>
															                 			</div>
																			    	</div>
																			    </TabPanel>
																			    <TabPanel>
																			    	<div className="layout-tab layout-tab inner-tab active">
																			    		<div className="row">
															                 				<div className="tab_panel_inputFields col-sm-12 col-12">
																                 				<div className="inputFields_row">
																                 					<small>No Records 2</small>
																                 				</div>
																                 			</div>
															                 			</div>
																			    	</div>
																			    </TabPanel>
																			    <TabPanel>
																			    	<div className="layout-tab layout-tab inner-tab active">
																			    		<div className="row">
															                 				<div className="tab_panel_inputFields col-sm-12 col-12">
																                 				<div className="inputFields_row">
																                 					<small>No Records 3</small>
																                 				</div>
																                 			</div>
															                 			</div>
																			    	</div>
																			    </TabPanel>
												                 			</Tabs>
												                 		</div>
												                 	</div>
												                 	<div id="test" className="tab_overview_sec">
												                 		<div className="tab_panel">
												                 			<h5>Test</h5>
												                 			<div className="row">
												                 				<div className="tab_panel_inputFields col-sm-12 col-12">
													                 				<div className="inputFields_row">
																						<small>No Records</small>
													                 				</div>
													                 			</div>
												                 			</div>
												                 		</div>
												                 	</div>
												                </div>
											                </TabPanel>
											                <TabPanel>
												                <div className="layout-tab inner-tab active">
												                	<div className="tab_overview_sec">
												                 		<div className="tab_panel">
												                 			<h5>Upcoming Actions</h5>
												                 			<div className="row">
												                 				<div className="tab_panel_inputFields col-sm-12 col-12">
													                 				<div className="inputFields_row">
																						<small>No upcoming actions</small>
													                 				</div>
													                 			</div>
												                 			</div>
												                 		</div>
												                 	</div>
												                 	<div className="tab_overview_sec">
												                 		<div className="tab_panel">
												                 			<h5>History</h5>
												                 			<div className="row">
												                 				<div className="tab_panel_inputFields col-sm-12 col-12">
													                 				<div className="inputFields_row">
																						<small>No Records</small>
													                 				</div>
													                 			</div>
												                 			</div>
												                 		</div>
												                 	</div>
												                </div>
											                </TabPanel>
										                </Tabs>
	                            </div>
	                      		</div>

                      		</div>
		                    </div>
		                     		
		                     	
		                    	
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}
		</>
	)
}

export default ModuleRecordDetail