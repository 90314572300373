import React,{useState,useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Card from '../../components/Card';
import PasswordInput from '../../components/PasswordInput';
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from 'react-router-dom';
import {GrInsecure} from 'react-icons/gr';
import {
  loginWithCode,
  RESET,
  sendLoginCode,
} from "../../../redux/crm-features/auth/authSlice";

const LoginWithCode = () => {

	const [loginCode, setLoginCode] = useState('');
	const { email } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { isLoading, isLoggedIn, isSuccess } = useSelector(
	    (state) => state.auth
	);

	const sendUserLoginCode = async () => {
	    await dispatch(sendLoginCode(email));
	    await dispatch(RESET());
	};

	const loginUserWithCode = async (e) => {
		e.preventDefault();

	    if (loginCode === "") {
	      return toast.error("Please fill in the login code");
	    }
	    if (loginCode.length !== 6) {
	      return toast.error("Access code must be 6 characters");
	    }

	    const code = {
	      loginCode,
	    };

	    await dispatch(loginWithCode({ code, email }));
	}

	useEffect(() => {
	    if (isSuccess && isLoggedIn) {
	      navigate("/");
	    }

	    dispatch(RESET());
	}, [isLoggedIn, isSuccess, dispatch, navigate]);

	return (
		<>
		{/*<div className="container">
			<Card>
				<GrInsecure size={24} color='#3b3f5c'/>
				<h4>Enter Access Code</h4>
				<form onSubmit={loginUser}>
				    <div className="row mb-3">
				        <div className="col-sm-12">
				            <input type="text" className="form-control" placeholder="Access Code" name="loginCode" 
				            value={loginCode} onChange={(e) => setLoginCode(e.target.value)} required/>
				        </div>
				    </div>
				    <div className="col-12 mb-2">
				    	<button type="submit" className="btn btn-primary w-100">Proceed to Login</button>
				        <span>Check your mail form login access code</span>
				    </div>
				    <div className="d-flex justify-content-between">
				        <div className="d-flex">
				            <Link to="/login">Login</Link>
				        </div>
				        <div className="d-flex">
				            <b>Resend Code</b>
				        </div>
				    </div>
				</form>
			</Card>
		</div>*/}

		<div className="auth-container d-flex h-100">

        <div className="container mx-auto align-self-center">
    
            <div className="row">
    
                <div className="col-6 d-lg-flex d-none h-100 my-auto top-0 start-0 text-center justify-content-center flex-column">
                    <div className="auth-cover-bg-image"></div>
                    <div className="auth-overlay"></div>
                        
                    <div className="auth-cover">
    
                        <div className="position-relative">
    
                            <img src="../../src/assets/img/kbmlogo.png" alt="KBM_LOGO"/>
    
                            {/*<h2 className="mt-5 text-white font-weight-bolder px-2">Join the community of experts</h2>
                            <p className="text-white px-2">It is easy to setup with great customer experience.</p>*/}
                        </div>
                        
                    </div>

                </div>

                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-8 col-12 d-flex flex-column ms-lg-auto  align-self-center me-lg-0 mx-auto">
                    <div className="card">
                        <div className="card-body">
    
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    
                                    <h2>Enter Access Code</h2>
                                    <p>Enter your code</p>
                                    
                                </div>
                                <form onSubmit={loginUserWithCode}>
                                <div className="col-md-12">
                                    <div className="mb-4">
                                    	<label>Access Code</label>
                                        <input type="text" className="form-control" placeholder="Access Code" name="loginCode" 
				            			value={loginCode} onChange={(e) => setLoginCode(e.target.value)} required/>
                                    </div>
                                </div>
                                
                                <div className="col-12">
                                    <div className="mb-4">
                                        <button type="submit" className="btn btn-secondary w-100">Proceed to Login</button>
                                        <span>Check your mail form login access code</span>
                                    </div>
                                </div>
                                </form>
                            </div>
                            <div className="d-flex justify-content-between">
						        <div className="d-flex">
						            <Link to="/crm/login">Login</Link>
						        </div>
						        <div onClick={sendUserLoginCode} className="d-flex" style={{cursor: 'pointer'}}>
						            <b>Resend Code</b>
						        </div>
						    </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>

    </div>
		</>
	)
}

export default LoginWithCode