import React,{useEffect} from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { Link, useParams } from 'react-router-dom'; 
import { toast } from "react-toastify";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import {
  getAll,
} from "../../redux/crm-features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

const MasterData = () => {
	useRedirectLoggedOutUser("/crm/login");

	const { id } = useParams();
	const dispatch = useDispatch();
	const { users, isLoading, isSuccess, message } = useSelector(
	    (state) => state.auth
	);

	useEffect(() => {
	    dispatch(getAll());
	}, [dispatch]);

	return (
		<div>

		{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">

			                    {/*content*/}


			                    <div className="col-lg-12">
			                    	<div className="col-12">
			                    		<div className="d-flex align-items-center justify-content-between">
			                    			<h5 className="m-0">Master Data</h5>
			                    			{/*<Link className="btn btn-secondary" to={`/master-data`}>Back</Link>*/}
			                    			{/*<button onClick={() => { handlePostShow() }} className="v-link btn btn-secondary _effect--ripple waves-effect waves-light">Add Company</button>*/}
			                    		</div>
			                    	</div>
			                    	<div className="row mt-4">
				                    	<div className="col-md-4 col-sm-6 col-12">
				                    		<div className="stateBox">
				                    		<Link to={`/crm/master-data-detail`}>
				                    			<h4>Companies</h4>
				                    			<span><b>{users.length}</b></span>
				                    		</Link>
				                    		</div>
				                    	</div>
				                    	<div className="col-md-4 col-sm-6 col-12">
				                    		<div className="stateBox">
				                    		<Link to="/crm">
				                    			<h4>Modules</h4>
				                    			<span><b>26</b></span>
				                    		</Link>
				                    		</div>
				                    	</div>
				                    	<div className="col-md-4 col-sm-6 col-12">
				                    		<div className="stateBox">
				                    		<Link to='/crm'>
				                    			<h4>User</h4>
				                    			<span><b>2</b></span>
				                    		</Link>
				                    		</div>
				                    	</div>	                    		
			                    	</div>
		                            
	                        	</div>
	                        	

			                    {/*content*/}

		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		</div>
	)
}

export default MasterData