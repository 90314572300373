import React from 'react';
import { Link } from 'react-router-dom'; 
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { InputGroup, Form, Col, Row, DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap';
import { pixAddress, fileAddress } from './srcSet';
import pdfIco from '../formBuilder/image/pdfIco.png';
import wordIco from '../formBuilder/image/wordIco.png';
import excelIco from '../formBuilder/image/excelIco.png';
import pptIco from '../formBuilder/image/pptIco.png';
import imageIco from '../formBuilder/image/imageIco.png';
import audioIco from '../formBuilder/image/audioIco.png';
import videoIco from '../formBuilder/image/videoIco.png';
import attachIco from '../formBuilder/image/attachIco.png';
import { TbTargetArrow } from 'react-icons/tb';
import { BiMessageSquareEdit } from 'react-icons/bi';
import { BsFillCheckCircleFill, BsImage } from 'react-icons/bs';
import { FiPaperclip } from 'react-icons/fi';
import { FaRegFilePdf } from 'react-icons/fa';
import { MdOutlineAudiotrack, MdOutlineOndemandVideo } from 'react-icons/md';
import { AiOutlineFileWord, AiOutlineFileExcel, AiOutlineFilePpt, AiOutlineFile } from 'react-icons/ai';
import { savePix, saveFile, renameFile, deleteFile, updateModule, 
  findFormDataCount, addFormData, findFormData, updateFormData, deleteFormData, getFormData,
} from "../redux/crm-features/moduleItem/moduleSlice";
import { getLeads }	from "../redux/crm-features/lead/leadSlice";
import { getUserType, getUserInfo }	from "../redux/crm-features/auth/authSlice";

const copyWithFiles = (objectArr) => {
  const arr = JSON.parse(JSON.stringify(objectArr))
  for(let i=0; i<arr.length; i++) {
    const arr2 = arr[i].components
    for(let i2=0; i2<arr2.length; i2++) {
      const arr3 = arr2[i2].components
      for(let i3=0; i3<arr3.length; i3++) {
        var field = arr3[i3]
        if(['imageUpload', 'fileUpload'].includes(field.type)) {
          field.options = objectArr[i].components[i2].components[i3].options
          console.log(77, field.options)
          console.log(77, objectArr[i].components[i2].components[i3].options)
          // console.log(88, objectArr[i].components[i2].components[i3].options)
        }
      }
    }
  }
  return arr
}

const previewArrMap = async (objectArr, saving, dispatch) => {
  objectArr = copyWithFiles(objectArr)
  var checkRequired, checkEmail, checkPhone, error = false, multiSelectArr=[], imageUploadArr=[], fileUploadArr=[]
  function err(x, u) {
    console.log(u)
    error = x
  }
  function multiArr(x) {
    multiSelectArr.push(x)
  }
  function imgUploadArr(elm, objectArr, i, ic, icc) {
    imageUploadArr.push({elm, objectArr, i, ic, icc})
  }
  function flUploadArr(elm, objectArr, i, ic, icc) {
    fileUploadArr.push({elm, objectArr, i, ic, icc})
  }
  function isValidEmail(email) {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  var formPreview = objectArr.map(
    (item, i) => (
      <Form key={i}>
        <div className='section-title text-black'>{item.label}</div>
        <div className='elm-section-box'>
          {item.components.map(
            (itemL2, ic) => (
              <div key={ic} className='elm-section-col px-3 me-4'>
                {itemL2.components.map(
                  (itemL3, icc) => (
                    checkRequired = itemL3.required && !exist(itemL3.value) ? true : false,
                    checkEmail = itemL3.type==='email' && exist(itemL3.value) && !isValidEmail(itemL3.value) ? true : false,
                    checkPhone = itemL3.type==='phone' && exist(itemL3.value) && itemL3.value?.length<10 ? true : false,
                    <Form.Group key={icc} as={Row} className="mb-3">
                      <Form.Label className='elm-title' >
                        {itemL3.label}
                      </Form.Label>
                      <Col style={{width:'100%'}}>
                        {elmObj(itemL3, objectArr, i, ic, icc, dispatch)}
                        {itemL3.type==='multiSelect' && multiArr(itemL3)}
                        {itemL3.type==='imageUpload' && imgUploadArr(itemL3, objectArr, i, ic, icc)}
                        {itemL3.type==='fileUpload' && flUploadArr(itemL3, objectArr, i, ic, icc)}
                        {checkRequired && err(true, 'Required')}
                        {checkEmail && err(true, 'Email')}
                        {checkPhone && err(true, 'Phone')}
                        <div>{checkEmail && <span className='font-red'>Email is not correct.</span>}</div>
                        <div>{checkPhone && <span className='font-red'>Phone number can not be less than 10 digit.</span>}</div>
                        <div>{saving && checkRequired && <span className='font-red'>This field is required.</span>}</div>
                      </Col>
                    </Form.Group>
                  )
                )}
              </div>
            )
          )}
        </div>
      </Form>
    )
  )

  return { formPreview, error, multiSelectArr, imageUploadArr, fileUploadArr, objectArr }
}

const pdfType = ['pdf']
const wordType = ['doc', 'docx', 'docm', 'dot', 'dotm', 'dotx']
const excelType = ['csv', 'xla', 'xlam', 'xls', 'xlsb', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx', 'xml']
const pptType = ['ppt', 'pptx', 'pptm', 'potm', 'ppam', 'pps', 'ppsm', 'ppsx']
const imageType = ['tif', 'tiff', 'bmp', 'jpg', 'jpeg', 'gif', 'png', 'eps', 'raw', 'cr2', 'nef', 'orf', 'sr2', 'psd', 'xcf', 'al', 'cdr']
const audioType = ['3gp', 'aa', 'aac', 'aax', 'act', 'aiff', 'alac', 'amr', 'ape', 'au', 'awb', 'dss', 'dvf', 'flac', 'gsm', 'iklax', 'ivs', 'm4a', 'm4b', 'm4p', 'mmf', 'mp3', 'mpc', 'msv', 'nmf', 'ogg', 'oga', 'mogg', 'opus', 'ra', 'rm', 'raw', 'rf64', 'sln', 'tta', 'voc', 'vox', 'wav', 'wma', 'wv', 'webm', '8svx', 'cda']
const videoType = ['webm', 'mkv', 'flv', 'vob', 'ogv', 'ogg', 'drc', 'gifv', 'mng', 'avi', 'mts', 'm2ts', 'ts', 'mov', 'qt', 'wmv', 'yuv', 'rm', 'rmvb', 'viv', 'asf', 'amv', 'mp4', 'm4p', 'm4v', 'mpg', 'mp2', 'mpeg', 'mpe', 'mpv', 'm2v', 'm4v', 'svi', '3gp', '3g2', 'mxf', 'roq', 'nsv', 'flv', 'f4v', 'f4p', 'f4a', 'f4b']

const pdfIcon = <FaRegFilePdf style={{}}/>
const wordIcon = <AiOutlineFileWord style={{}}/>
const excelIcon = <AiOutlineFileExcel style={{}}/>
const pptIcon = <AiOutlineFilePpt style={{}}/>
const imageIcon = <BsImage style={{}}/>
const audioIcon = <MdOutlineAudiotrack style={{}}/>
const videoIcon = <MdOutlineOndemandVideo style={{}}/>
const fileIcon = <AiOutlineFile style={{}}/>

const setType = (x) => {
  var tpx
  switch (true) {
      case pdfType.includes(x) : tpx = pdfIco; break;
      case wordType.includes(x) : tpx = wordIco; break;
      case excelType.includes(x) : tpx = excelIco; break;
      case pptType.includes(x) : tpx = pptIco; break;
      case imageType.includes(x) : tpx = imageIco; break;
      case audioType.includes(x) : tpx = audioIco; break;
      case videoType.includes(x) : tpx = videoIco; break;
      default: tpx = attachIco
  }
  return tpx
}

const setUserType = (x) => {
  var tpx
  switch (x) {
      case 1 : tpx = 'Student'; break;
      case 2 : tpx = 'Staff'; break;
      default: tpx = ''
  }
  return tpx
}

const fileChangeHandler = (e, key, objectArr, i, ic, icc) => {
  const file = e.target.files[0]
  if(file) {
    const date = new Date();
    const dateN = date.getTime().toString();

    var fileName = file.name
    var fName = file.name.replace(/\.[^/.]+$/, '')
    var fNameX = fName.length<15 ? fName : fName.substr(0, 10) + '... '
    var fType = file.name.split('.').pop().toLocaleLowerCase()
    var fileTypeImg = setType(fType)
    var target = objectArr[i].components[ic].components[icc]
    const elmntX = document.getElementById(key + 'upload')
    let numb = elmntX.children.length;
    const newDiv = fileElm('file', fileTypeImg, fileTypeImg, fNameX, fType, dateN, target)
    if(elmntX) elmntX.insertBefore(newDiv, elmntX.childNodes[numb-1]);

    target.options.push(file)
    target.value.push({ id: dateN, name: fName, type: fType })
  }
}

const pixChangeHandler = (e, key, objectArr, i, ic, icc) => {
  const file = e.target.files[0]
  if(file) {
    const date = new Date();
    const dateN = date.getTime().toString();

    var fName = file.name.replace(/\.[^/.]+$/, '')
    var fType = file.name.split('.').pop()

    var szx=1000, selectedFileB, formatErr, target
    target = objectArr[i].components[ic].components[icc]

    pixHandler(e, szx).then(res => {
      if(res) {
        var a = res.file.size
        var b = res.fileResized.size
        // imageArray.push(res.base64)
        const elmntX = document.getElementById(key + 'upload')
        let numb = elmntX.children.length;
        const newDiv = fileElm('image', res.base64, res.base64, fName, fType, dateN, target)
        if(elmntX) elmntX.insertBefore(newDiv, elmntX.childNodes[numb-1]);
        // mapImg(imageArray)
        selectedFileB = a > b ? res.fileResized : res.file
        target.options.push(selectedFileB)
        target.value.push({id: dateN, name: fName})
      } else {
        formatErr = 'formatErr.formatErr.formatErr.formatErr'
      }
    })
  }
}

const imgMap = (itemArr, id) => {
  var imgList = itemArr.map(
    (item, i) => (
      // console.log('item: ', item),
      <img key={i}
        style={{objectFit:'cover', width:'25px', height:'25px', borderRadius:'2px', margin:'1px 10px 1px 1px', border:'', padding:'0px', cursor:'pointer'}}
        src={`${pixAddress}${item.id}.jpeg`}
        onClick={() => window.open(`${pixAddress}${item.id}.jpeg`)}
      />
    )
  )
  return imgList
}

const fileMap = (itemArr, id) => {
  var list = itemArr.map(
    (item, i) => (
      // console.log(444, item),
      <divx key={i} className='' style={{margin:'0px 10px 5px 0px', cursor:'pointer'}}
        onClick={() => window.open(`${fileAddress}${item.id}.${item.type}`)}
      >
        <img
          style={{objectFit: 'cover', width:'25px', height:'25px', borderRadius:'2px', margin:'1px 10px 1px 1px', border:'', padding:'0px'}}
          src={setType(item.type)}
        />
        {shortName(item.name)}
      </divx>
    )
  )
  return list
}

const fileElm = (type, source, img, fName, fType, dateN, target) => {

  const divElement = document.createElement('div');
  divElement.id = dateN;
  divElement.className = 'd-flex div-element option-parent';

  const imgElement = document.createElement('img');
  imgElement.className = 'img-element';

  imgElement.src = img
  if(type==='file') {
    imgElement.style.padding = '3px';
  }
  if(type==='image') {
    imgElement.style.cursor = 'pointer';
  }

  // modal
  var popupWindow = document.createElement("div");
  popupWindow.id = "myModal";
  popupWindow.className = "pix-modal";
  
  var closeSpan = document.createElement("span");
  closeSpan.className = "close";
  closeSpan.innerHTML = "&times;";
  closeSpan.addEventListener(
    'click',
    (e) => {
      // e.preventDefault();
      popupWindow.style.display = "none";
    }
  );

  var downloadLink = document.createElement("a");
  downloadLink.href = source;
  downloadLink.className = "download";
  
  var downloadIcon = document.createElement("i");
  if(type==='image') {
    downloadIcon.className = "fa fa-download";
  } else {
    downloadIcon.className = "fa fa-download download-icon hover";
  }
  
  downloadLink.appendChild(downloadIcon);

  downloadIcon.addEventListener("click", (e) => {
    e.preventDefault();
    window.open(downloadLink.href);
  });

  var imgElementX = document.createElement("img");
  imgElementX.className = "modal-content";
  imgElementX.id = "img01";
  imgElementX.src = img
  imgElementX.alt = 'item xxx'
  
  var captionDiv = document.createElement("div");
  captionDiv.id = "caption";
  captionDiv.innerHTML = fName + '.' + fType;

  popupWindow.appendChild(closeSpan);
  popupWindow.appendChild(downloadLink);
  popupWindow.appendChild(imgElementX);
  popupWindow.appendChild(captionDiv);

  if(type==='image') {
    imgElement.addEventListener(
      'click',
      (e) => {
        e.preventDefault();
        popupWindow.style.display = "block";
        document.body.appendChild(popupWindow);
      }
    );
  }

  const nameElement = document.createElement('div');
  nameElement.className = 'd-flex name-element';
  nameElement.style.borderLeft = type==='image' ? '1px solid #99999999' : '';
  nameElement.textContent = shortName(fName) + '.' + fType;
  
  const onSecElement = document.createElement('div');
  onSecElement.className = 'center sec-element option-child';
  onSecElement.style.width = type==='image' ? '40px' : '70px';

  const trashIcon = document.createElement("i");
  trashIcon.className = 'fa fa-trash trash-icon hover';

  trashIcon.addEventListener(
    'click',
    () => {
      removeElm(dateN)//divElement.id
      var options = target.options
      var value = target.value
      var deletedItems = target.deletedItems
      for(let x=0; x<value.length; x++) {
        if(value[x].id===dateN) {
          deletedItems.push(value[x])
          value.splice(x, 1)
          options.splice(x, 1)
        }
      }
    }
  );

  if(type==='file') onSecElement.appendChild(downloadIcon);

  onSecElement.appendChild(trashIcon);

  divElement.appendChild(imgElement);
  divElement.appendChild(nameElement);
  divElement.appendChild(onSecElement);

  return divElement;
}

const pixHandler = async (e, zx) => {
  if(checkMimeType(e)){
    const file = e.target.files[0];
    const blob = await resizeFile(file, "jpeg", "blob")
    const base64 = await resizeFile(file, "jpeg", "base64") // file.type.split("/")[1]
    const fileResized = new File([blob], 'image.jpeg', { type: blob.type });
    return { file, fileResized, base64 }
  }

  async function resizeFile(file, type, format){
    var result = ''
    await new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        zx,
        zx,
        type,
        100,
        0,
        (uri) => {
          result = uri
          resolve(uri);
        },
        format,
        null,
        null,
        zx,
        zx
      );
    });
    return result
  }

  function checkMimeType(e){
    let files = e.target.files
    let err = ''
    const types = ['image/jpeg' , 'image/png', 'image/gif'] //
    for(var x = 0; x<files.length; x++) {
      if (types.every(type => files[x].type !== type)) {
        err += files[x].type+' is not a supported format\n';
      }
    };

   if (err !== '') {
      e.target.value = null
      console.log(err)
      return false;
    }
   return true;
  }

}

const fileSave = async (selectedFile, Info, fileName, username, destX, dispatch) => {
  const data = new FormData()
  data.append('file', selectedFile, Info) // use "@" instead of "/" in address
  await dispatch(saveFile(data))
  .then(async res => {
    var newNameX = res.payload.filename
    var xArr = newNameX.split('-')
    xArr.shift()

    if(fileName!==xArr[0]) {
      let parts = fileName.split('.');
      let extension = parts[parts.length - 1];

      const oldName = newNameX
      const newName = newNameX+'.'+extension//username + '-' + fileName
      var dataX = {dest: destX, oldName, newName}

      await dispatch(renameFile(dataX))
      .then(res => {})
    }

  })
}

const pixSave = async (selectedFile, Info, dispatch) => {
  const data = new FormData()
  data.append('file', selectedFile, Info)
  await dispatch(savePix(data))
  .then(res => {})
}

const fileDelete = async (data, dispatch) => {
  await dispatch(deleteFile(data))
  .then(res => {})
}

const removeElm = (id) => {
  const element = document.getElementById(id);
  if (element) element.remove();
}

const exist = (item) => {
  var result = item==='' || item===undefined || item===null ? false: true
  return result
}

const setEdit = (key, type) => {
  const elmnt = document.getElementById(key)
  const elmntAtag = document.getElementById(key + 'atag')
  const elmntEdit = document.getElementById(key + 'edit')
  const elmntLink = document.getElementById(key + 'link')
  var vx = setHttp(elmnt.value)
  if(type==='edit') {
    elmntLink.style.display = 'none'
    elmntEdit.style.display = 'block'
    elmnt.value = vx
  } else {
    elmntLink.style.display = 'block'
    elmntEdit.style.display = 'none'
    elmntAtag.textContent = vx
    elmntAtag.href = vx
  }
}

const setValue = async (objectArr) => {
  const setCheck = (key, type, value) => {
    if(type==='checkbox') {
      const elmntX = document.getElementById(key)
      if(elmntX) elmntX.checked = value
    }
  }

  objectArr.map(
    (item) => (
      item.components.map(
        (itemL2) => (
          itemL2.components.map(
            (itemL3) => (
              setCheck(itemL3.key, itemL3.type, itemL3.value)
            )
          )
        )
      )
    )
  )

}

const pickListOptionClick = (e, key, objectArr, i, ic, icc, txt) => {
  var vx, options, target
  vx = e.target.value
  target =  objectArr[i].components[ic].components[icc]
  options = target.options
  for(let x=0; x<options.length; x++) {
    if(options[x].defaultValue) {
      options[x].defaultValue = undefined
    }
  }
  
  const elmntX = document.getElementById(key + 'dropdown')
  if(elmntX) {
    if(txt>=0) {
      elmntX.innerHTML = options[txt].label
      options[txt].defaultValue = true
    } else {
      elmntX.innerHTML = '-None-'
      target.optionId = undefined
    }
  }

}

const setMultiSelect = (multiSelectArr) => {
  for(let i=0; i<multiSelectArr.length; i++) {
    var target = multiSelectArr[i]
    var targetKey = target.key
    var optionsArr = target.options
    var valueArr = target.value
    if(valueArr) {
      for(let x=0; x<valueArr.length; x++) {
        for(let a=0; a<optionsArr.length; a++) {
          if(optionsArr[a].key===valueArr[x]) {
            multiSelectOptionClick(targetKey, optionsArr[a], a)
          }
        }
      }
    } else {
      for(let p=0; p<optionsArr.length; p++) {
        if(optionsArr[p].defaultValue) multiSelectOptionClick(targetKey, optionsArr[p], p)
      }
    }
  }
}

const shortName = (name) => {
  return name.length<15 ? name : name.substr(0, 10) + '... '
}

const setImageUpload = (imageUploadArr, id) => {
  for(let x=0; x<imageUploadArr.length; x++) {
    const { elm, objectArr, i, ic, icc } = imageUploadArr[x]
    var target = objectArr[i].components[ic].components[icc]
    const value = elm.value
    target.options = []
    var options = target.options
    for(let a=0; a<value.length; a++) {
      options.push(a)
      const item = value[a]
      const elmntX = document.getElementById(elm.key + 'upload')
      let numb = elmntX.children.length
      const source = `${pixAddress}${item.id}.jpeg`
      // const source = `https://www.pix.whoraly.com/whoraly/profile/big/6556359fa748de41c85a6b3d-1700150006177.jpeg`
      const newDiv = fileElm('image', source, source, item.name, 'jpeg', item.id, target)
      if(elmntX) elmntX.insertBefore(newDiv, elmntX.childNodes[numb-1])
    }
  }
}

const setFileUpload = (fileUploadArr, id) => {
  for(let x=0; x<fileUploadArr.length; x++) {
    const { elm, objectArr, i, ic, icc } = fileUploadArr[x]
    var target = objectArr[i].components[ic].components[icc]
    const value = elm.value
    target.options = []
    var options = target.options
    for(let a=0; a<value.length; a++) {
      options.push(a)
      const item = value[a]
      const elmntX = document.getElementById(elm.key + 'upload')
      let numb = elmntX.children.length;
      const source = `${fileAddress}${item.name}.${item.type}`
      // const source = `https://www.pix.whoraly.com/whoraly/profile/big/6556359fa748de41c85a6b3d-1700150006177.jpeg`
      var fileTypeImg = setType(item.type)

      const newDiv = fileElm('file', source, fileTypeImg, item.name, item.type, item.id, target)
      if(elmntX) elmntX.insertBefore(newDiv, elmntX.childNodes[numb-1]);
    }
  }
}

const handleMultiSelectBtnClick = async (isOpen, key, objectArr, i, ic, icc, optionArr) => {
  if (isOpen) {
    setTimeout(async() => {
      for(let x=0; x<optionArr.length; x++) {
        const targetKey = optionArr[x].key
        const selected = document.getElementById(targetKey + '-selected')
        const option = document.getElementById(targetKey)
        if(selected) {
          option.style.display = 'none'
        } else {
          option.style.display = ''
        }
      }
    }, 50);

  }

}

const multiSelectOptionClick = (key, itemX, iX, objectArr, i, ic, icc) => {
  const elmntX = document.getElementById(key + 'multiSelect')
  if(elmntX) {
    let numb = elmntX.children.length;
  
    const newDiv = document.createElement('div');
    newDiv.id = itemX.key + '-selected'
  
    newDiv.classList.add('selected-option');
    newDiv.classList.add('disable-select');
  
    const labelDiv = document.createElement('div');
    labelDiv.style.margin = '0px 5px';
    labelDiv.textContent = itemX.label;
  
    const removeDiv = document.createElement('div');
    removeDiv.classList.add('option-remove');
    const span = document.createElement('span');
    span.style.marginTop = '-2px';
    span.textContent = '×';
    removeDiv.appendChild(span);
    removeDiv.addEventListener(
      'click',
      () => {
        removeElm(newDiv.id)
        const optionX = document.getElementById(key)
        if(optionX) optionX.style.display = '';
      }
    );
  
    newDiv.appendChild(labelDiv);
    newDiv.appendChild(removeDiv);
  
    if(elmntX) elmntX.insertBefore(newDiv, elmntX.childNodes[numb-1]);
  
    const option = document.getElementById(itemX.key)
    if(option) option.style.display = 'none';
  }
}

const getPattern = (moduleArr) => {
  var formData = [], multiSelectOptions = []
  // console.log('moduleArr',moduleArr)
  const arr = JSON.parse(JSON.stringify(moduleArr))
  // setModuleArr(arr)///////////
  for(let i=0; i<arr.length; i++) {
    const arr2 = arr[i].components
    for(let i2=0; i2<arr2.length; i2++) {
    const arr3 = arr2[i2].components
    for(let i3=0; i3<arr3.length; i3++) {
      var field = arr3[i3]
      if(['pickList', 'multiSelect'].includes(field.type)) {
      var options = field.options
      if(field.type==='pickList') {
        for(let x=0; x<options.length; x++) {
        if(options[x].defaultValue) {
          field.value = options[x].label
        } else {
          field.value = undefined
        }
        }
      } else if(field.type==='multiSelect') {
        multiSelectOptions.push({
        key: field.key,
        options: field.options
        })
        // setMultiSelectOptions(multiSelectOptions)/////////////
      }
      }
      field.components = undefined
      formData.push(field)
    }
    }
  }
  // console.log('formData',formData)
  return { formData, arr, multiSelectOptions }
}

const changeFormData = async (e, key, objectArr, i, ic, icc, dispatch) => {
  const elmntX = document.getElementById(key)
  var vx, limit, field, prefv
  field = objectArr[i].components[ic].components[icc]
  prefv = field.value

  if(field.type==='number') {
    limit = field.name==='Number' ? 9 : 16
    var inputNumber = parseFloat(e.target.value);
    var stringRepresentation = inputNumber.toString();
    var limitedString = stringRepresentation.slice(0, limit);
    var roundedNumber = parseFloat(limitedString);
    vx = roundedNumber;

    field.value = isNaN(vx) ? undefined : vx
    const elmntX = document.getElementById(key)
    if(elmntX) {
      elmntX.value = vx
    }
  } else if(field.type==='percent') {
    const char = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
    var str = e.target.value
    for(let x=0; x<str.length; x++) {
      if(!char.includes(str[x])) {
        str = str.replace(str[x], '')
      }
    }
    const dotCount = (str.match(/\./g) || []).length;
    var fx = e.target.selectionStart
    var dot = str.substring(fx-1, fx)==='.' ? true : false

    if(dotCount<=1) {
      limit = dotCount===0 ? 5 : 6
      vx = str.slice(0, limit)
    } else {
      if(!dot) {
        limit = str.length-1
        vx = str.slice(0, limit)
      } else {
        vx = str.replace('.', '')
      }
    }

    var vl = vx.length
    if(vl>=6 && str.substr(-1)==='.') vx = str.replaceAll('.', '')
    field.value = parseFloat(vx)
    const elmntX = document.getElementById(key)
    if(elmntX) {
      elmntX.value = vx
    }
  } else if(field.type==='checkbox') {
    vx = e.target.checked
    field.value = vx
    const elmntX = document.getElementById(key)
    if(elmntX) {
      elmntX.checked = vx
    }
    previewArrMap(objectArr, true)
  } else if(field.type==='currency') {
    const char = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',']
    fx = e.target.selectionStart
    vx = e.target.value
    for(let x=0; x<vx.length; x++) {
      if(!char.includes(vx[x])) {
        vx = vx.replace(vx[x], '')
      }
    }
    if(vx.charAt(0) === '.') {
      vx = '0' + vx
      fx++
    }
    var net = vx.replace(/,/g, '')
    var lx = net.length
    limit = 9
    var n1 = net.split('.')[0]
    n1 = n1.replace(/,/g, '').slice(0, limit).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    const elmntX = document.getElementById(key)
    if(elmntX) {
      var newVl = vx.replace(/,/g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      var dotCount = (newVl.match(/\./g) || []).length
      if(dotCount>=1) {
        if(dotCount>1) newVl = newVl.replace(/(\..*?)\./g, "$1")
        var s2 = newVl.split('.')[1]
        if(s2) s2 = s2.replace(/,/g, '').substr(0, 2)
        newVl = `${n1}.${s2}`
        if(dotCount===2) fx--
      } else {
        newVl = `${n1}`
      }
      if(lx%3===1) fx = fx + 1
      elmntX.value = newVl
      elmntX.setSelectionRange(fx, fx)
      field.value = Number(newVl.replace(/,/g, ''))
    }
  } else if(field.type==='phone') {
    vx = e.target.value
    limit = 11
    if(vx.length<=limit) {
      const char = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      for(let x=0; x<vx.length; x++) {
        if(!char.includes(vx[x])) {
          vx = vx.replace(vx[x], '')
        }
      }
      vx = vx.slice(0, limit)
      field.value = isNaN(vx) ? undefined : vx
      const elmntX = document.getElementById(key)
      if(elmntX) elmntX.value = vx
    } else {
      if(elmntX) elmntX.value = prefv
    }
  } else if(field.type==='url') {
    field.value = setHttp(e.target.value)
  } else if(field.type==='lookup') {
    vx = e.target.value ? e.target.value : ''
    field.value = vx
    field.formDataId = e.target.formDataId
    const elmntX = document.getElementById(key)
    if(elmntX) elmntX.value = vx
  } else if(field.type==='user') {
    // console.log(99, e.target.value)

    let idx = e.target.id
    if(idx==='') {
      vx = ''
      // console.log(1, vx)
    } else {
      await dispatch(getUserInfo({ id: idx })).then(async res => {
        var name = res.payload.name
        if(name===undefined) {
          vx = e.target.value
          userInputDropDownOpen(key, objectArr, i, ic, icc, dispatch)
        } else {
          vx = res.payload.name
        }
        // console.log(2, vx)
      })
    }
    field.value = vx
    const elmntX = document.getElementById(key)
    if(elmntX) elmntX.value = vx
  } else {
    field.value = e.target.value
  }
};

const fieldValue = (field, pattern) => {
  var vx
  var value = field.value
  var type = field.type
  if(value) {
    switch (type) {
    case 'password':
      vx = '●'.repeat(value.length)
      break;
    case 'checkbox':
      vx = value===true ? 'Yes' : 'No'
      break;
    case 'currency':
      vx = seperator(value)
      break;
    case 'percent':
      vx = value + '%'
      break;
    case 'pickList':
      const optionId = field.optionId
      const key = field.key
      const formData = pattern.formData
      for(let i=0; i<formData.length; i++) {
        if(key===formData[i].key) {
          const options = formData[i].options
          vx = options[optionId].label
        }
      }
      break;
    case 'multiSelect':
      var options = pattern.multiSelectOptions[0].options
      var targetValue = field.value
      for(let i=0; i<targetValue.length; i++) {
        for(let x=0; x<options.length; x++) {
          if(options[x].key===targetValue[i]) targetValue[i] = options[x].label
        }
      }
      vx = targetValue.toString()
      break;
    case 'imageUpload':
      vx = imgMap(value)
      break;
    case 'fileUpload':
      vx = <div className='d-flex' style={{flexDirection:'column'}}>{fileMap(value)}</div>
      break;
    case 'email':
      vx = <Link target='_blank' to={`mailto:${value}`}>{value}</Link>
      break;
    case 'url':
      vx = <Link target='_blank' to={value}>{value}</Link>
      break;
    // case 'user':
    //   vx = <Link target='_blank' to={value}>{value}</Link>
    //   break;
    default: vx = value
    }
    return vx
  } else {
    return ''
  }
}

const setData = async (preData, pattern) => {
  var data = JSON.parse(JSON.stringify(preData))
  for(let i=0; i<data.length; i++) {
    var moduleData = data[i].moduleData
    for(const [key, value] of Object.entries(moduleData)) {
      var vx = key==='0'
      ? <Link to="/crm/module-record-detail"><b>{value.value}</b></Link>
      : fieldValue(value, pattern)
      data[i][`${value.key}`] = vx
    }
  }
  return data
}

const setHttp = (vx) => {
  if(vx) {
    vx = vx.toString().toLowerCase()
    if(vx.substr(0, 4)!=='http') {
      vx = 'https://' + vx
    }
  }
  return vx
}

const seperator = (value) => {
  value = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  var [s1, s2] = value.split('.')
  if(s2) {
    if(s2.length===1) s2 = s2 + '0'
  } else {
    s2 = '00'
  }
  return `${s1}.${s2}`
}

const elmObj = (item, objectArr, i, ic, icc, dispatch) => {
  var optionDefaultValue = false, optionArr=[], selectedId, target
  const { key, type, value, required, formId, fieldId } = item
  const inputProps = {
    id: key,
    style: { borderLeft: required ? '2px solid red' : '' },
    size: "sm",
    onChange: (e) => changeFormData(e, key, objectArr, i, ic, icc, dispatch)
  };

  if(type==='checkbox') {
    inputProps.style = { borderLeft: required ? '2px solid red' : '', marginTop: type==='checkbox' ? '6px' : '', width: type==='checkbox' ? '30px' : '', height: type==='checkbox' ? '30px' : '' }
  } else if(type==='number'){
    inputProps.defaultValue = isNaN(value) ? undefined : value
    inputProps.style = { borderLeft: required ? '2px solid red' : '' }
  } else if(type==='currency'){
    inputProps.defaultValue = value ? seperator(value) : ''
  } else if(type==='pickList'){
    optionArr = objectArr[i].components[ic].components[icc].options
    for(let x=0; x<optionArr.length; x++) {
      if(optionArr[x].defaultValue) optionDefaultValue = optionArr[x].label
    }
  } else if(type==='multiSelect'){
    target = objectArr[i].components[ic].components[icc]
    optionArr = target.options
  } else if(type==='lookup'){
    inputProps.style = { borderLeft: required ? '2px solid red' : '', padding:'4px 45px 4px 8px' }
    inputProps.defaultValue = value
  } else {
    inputProps.defaultValue = value
    inputProps.style = { borderLeft: required ? '2px solid red' : '' }
  }

  const inputElements = {
    singleLine: <Form.Control type="text" {...inputProps} />,
    multiLine: <Form.Control type="text" as="textarea" rows={3} {...inputProps} />,
    number: <Form.Control type="number" {...inputProps} />,
    email: <Form.Control type="email" {...inputProps} />,
    password: <Form.Control type="password" {...inputProps} />,
    checkbox: <Form.Check type="checkbox" {...inputProps} />,
    longInteger: <Form.Control type="number" {...inputProps} />,
    date: <Form.Control type="date" {...inputProps} />,
    datetime: <Form.Control type="datetime-local" {...inputProps} />,
    percent: (
      <InputGroup>
        <Form.Control type="text" {...inputProps} />
        <InputGroup.Text style={{padding:'2px 12px'}}>%</InputGroup.Text>
      </InputGroup>
    ),
    currency: (
      <InputGroup>
        <InputGroup.Text style={{padding:'2px 12px'}}>£</InputGroup.Text>
        <Form.Control type="text" {...inputProps} />
      </InputGroup>
    ),
    phone: <Form.Control type="text" {...inputProps} />,
    url: (
      <div style={{ position: 'relative' }}>
        <div id={key + 'edit'} className='' style={{ position: 'relative', display: exist(value) ? 'none' : '' }}>
          <Form.Control type="text" {...inputProps} style={{  }} />
          <BsFillCheckCircleFill color="green" className='hover' onClick={() => setEdit(key, 'link')}
            style={{ width: '20px', fontSize: '20px', position: 'absolute', right: 5, top: 5, zIndex:'10000' }} />
        </div>
        <div id={key + 'link'} style={{ position: 'relative', marginTop:'5px', display: exist(value) ? '' : 'none' }}>
          <a id={key + 'atag'} href={setHttp(value)} target="_blank" style={{cursor:'pointer'}}>{value}</a>
          <BiMessageSquareEdit className='hover' onClick={() => setEdit(key, 'edit')}
            style={{ width: '22px', fontSize: '22px', position: 'absolute', right: 5, top: 0 }}
          />
        </div>
      </div>
    ),
    pickList: (
      <DropdownButton
        id = {key + 'dropdown'}
        as={ButtonGroup}
        size='sm'
        style={{width:'100%', height:'30px', border:'1px solid #aaaaaa', borderRadius:'4px'}}
        variant=''
        title={optionDefaultValue ? optionDefaultValue : '-None-'}
      >
        <Dropdown.Item eventKey={0} active={false} onClick={(e) => pickListOptionClick(e, key, objectArr, i, ic, icc)}>-None-</Dropdown.Item>
        { optionArr.length>0 &&
          optionArr.map(
            (itemX, iX) => (
              <Dropdown.Item key={iX} eventKey={iX} active={itemX.defaultValue} onClick={(e) => pickListOptionClick(e, key, objectArr, i, ic, icc, iX)}>{itemX.label}</Dropdown.Item>
            )
          )
        }
      </DropdownButton>
    ),
    multiSelect: (
      <div id={key + 'multiSelect'} className='d-flex option-parent' style={{width:'100%', minHeight:'30px', padding:'1px', border:'1px solid #aaaaaa', borderRadius:'4px', flexWrap:'wrap'}}>
        <DropdownButton
          autoClose="outside"
          id = {key + 'dropdown'}
          as={ButtonGroup}
          size='sm'
          style={{width:'80px', height:'25px', border:'0px solid #aaaaaa', borderRadius:'4px'}}
          variant=''
          title='Select'
          className="multi-select option-child"
          onToggle={(e) => handleMultiSelectBtnClick(e, key, objectArr, i, ic, icc, optionArr)}
        >
          { optionArr.length>0 &&
            optionArr.map(
              (itemX, iX) => (
                <Dropdown.Item key={iX} id={itemX.key} eventKey={iX}
                  className='disable-select'
                  style={{display: itemX.defaultValue ? 'none' : ''}}
                  onClick={() => multiSelectOptionClick(key, itemX, iX, objectArr, i, ic, icc)}
                >
                  {itemX.label}
                </Dropdown.Item>
              )
            )
          }
        </DropdownButton>
      </div>
    ),
    imageUpload: (
      <div id={key + 'upload'} className='d-flex' style={{width:'100%', minHeight:'30px', padding:'5px', border:'1px solid #aaaaaa', borderRadius:'4px', flexWrap:'wrap'}}>
        <span className="btn btn-file center btnShadowX disable-select"
          style={{color:'#338CF0', height:'34px', width:'125px', border:'1px solid #338CF0', backgroundColor: '#EDF6FF', borderRadius:'5px', fontSize:'14px', fontWeight:400, alignItems:'center', margin:'3px', padding:'0px'}}>
          + New Image<input type="file" name="file" onChange={(e) => pixChangeHandler(e, key, objectArr, i, ic, icc)}/>
        </span>
      </div>
    ),
    fileUpload: (
      <div id={key + 'upload'} className='d-flex' style={{width:'100%', minHeight:'30px', padding:'5px', border:'1px solid #aaaaaa', borderRadius:'4px', flexWrap:'wrap'}}>
        <span className="btn btn-file center btnShadowX disable-select"
          style={{color:'#338CF0', height:'34px', width:'125px', border:'1px solid #338CF0', backgroundColor: '#EDF6FF', borderRadius:'5px', fontSize:'14px', fontWeight:400, alignItems:'center', margin:'3px', padding:'0px'}}>
          + New File &nbsp;
          <FiPaperclip/>
          <input type="file" name="file" onChange={(e) => fileChangeHandler(e, key, objectArr, i, ic, icc)}/>
        </span>
      </div>
    ),
    lookup: (
      <InputGroup className='option-parent'>
        <Form.Control type="text" id={key} {...inputProps} autoComplete="off" onFocus={() => lookupInputDropDownOpen(key, formId, fieldId, objectArr, i, ic, icc, dispatch)} onBlur={() => lookUpInputDropDownClose(`lookupModal-${key}`)}/>
        <div id={`lookupModal-${key}`} className='popupContainer animated animatedFadeInUp fadeInUp' style={{}}>
          <div id={`listModal-${key}`} className='' style={{}}></div>
          {/* <div className='btnShadow' style={{padding:'6px 10px', width:'100%', color:'#1476FE', backgroundColor:'#F2F7FB'}}>New</div> */}
        </div>
        <div id='lookupTooltip' className='popupTooltip fadeIn' style={{}}></div>
        <div className='close-cross option-child'
          style={{fontSize:'20px', position:'absolute', right:50, top:10, lineHeight:'10px'}}
          onClick={(e)=>onLookupDelete(e, key, objectArr, i, ic, icc, dispatch)}>×</div>
        <InputGroup.Text style={{padding:'2px 12px'}}><TbTargetArrow/></InputGroup.Text>
      </InputGroup>
    ),
    user: (
      <InputGroup className='option-parent'>
        <Form.Control type="text" id={key} {...inputProps} autoComplete="off" onFocus={() => userInputDropDownOpen(key, objectArr, i, ic, icc, dispatch)} onBlur={() => userInputDropDownClose(`userModal-${key}`)}/>
        <div id={`userModal-${key}`} className='popupContainer animated animatedFadeInUp fadeInUp' style={{}}>
          <div id={`listModal-${key}`}></div>
        </div>
        <div id='userTooltip' className='popupTooltip fadeIn' style={{}}></div>
        <div className='close-cross option-child'
          style={{fontSize:'20px', position:'absolute', right:50, top:10, lineHeight:'10px'}}
          onClick={(e)=>onUserDelete(e, key, objectArr, i, ic, icc, dispatch)}>×</div>
        <InputGroup.Text style={{padding:'2px 12px'}}><TbTargetArrow/></InputGroup.Text>
      </InputGroup>
    ),
  };
  const obj = inputElements[type] || null;
  return obj;
};

const lookupInputDropDownOpen = async (key, formId, fieldId, objectArr, i, ic, icc, dispatch) => {
  // const elmInput = document.getElementById(key);
  // if(elmInput) inputDropDownClose('lookupModal')

  var dataList=[]
  var values=[]
  await dispatch(getLeads(formId))
  .then(async res => {
    // var arr = res.payload
    var arr = JSON.parse(JSON.stringify(res.payload))

    for(let i=0; i<arr.length; i++) {
      var formDataId = arr[i]._id
      var arr2 = arr[i].moduleData
      arr2.sort((a, b) => a.order - b.order)
      for(let i2=0; i2<arr2.length; i2++) {
        var arr3 = arr2[i2]
        if(arr3.key===fieldId && !values.includes(arr3.value)) {
          values.push(arr3.value)
          if(arr3.key===fieldId) dataList.push({ data: arr2, formDataId, key: arr3.key, value: arr3.value })
        }
      }
    }

    dataList.sort((a, b) => {
      const valueA = typeof a.value === 'number' ? a.value : parseFloat(a.value);
      const valueB = typeof b.value === 'number' ? b.value : parseFloat(b.value);

      if(isNaN(valueA) && isNaN(valueB)) {
        return a.value.localeCompare(b.value);
      }

      return valueA - valueB;
    });

    const elmInput = document.getElementById(key);
    const rect = elmInput.getBoundingClientRect();
    // const elmInputBottem = rect.bottom
    // const elmInputLeft = rect.left
    // const elmInputWidth = rect.width
    const elm = document.getElementById(`lookupModal-${key}`);
    elm.style.display = "block";
    elm.style.borderRadius = "5px";
    elm.style.position = 'fixed';
    elm.style.overflow = 'hidden';
    elm.style.top = `${rect.bottom+5}px`;
    elm.style.left = `${rect.left+1}px`;
    elm.style.width = `${rect.width}px`;

    const elmList = document.getElementById(`listModal-${key}`);
    elmList.style.position = 'relative';
    elmList.innerHTML = '';
    elmList.style.maxHeight = '300px';
    elmList.style.overflow = 'scroll';

    for(let ix=0; ix<dataList.length; ix++) {
      const item = document.createElement('div');
      item.id = 'item';
      item.className = 'select-item';
      item.textContent = dataList[ix].value;
      item.addEventListener(
        'click',
        (e) => {
          e.target.formDataId = dataList[ix].formDataId
          e.target.value = dataList[ix].value
          changeFormData(e, key, objectArr, i, ic, icc, dispatch)
          // e.preventDefault();
          elmInput.value = dataList[ix].value
          elm.style.display = "none";
          // popupWindow.style.display = "none";
        }
      );

      const infoIcon = document.createElement("i");
      infoIcon.className = "fa fa-info-circle hover"
      infoIcon.style.position = 'absolute';
      infoIcon.style.marginTop = '10px';
      infoIcon.style.right = '10px';

      const tooltip = document.getElementById('lookupTooltip');

      infoIcon.addEventListener(
        'mousemove',
        (e) => {
          const infoIconRect = infoIcon.getBoundingClientRect();
          tooltip.innerHTML = '';

          tooltip.style.display = "block";
          tooltip.style.borderRadius = "5px";
          tooltip.style.position = 'fixed';
          tooltip.style.overflow = 'scroll';
          tooltip.style.padding = '10px';
          tooltip.style.top = `${infoIconRect.bottom + 5}px`;
          tooltip.style.left = `${infoIconRect.left - 95}px`;
          tooltip.style.width = `200px`;
          tooltip.style.maxHeight = `310px`;

          tooltip.addEventListener(
            'click',
            (e) => {
              var x = e.clientX;
              var y = e.clientY;
              var elmnt = document.getElementById(key);
              elmnt.outline = 'none';
            }
          );
          const info = dataList[ix].data
          for(let x=0; x<info.length; x++) {
            const divX = document.createElement('div');
            divX.innerHTML = `${info[x].label}: ${info[x].value ? info[x].value : '-'}`;
            divX.style.marginBottom = `5px`;
            tooltip.appendChild(divX);
          }

        }
      );

      infoIcon.addEventListener(
        'mouseout',
        (e) => {
          tooltip.style.display = "none";

          var x = e.clientX;
          var y = e.clientY;
          const tooltipRect = tooltip.getBoundingClientRect();
          if(y<390) {
            tooltip.style.display = "none";
          }
        }
      );

      elmList.appendChild(infoIcon);
      elmList.appendChild(item);
    }


  })
}

const lookUpInputDropDownClose = (id) => {
  const elm = document.getElementById(id);
  elm.outline = 'none';

  setTimeout(() => {
    const tooltip = document.getElementById('lookupTooltip');
    elm.style.display = "none";
    tooltip.style.display = "none";
  }, 200);
}

const userInputDropDownOpen = async (key, objectArr, i, ic, icc, dispatch) => {
  // const elmInput = document.getElementById(key);
  // if(elmInput) inputDropDownClose('lookupModal')
  const elmInput = document.getElementById(key);

  const target = objectArr[i].components[ic].components[icc]

  const userData = {
    userType: target.userType===1 ? 'student' : 'staff',
    filter: elmInput.value
  }
  await dispatch(getUserType(userData))
  .then(async res => {
    // var arr = res.payload
    var dataList = JSON.parse(JSON.stringify(res.payload))
    dataList.sort((a, b) => a.name - b.name)

    const rect = elmInput.getBoundingClientRect();
    // const elmInputBottem = rect.bottom
    // const elmInputLeft = rect.left
    // const elmInputWidth = rect.width
    const elm = document.getElementById(`userModal-${key}`);
    elm.style.display = "block";
    elm.style.borderRadius = "5px";
    elm.style.position = 'fixed';
    elm.style.overflow = 'hidden';
    elm.style.top = `${rect.bottom+5}px`;
    elm.style.left = `${rect.left+1}px`;
    elm.style.width = `${rect.width}px`;

    const elmList = document.getElementById(`listModal-${key}`);
    elmList.style.position = 'relative';
    elmList.innerHTML = '';
    elmList.style.maxHeight = '300px';
    elmList.style.overflow = 'scroll';

    for(let ix=0; ix<dataList.length; ix++) {
      let pressing = true

      const item = document.createElement('div');
      item.id = 'item';
      item.className = 'select-item';
      var content = (
        <div className='select-item'>
          <span>{dataList[ix].name}</span>
          <span>{dataList[ix].email}</span>
        </div>
      )

      const nameSpan = document.createElement('span');
      nameSpan.textContent = dataList[ix].name;

      const emailSpan = document.createElement('span');
      emailSpan.textContent = dataList[ix].email;

      item.appendChild(nameSpan);
      item.appendChild(emailSpan);
      
      item.addEventListener(
        'click',
        (e) => {
          console.log(dataList[ix])
          e.target.formDataId = dataList[ix].formDataId
          e.target.value = dataList[ix].value
          e.target.id = dataList[ix]._id
          changeFormData(e, key, objectArr, i, ic, icc, dispatch)
          // e.preventDefault();
          elmInput.value = dataList[ix].name
          elm.style.display = "none";
          // popupWindow.style.display = "none";
        }
      );

      const infoIcon = document.createElement("i");
      infoIcon.className = "fa fa-info-circle hover"
      infoIcon.style.position = 'absolute';
      infoIcon.style.marginTop = '10px';
      infoIcon.style.right = '10px';

      const tooltip = document.getElementById('userTooltip');

      infoIcon.addEventListener(
        'mouseenter',
        (e) => {
          // if (pressing) {
            setTimeout(() => {
              // console.log(99)
              const infoIconRect = infoIcon.getBoundingClientRect();
              tooltip.innerHTML = '';
    
              tooltip.style.display = "block";
              tooltip.style.borderRadius = "5px";
              tooltip.style.position = 'fixed';
              tooltip.style.overflow = 'scroll';
              tooltip.style.padding = '10px';
              tooltip.style.top = `${infoIconRect.bottom + 5}px`;
              tooltip.style.left = `${infoIconRect.left - 95}px`;
              tooltip.style.width = `200px`;
              tooltip.style.maxHeight = `310px`;
              tooltip.style.zIndex = `10000`;
    
              tooltip.addEventListener(
                'click',
                (e) => {
                  var x = e.clientX;
                  var y = e.clientY;
                  var elmnt = document.getElementById(key);
                  elmnt.outline = 'none';
                }
              );
  
              const info = dataList[ix]
              for(const [key, value] of Object.entries(info)) {
                  // console.log(99, key, value)
                  const divX = document.createElement('div');
                  divX.innerHTML = `${key}: ${value ? value : '-'}`;
                  divX.style.marginBottom = `5px`;
                  tooltip.appendChild(divX);
              }
              // pressing = false
            }, 100);
          }
        // }

      );

      infoIcon.addEventListener(
        'mouseleave',
        (e) => {
          pressing = true
          tooltip.style.display = "none";

          var x = e.clientX;
          var y = e.clientY;
          const tooltipRect = tooltip.getBoundingClientRect();
          if(y<390) {
            tooltip.style.display = "none";
          }
        }
      );

      elmList.appendChild(infoIcon);
      elmList.appendChild(item);
    }

  })
}

const userInputDropDownClose = (id) => {
  const elm = document.getElementById(id);
  elm.outline = 'none';

  setTimeout(() => {
    const tooltip = document.getElementById('userTooltip');
    elm.style.display = "none";
    tooltip.style.display = "none";
  }, 200);
}

const onLookupDelete = (e, key, objectArr, i, ic, icc, dispatch) => {
  changeFormData(e, key, objectArr, i, ic, icc, dispatch)
}

const onUserDelete = (e, key, objectArr, i, ic, icc, dispatch) => {
  changeFormData(e, key, objectArr, i, ic, icc, dispatch)
}

const connectSection = async (formConnectArr, dispatch) => {
  for(let x=0; x<formConnectArr.length; x++) {
    const { _id, title, form } = formConnectArr[x]
    const moduleData = {
      id: _id,
      title,
      form,
    }

    await dispatch(updateModule(moduleData))
    .then(async res => {

    })
  }
}

const connectField = async (formsX, fCIndex, targetX, dispatch) => {
  for(const [key, value] of Object.entries(fCIndex)) {
    const sectionCol1 = formsX[Number(key)].form[value].components[0].components
    sectionCol1.push(targetX)
    const { _id, title, form } = formsX[Number(key)]
    const moduleData = {
      id: _id,
      title,
      form,
    }
    await dispatch(updateModule(moduleData))
    .then(async res => {})
  }
}

export { previewArrMap, setValue, connectSection, connectField, seperator, removeElm, exist, setType,
  multiSelectOptionClick, setMultiSelect, setImageUpload, setFileUpload, pixSave, fileDelete, fileSave,
  shortName, getPattern, setData, imgMap, fileMap, setUserType };