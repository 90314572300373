import React,{useEffect,useState,useLayoutEffect,useReducer} from 'react';
import ReactDOM from 'react-dom';
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { Link,useNavigate,useParams } from 'react-router-dom'; 
import { toast } from "react-toastify";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  selectUserProfile,
  getProfiles,
  updateProfile,
} from "../../redux/crm-features/userProfile/profileSlice";

const EditProfile = () => {
	useRedirectLoggedOutUser("/crm/login");
	
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { userProfiles, isLoading, isSuccess, message } = useSelector(
	    (state) => state.userProfile
	);

	const initialState ={
		name: userProfiles?.name || "",
	  cloneProfile: userProfiles?.cloneProfile || "",
	  desc: userProfiles?.desc || "",
	}

	// const productEdit = useSelector(selectProduct);

	useEffect(() => {
	    dispatch(getProfile(id));
	}, [dispatch, id]);

	const [formData, setFormData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const {name,cloneProfile,desc} = formData;

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setFormData({...formData, [name]: value});
	}

	const profileEdit = async (e) => {
		e.preventDefault();

		const profileData = {
			name:formData.name, 
			cloneProfile: formData.cloneProfile,  
			desc: formData.desc, 
		};
		
		await dispatch(updateProfile({id, profileData}));
		await dispatch(getProfiles());
		forceUpdate();
		navigate('/crm/security-control');
		// window.location.reload();
		// await dispatch(sendVerificationEmail());
	};

	// console.log(users);

	
	useLayoutEffect(() => {
	    if (userProfiles) {
	      setFormData({
	        ...formData,
	        name: userProfiles.name,
	        cloneProfile: userProfiles.cloneProfile,
	        desc: userProfiles.desc,
	        
	      });
	    }
	}, [userProfiles]);

	
	return (
		<div>

		{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">

			                    {/*content*/}


			                    <div className="col-lg-12">
			                    	<div className="col-12">
			                    		<div className="d-flex align-items-center justify-content-between">
			                    			<h5 className="m-0">Edit Profile</h5>
			                    			<Link to="/crm/security-control" className="v-link btn btn-secondary _effect--ripple waves-effect waves-light">Back</Link>
			                    		</div>
			                    	</div>
			                    	<div className="col-12 mt-4">
			                    		{/*<UserStats />*/}
			                    	</div>
		                            <div className="statbox widget box box-shadow">
		                                <div className="widget-content widget-content-area">
		                                	<form className="form" onSubmit={profileEdit}>
				                                <div className="row mt-4">
				                                    <div className="col-md-12">
				                                        <div className="form-group">
				                                            <input type="text" className="form-control mb-3" id="fullName" placeholder="Profile Name" name="name" value={formData?.name} onChange={handleInputChange}/>
				                                        </div>
				                                    </div>
				                                    <div className="col-md-12">
				                                    	<select value={formData?.cloneProfile} onChange={handleInputChange} name="cloneProfile" className="form-select form-control-sm mb-3">
																				     		<option>Clone Profile</option>
																				     		<option value="admin">Admin</option>
																				     		<option value="author">Author</option>
																				     		<option value="client">Client</option>
																				     		<option value="suspended">Suspended</option>
																				     	</select>
				                                    </div>                              
				                                    <div className="col-md-12">
				                                        <div className="form-group">
				                                            <textarea type="text" className="form-control mb-3" name="desc" placeholder="Profile Description" value={formData?.desc} onChange={handleInputChange}></textarea>
				                                        </div>
				                                    </div>
				                                    <div className="col-md-12 mt-1">
				                                        <div className="form-group text-end">
				                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
				                                        </div>
				                                    </div>
				                                </div>
				                            </form>
		                                </div>
		                            </div>
	                        	</div>


			                    {/*content*/}

		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		</div>
	)
}

export default EditProfile