import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";

const EmailConfig = () => {

	const [tabIndex, setTabIndex] = useState(0);
	const [tabIndexSec, setTabIndexSec] = useState(0);
	
	useRedirectLoggedOutUser("/crm/login");

	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">
		                        
		                    <div className="custom_tabs">
		                    	<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
								    <TabList>
								    	<Tab className="btn btn-secondary">Email Configuration</Tab>
								      	<Tab className="btn btn-secondary">Email Deliverability</Tab>
								    </TabList>
								    <TabPanel>
								    	<Tabs selectedIndex={tabIndexSec} onSelect={(index) => setTabIndexSec(index)}>
									    	<TabList>
										    	<Tab className="btn btn-secondary">Compose</Tab>
										    	<Tab className="btn btn-secondary">Email</Tab>
										      	<Tab className="btn btn-secondary">Organization Emails</Tab>
										    </TabList>
										    <TabPanel>
										    	<div className="tabContent">
										      		<div className="d-flex justify-content-between align-items-baseline">
														<div className="col-md-8 col-12">
															<h5>Compose Settings</h5>
															<p>Configure basic default email compose settings to personalize your composing experience.</p>
														</div>
														<Link to="/crm">Help</Link>
													</div>
													<div className="col-12 mt-3">
														<table className="table">
															<tbody>
																<tr>
																	<td>Default Font Family</td>
																	<td>
																		<select className="form-select">
																			<option value="1">Verdana</option>
																			<option value="2">Arial</option>
																		</select>
																	</td>
																</tr>
																<tr>
																	<td>Default Font Size</td>
																	<td>
																		<select className="form-select">
																			<option value="10">10</option>
																			<option value="9">9</option>
																			<option value="8">8</option>
																			<option value="7">7</option>
																			<option value="6">6</option>
																			<option value="5">5</option>
																		</select>
																	</td>
																</tr>
																<tr>
																	<td>Default From Address</td>
																	<td>
																		<select className="form-select">
																			<option value="Aamna Hassan(kbmhr@kbmgroup.co.uk)">Aamna Hassan(kbmhr@kbmgroup.co.uk)</option>
																		</select>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
										      	</div>
										    </TabPanel>
										    <TabPanel>
										    	<div className="tabContent">
										      		<div className="d-flex justify-content-between align-items-baseline">
														<div className="col-md-8 col-12">
															<h5>Configure Email</h5>
															<p>Connect your email inbox with Zoho CRM and transform the way you do sales.</p>
														</div>
														<Link to="/crm">Help</Link>
													</div>
										      	</div>
										    </TabPanel>
										    <TabPanel>
										    	<div className="tabContent">
										      		<div className="d-flex justify-content-between align-items-baseline">
														<div className="col-md-8 col-12">
															<h5>Organization Email Addresses</h5>
															<p>Add the email addresses of your various departments from which you send and receive customer emails. You can use these addresses as the 'From' and 'Reply to' addresses. The email addresses have to be verified before you can use them.</p>
														</div>
														<Link to="/crm">Help</Link>
													</div>
													<div className="col-12 mt-3">
														<button type="button" className="btn btn-secondary">Add Email Address</button>
													</div>
										      	</div>
										    </TabPanel>
										</Tabs>
								    </TabPanel>
								    <TabPanel>
								      	<div className="tabContent">
								      		<div className="d-flex justify-content-between align-items-baseline">
												<div className="col-md-8 col-12">
													<h5>Email Authentication</h5>
													<p>Improve deliverability of emails sent from Zoho CRM by adding SPF (Sender Policy Framework) and DKIM (Domain Keys Identified Mail) in your DNS settings.</p>
												</div>
												<Link to="/crm">Help</Link>
											</div>
											<div className="col-12 mt-3">
												<button type="button" className="btn btn-secondary">Add Domain</button>
											</div>
								      	</div>
								    </TabPanel>
								</Tabs>
		                    </div>
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		</>
	);
}

export default EmailConfig