import React,{useEffect,useState} from 'react';
import ReactDOM from "react-dom";
import { Link,useNavigate } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { toast } from "react-toastify";

const WebTab = () => {
	
	useRedirectLoggedOutUser("/crm/login");

	
	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">

		                    	<div className="tabMenu d-flex justify-content-between">
		                        	<ul>
		                        		<li><Link className="btn btn-secondary" to="/crm/modules">Modules</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/tab-group">Tab Groups</Link></li>
		                        		<li><Link className="btn btn-secondary active" to="/crm/web-tab">Web Tabs</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/global-set">Global Sets</Link></li>
		                        	</ul>
		               
		                        </div>

		                        <div className="col-12 mt-4">
			                        <div className="d-flex align-items-center justify-content-between">
			                        	<div>
			                        		<h5 className="">Web Tabs</h5>
					                        <p>
					                        	Web tabs help you to open web pages like internet pages,company-wide announcements,web applications, etc.
					                        	indide KBM crm.
					                        </p>
			                        	</div>
				                        <div className="modules_btn text-end mt-4">
				                      		<button type="button" className="btn btn-secondary">New Tab</button>
				                      	</div>
			                        </div>
		                        </div>
		                        
			                    
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		</>
	);
}

export default WebTab