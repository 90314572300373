import React from 'react'

const InfoBox = ({bgColor, title, count, icon}) => {
	return (
		<div>
			<div className={`infoBox ${bgColor}`}>
				<span className="info-box-icon">
					{icon}
				</span>
				<span className="info-box-text">
					<h6>{title}</h6>
					<p>{count}</p>
				</span>
			</div>
		</div>
	)
}

export default InfoBox