import React,{useState,useEffect,useRef,useMemo} from 'react';
import { Link } from "react-router-dom";
import ModuleSideBar from './ModuleSideBar';
import Footer from '../components/Footer';
import { AiOutlineSearch,AiOutlineArrowLeft } from "react-icons/ai";
import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const data = [
  {
    _id: '1',
    title: "Subject",
  },
  {
    _id: '2',
    title: "Due Date",
  },
  {
    _id: '3',
    title: "Status",
  },
  {
    _id: '4',
    title: "Vendor Name",
  },
  {
    _id: '5',
    title: "Phone",
  },
  {
    _id: '6',
    title: "Email",
  },
];

const data2 = [
  {
    _id: '4',
    title: "Vendor Name",
  },
];

const reorder = (list, startIndex, endIndex) =>{
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex,0,removed);
	return result;
}

const getListStyle = (isDraggingOver) =>({
	background: isDraggingOver? '#fff':'#fff',
})

const getItemStyle = (isDragging,draggableStyle)=>({
	userSelect: 'none',
	padding: 10,
	margin: '0 0 10px 0',
	background: isDragging?'#000':'#f1f1f1',
	color: isDragging?'#fff':'#000',
	fontSize: '14px',
	cursor: 'move',
	display: 'flex',
	alignItems: 'center',
	fontWeight: 500,
	flexDirection: 'row',
	...draggableStyle
})


const layouts = [
	{
		
	},
];

const ReportEdit = () => {

	useRedirectLoggedOutUser("/crm/login");

const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'VENDOR NAME',
        Header: () => <span>VENDOR NAME</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          <span className=""><Link to={`/crm/report/id`}><b>{renderedCellValue}</b></Link></span>
        ),
      },
      {
        accessorFn: (row) => row.subject, //alternate way
        id: 'subject', //id required if you use accessorFn instead of accessorKey
        header: 'SUBJECT',
        Header: () => <span>SUBJECT</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.dueDate, //alternate way
        id: 'dueDate', //id required if you use accessorFn instead of accessorKey
        header: 'DUE DATE',
        Header: () => <span>DUE DATE</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.status, //alternate way
        id: 'status', //id required if you use accessorFn instead of accessorKey
        header: 'STATUS',
        Header: () => <span>STATUS</span>, //optional custom header render
      },
      {
        accessorFn: (row) => <span><Link to="/crm/report/id"><b>{row.name}</b></Link></span>, //alternate way
        id: 'name2', //id required if you use accessorFn instead of accessorKey
        header: 'VENDOR NAME',
        Header: () => <span>VENDOR NAME</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.phone, //alternate way
        id: 'phone', //id required if you use accessorFn instead of accessorKey
        header: 'PHONE',
        Header: () => <span>PHONE</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.email, //alternate way
        id: 'email', //id required if you use accessorFn instead of accessorKey
        header: 'EMAIL',
        Header: () => <span>EMAIL</span>, //optional custom header render
      },
    ],
    [],
  );

const [items, setItems] = useState([]);

	useEffect(() => {
	    setItems(data);
	}, []);

	// console.log(moduleItems)

	const onDragEnd = (result) => {
	    if (!result.destination) {
	      return;
		}

	    const reorderedItems = reorder(
	      items,
	      result.source.index,
	      result.destination.index
	    );

	    // console.log({ reorderedItems });
	    setItems(reorderedItems);
	};

	const [secondItems, setSecondItems] = useState([]);

	useEffect(() => {
	    setSecondItems(data2);
	}, []);

	// console.log(moduleItems)

	const onSecondDragEnd = (result) => {
	    if (!result.destination) {
	      return;
		}

	    const reorderedItems = reorder(
	      secondItems,
	      result.source.index,
	      result.destination.index
	    );

	    // console.log({ reorderedItems });
	    setSecondItems(reorderedItems);
	};

	const [windowHeight, setWindowHeight] = useState({ winHeight: window.innerHeight })

	const detectSize = () =>{
		setWindowHeight({
			winHeight: window.innerHeight 
		})
	}

	useEffect(() => {
	  window.addEventListener('resize', detectSize);
	  return () => {
	    window.removeEventListener('resize', detectSize)
	  }
	}, [windowHeight])

	const isFirstRender = useRef(true);
	const [columnVisibility, setColumnVisibility] = useState({});

	useEffect(() => {
	    const columnVisibility = localStorage.getItem(
	      'mrt_columnVisibility_table_1',
	    );
	    
	    if (columnVisibility) {
	      setColumnVisibility(JSON.parse(columnVisibility));
	    }
	    
	    isFirstRender.current = false;
	  }, []);

	useEffect(() => {
	    if (isFirstRender.current) return;
	    localStorage.setItem(
	      'mrt_columnVisibility_table_1',
	      JSON.stringify(columnVisibility),
	    );
	  }, [columnVisibility]);
	
	return (
		<>
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container reports_sec" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		      	{/*<!--  BEGIN SIDEBAR  -->*/}
	        {/*<div className="sidebar-wrapper sidebar-theme">

	            <nav id="sidebar">
	            
	                <div className="navbar-nav theme-brand flex-row  text-center">
	                    <div className="nav-logo">
	                        
	                        <div className="nav-item theme-text">
	                            <Link to="" className="nav-link"> KBM </Link>
	                        </div>
	                    </div>
	                    <div className="nav-item sidebar-toggle">
	                        <div className="btn-toggle sidebarCollapse">
	                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevrons-left"><polyline points="11 17 6 12 11 7"></polyline><polyline points="18 17 13 12 18 7"></polyline></svg>
	                        </div>
	                    </div>
	                </div>
	                <div className="shadow-bottom"></div>
	                
	                <ul className="list-unstyled menu-categories" id="accordionExample">
	                <li className="mt-4">
	                	<h5>Folders </h5>
	                </li>
	                <li>
	                	<form className="custom-search mb-3">
							<div className="position-relative">
								<input type="text" placeholder="Search" className="form-control" />
								<button><AiOutlineSearch size={18}/></button>
							</div>
						</form>
	                </li>
	                <li className="menu active">
	                    <Link to="" className="dropdown-toggle mb-2">
                            <div className="">
                                <span title="All Reports">All Reports</span>
                            </div>
                        </Link>
                        
                    </li>
	                <li className="menu active">
	                    <Link to="" className="dropdown-toggle mb-2">
                            <div className="">
                                <span title="My Reports">My Reports</span>
                            </div>
                        </Link>
                        
                    </li>
	                </ul>
	                
	            </nav>

	        </div>*/}
	       {/* <!--  END SIDEBAR  -->*/}

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content reportDetail">
		            <div className="layout-px-spacing">
					
		                <div className="middle-content container-xxl p-0">

		                    
		                    <div className="row layout-top-spacing">
		                    
		                    <div className="viewModule_rightSide">
		                    	<div className="d-flex justify-content-between align-items-baseline flex-wrap">
														<div className="col-md-4 col-12">
															<h5><Link to="/crm/reports"><AiOutlineArrowLeft /> Vendors vs. Purchases</Link></h5>
														</div>
														<div className="col-md-4 col-12 text-end">
															<Link to="/crm/reports" className="btn btn-secondary mx-1">Cancel</Link>
															<Link to="/crm/report/id" className="btn btn-secondary mx-1">Run</Link>
															<button className="btn btn-secondary mx-1">Save</button>
														</div>
													</div>
													<div className="d-flex justify-content-between align-items-baseline flex-wrap">
														<div className="col-md-3 col-12">
															<div className="filterSidebar" style={{height : windowHeight.winHeight - 210 }}>
																<Tabs>
															    <TabList>
															      <Tab>Columns</Tab>
															      <Tab>Filters</Tab>
															    </TabList>

															    <TabPanel>
															      <h5>Columns</h5>
															      <div className="dragNdrop-moduleList">
																			<DragDropContext onDragEnd={onDragEnd}>
																				<Droppable droppableId="droppableId">
																					{(provided, snapshot) =>(
																							<div 
																							{...provided.droppableProps} 
																							ref={provided.innerRef} 
																							style={getListStyle(snapshot.isDraggingOver)}
																							>
																								{
																									items.map((item,index) =>(
																										<Draggable key={item._id} draggableId={item._id} index={index}>
																											{(provided,snapshot) => (
																												<div className="">
																													
																													<div 
																														className="card" 
																														ref={provided.innerRef} 
																														{...provided.draggableProps} 
																														{...provided.dragHandleProps}
																														style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)}
																														>
																														{item.title}
																														</div>
																												</div>
																											)}
																										</Draggable>
																									))
																								}
																								{provided.placeholder}
																							</div>
																						)}
																				</Droppable>
																			</DragDropContext>
																		</div>
																		<h5 className="mt-3">Row Groups</h5>
															      <div className="dragNdrop-moduleList">
																			<DragDropContext onDragEnd={onSecondDragEnd}>
																				<Droppable droppableId="droppableId">
																					{(provided, snapshot) =>(
																							<div 
																							{...provided.droppableProps} 
																							ref={provided.innerRef} 
																							style={getListStyle(snapshot.isDraggingOver)}
																							>
																								{
																									secondItems.map((item,index) =>(
																										<Draggable key={item._id} draggableId={item._id} index={index}>
																											{(provided,snapshot) => (
																												<div className="">
																													
																													<div 
																														className="card" 
																														ref={provided.innerRef} 
																														{...provided.draggableProps} 
																														{...provided.dragHandleProps}
																														style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)}
																														>
																														{item.title}
																														</div>
																												</div>
																											)}
																										</Draggable>
																									))
																								}
																								{provided.placeholder}
																							</div>
																						)}
																				</Droppable>
																			</DragDropContext>
																		</div>
															    </TabPanel>
															    <TabPanel>
															      <h5>Filters</h5>
															      <small>No Filters Found</small>
															    </TabPanel>
															  </Tabs>
															</div>
														</div>
			                    	<div className="statbox widget box box-shadow col-md-9 col-12">
	                            <div className="widget-content widget-content-area">
	                              	<MaterialReactTable 
	                              	onColumnVisibilityChange={setColumnVisibility}
                                      state={{columnVisibility}}
                                      enableRowSelection={true}
	                              	columns={columns} 
	                              	data={layouts} 
	                              	// enableRowNumbers rowNumberMode="original"
	                              	/>
	                            </div>
	                      		</div>
                      		</div>
		                    </div>
		                     		
		                     	
		                    	
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}
		</>
	)
}

export default ReportEdit