import React,{useEffect,useRef,useState,useMemo,useReducer} from 'react';
import ReactDOM from 'react-dom';
import { FaCheck } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit,AiOutlineEllipsis } from "react-icons/ai";
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import { Modal } from 'react-bootstrap';
import PasswordInput from '../components/PasswordInput';
import Footer from '../components/Footer';
// import UserStats from '../components/UserStats';
import { Link,useNavigate } from 'react-router-dom'; 
import { toast } from "react-toastify";
import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { shortenText } from "./ProfileAccount";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { validateEmail } from "../../redux/crm-features/auth/authService";
import { useDispatch, useSelector } from "react-redux";
// import ChangeCompanyRole from "../components/ChangeCompanyRole";
import {
  // addUser,
  getAll,
  upgradeUser,
  deleteUser,
  // getAllCompanies,
  RESET,
  sendVerificationEmail,
} from "../../redux/crm-features/auth/authSlice";
import {
  EMAIL_RESET,
  sendAutomatedEmail,
} from "../../redux/crm-features/email/emailSlice";
import {
  	getRoles
} 	from "../../redux/crm-features/userRole/roleSlice";

// import imagePreview from "https://i.pinimg.com/736x/1f/51/03/1f51038bd5af319dd132a3fab9ee3b57.jpg";
// const cloud_name = process.env.REACT_APP_CLOUD_NAME;
// const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;


// const initialState ={
// 	name : '',
// 	email : '',
// 	password : '',
// 	phone : '',
// 	desc: '',
// 	role: '',
// }

const ChangeCompanyRole = ({ _id, email }) => {
  const [userRole, setUserRole] = useState("");
  const dispatch = useDispatch();

  const { userRoles } = useSelector(
      (state) => state.userRole
  );

  useEffect(() => {
      dispatch(getRoles());
  }, [dispatch]);

  // Change User role
  const changeRole = async (e) => {
    e.preventDefault();

    if (!userRole) {
      toast.error("Please select a role");
    }

    const userData = {
      role: userRole,
      id: _id,
    };

    const emailData = {
      subject: "Account Role Changed - KBM",
      send_to: email,
      reply_to: "noreply@kbmgroup",
      template: "changeRole",
      url: "/crm/login",
    };

    await dispatch(upgradeUser(userData));
    await dispatch(sendAutomatedEmail(emailData));
    await dispatch(getAll());
    dispatch(EMAIL_RESET());
  };

  return (
    <div className="sort">
      <form
        className="d-flex"
        onSubmit={(e) => changeRole(e, _id, userRole)}
      >
        <select value={userRole} onChange={(e) => setUserRole(e.target.value)} className="form-select form-control-sm">
          <option value="">-- select --</option>
          {
            userRoles && userRoles.map((item) => {
              return(
                  <option key={item._id} value={item.name}>{item.name}</option>
                )
            })
          }
        </select>
        <button className="btn btn-secondary changeRoleBtn">
          <FaCheck />
        </button>
      </form>
    </div>
  );
};

const MasterDataDetail = () => {
	useRedirectLoggedOutUser("/crm/login");

	// const [formData, setFormData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	// const {name,email,password,phone,desc,role} = formData;
	// const [ViewPost, SetPostShow] = useState(false)
 //    const handlePostShow = () => { SetPostShow(true) }
 //    const hanldePostClose = () => { SetPostShow(false) }

	const dispatch = useDispatch();
	const navigate = useNavigate();

	// const [imagePreview, setImagePreview] = useState(null);
	// const [profileImage, setProfileImage] = useState(null);
	// const [currentItems, setCurrentItems] = useState([]);

	const { users, isLoading, isLoggedIn, isSuccess, message } = useSelector(
	    (state) => state.auth
	);

	// const { userRoles } = useSelector(
	//     (state) => state.userRole
	// );

	// useEffect(() => {
	//     dispatch(getRoles());
	// }, [dispatch]);

	// const handleImageChange = (e) => {
	// 	setProfileImage(e.target.files[0]);
 //    	setImagePreview(URL.createObjectURL(e.target.files[0]));
	// }

	// const handleInputChange = (e) => {
	// 	const {name, value} = e.target;
	// 	setFormData({...formData, [name]: value});
	// }

	// const companyAdd = async (event) => {
	// 	event.preventDefault();

	// 	let imageURL;

	// 	if (profileImage !== null &&(profileImage.type === "image/jpeg" || profileImage.type === "image/jpg" ||
	// 			 profileImage.type === "image/png"))
	// 		{

	// 			const image = new FormData();
	// 	        image.append("file", profileImage);
	// 	        image.append("cloud_name", cloud_name);
	// 	        image.append("upload_preset", upload_preset);

	// 	        // Save image to Cloudinary
	// 	        const response = await fetch(
	// 	          "https://api.cloudinary.com/v1_1/kbm/image/upload",
	// 	          { method: "post", body: image }
	// 	        );
	// 	        const imgData = await response.json();
	// 	        // console.log(imgData);
	// 	        imageURL = imgData.url.toString();

	// 		}

	// 	if (!name || !email || !password || !phone || !desc || !role){
	// 		return toast.error('All fields are required')
	// 	}
	// 	if (password.length < 6) {
	//       	return toast.error("Password must be up to 6 characters");
	//     }
	// 	if (!validateEmail(email)){
	// 		return toast.error('Please enter a valid email')
	// 	}

	// 	const userData = {name, email, password, phone, desc, role, photo: profileImage ? imageURL : formData.photo, userType:"company"};
		
	// 	await dispatch(addUser(userData));
	// 	setFormData({ name: "", email: "", password: "", phone: "", desc: "", role: "", photo: "" });
	// 	SetPostShow(false);
	// 	forceUpdate();
	// 	// window.location.reload();
	// 	// await dispatch(sendVerificationEmail());
	// };

	// console.log(users);

	useEffect(() => {
	    dispatch(getAll());
	}, [dispatch, reducerValue]);

	const removeUser = async (id) => {
	    await dispatch(deleteUser(id));
	    dispatch(getAll());
	};

	const confirmDelete = (id) => {
	    confirmAlert({
	      title: "Delete This Field",
	      message: "Are you sure to do delete this ?",
	      buttons: [
	        {
	          label: "Delete",
	          onClick: () => removeUser(id),
	        },
	        {
	          label: "Cancel",
	          // onClick: () => alert("Click No"),
	        },
	      ],
	    });
	};

const columns = useMemo(
    () => [
      // {
      //   accessorKey: 'name', //simple recommended way to define a column
      //   header: 'Name',
      //   // muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
      //   Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      // },
      {
        accessorFn: (row) => `${row.name}`, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'Company Name',
        Header: () => <span>Company Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          // <span className="textUppercase"><Link target="_blank" to={`/crm/company-data/${row.original._id}`}>{renderedCellValue}</Link></span>
          <span className="d-flex align-items-center"><Link target="_blank" to={`/crm/company-data/${row.original._id}`}>{renderedCellValue}</Link>
            <div className="dropdown">
              <span className="dropdown-toggle mx-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <AiOutlineEllipsis />
              </span>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><Link className="dropdown-item" to={`/crm/edit-user/${row.original._id}`}>Edit</Link></li>
                <li><Link className="dropdown-item" to="#" onClick={() => confirmDelete(row.original._id)}>Delete</Link></li>
              </ul>
            </div>
          </span>
        ),
      },
      // {
      //   accessorFn: (row) => <span className="textUppercase">{row.userType}</span>, //alternate way
      //   id: 'userType', //id required if you use accessorFn instead of accessorKey
      //   header: 'Type',
      //   Header: () => <span>Type</span>, //optional custom header render
      // },
      {
        accessorFn: (row) => <Link target="_blank" to={`/crm/company-data/${row._id}`}><img width = '70px' src={row.photo}/></Link>, //alternate way
        id: 'photo', //id required if you use accessorFn instead of accessorKey
        header: 'Logo',
        Header: () => <span>Logo</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.email, //alternate way
        id: 'email', //id required if you use accessorFn instead of accessorKey
        header: 'Company Email',
        Header: () => <span>Company Email</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.phone, //alternate way
        id: 'phone', //id required if you use accessorFn instead of accessorKey
        header: 'Company Phone',
        Header: () => <span>Company Phone</span>, //optional custom header render
      },
      {
        accessorFn: (row) => 
        <>
        <span>{row.role}</span>
        <ChangeCompanyRole _id={row._id} email={row.email}/>
        </>, //alternate way
        id: 'role', //id required if you use accessorFn instead of accessorKey
        header: 'Company Role',
        Header: () => <span>Company Role</span>, //optional custom header render
      },
      // {
      //   accessorFn: (row) => , //alternate way
      //   id: 'change-role', //id required if you use accessorFn instead of accessorKey
      //   header: 'Change Role',
      //   Header: () => <span>Role</span>, //optional custom header render
      // },
      {
        accessorFn: (row) => row.fax, //alternate way
        id: 'fax', //id required if you use accessorFn instead of accessorKey
        header: 'Fax',
        Header: () => <span>Fax</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.website, //alternate way
        id: 'website', //id required if you use accessorFn instead of accessorKey
        header: 'Website',
        Header: () => <span>Website</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.mobile, //alternate way
        id: 'mobile', //id required if you use accessorFn instead of accessorKey
        header: 'Mobile',
        Header: () => <span>Mobile</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.state, //alternate way
        id: 'state', //id required if you use accessorFn instead of accessorKey
        header: 'State',
        Header: () => <span>State</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.street, //alternate way
        id: 'street', //id required if you use accessorFn instead of accessorKey
        header: 'Street',
        Header: () => <span>Street</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.zipCode, //alternate way
        id: 'zipCode', //id required if you use accessorFn instead of accessorKey
        header: 'Zip Code',
        Header: () => <span>Zip Code</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.city, //alternate way
        id: 'city', //id required if you use accessorFn instead of accessorKey
        header: 'City',
        Header: () => <span>City</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.country, //alternate way
        id: 'country', //id required if you use accessorFn instead of accessorKey
        header: 'Country',
        Header: () => <span>Country</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.desc, //alternate way
        id: 'desc', //id required if you use accessorFn instead of accessorKey
        header: 'Description',
        Header: () => <span>Description</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.createdAt, //alternate way
        id: 'createdAt', //id required if you use accessorFn instead of accessorKey
        header: 'Created At',
        Header: () => <span>Created At</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.updatedAt, //alternate way
        id: 'updatedAt', //id required if you use accessorFn instead of accessorKey
        header: 'Updated At',
        Header: () => <span>Updated At</span>, //optional custom header render
      },
      // {
      //   accessorFn: (row) => <span>
      //                       <FaTrashAlt
      //                         size={18}
      //                         cursor="pointer"
      //                         color="rgb(0,0,0,.8)"
      //                         onClick={() => confirmDelete(row._id)}
      //                       />
      //                       &nbsp;
      //                       {/*<Link to={`/edit-user/${row._id}`}>
	     //                        <AiFillEdit
	     //                          size={20}
	     //                          cursor="pointer"
	     //                          color="rgb(0,0,0,.8)"
	     //                        />
      //                       </Link>*/}
      //                     </span>, //alternate way
      //   id: 'action', //id required if you use accessorFn instead of accessorKey
      //   header: 'Action',
      //   Header: () => <span>Action</span>, //optional custom header render
      // },
    ],
    [],
  );

const isFirstRender = useRef(true);
  const [columnVisibility, setColumnVisibility] = useState({});

  useEffect(() => {
      const columnVisibility = localStorage.getItem(
        'mrt_columnVisibility_table_1',
      );
      
      if (columnVisibility) {
        setColumnVisibility(JSON.parse(columnVisibility));
      }
      
      isFirstRender.current = false;
    }, []);

  useEffect(() => {
      if (isFirstRender.current) return;
      localStorage.setItem(
        'mrt_columnVisibility_table_1',
        JSON.stringify(columnVisibility),
      );
    }, [columnVisibility]);

	return (
		<div>

		{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">

			                    {/*content*/}


			                    <div className="col-lg-12">
			                    	<div className="col-12">
			                    		<div className="d-flex align-items-center justify-content-between">
			                    			<h5 className="m-0">All Companies </h5>
                                <Link className="btn btn-secondary" to={`/crm/master-data`}>Back</Link>
			                    			{/*<button onClick={() => { handlePostShow() }} className="v-link btn btn-secondary _effect--ripple waves-effect waves-light">Add Company</button>*/}
			                    		</div>
			                    	</div>
			                    	{/*<div className="col-12 mt-4">
			                    		<UserStats />
			                    	</div>*/}
		                            <div className="statbox widget box box-shadow moduleData">
		                                <div className="widget-content widget-content-area">
		                                {users.length === 0 ? (
		                                	<p>Companies not found...</p>
		                                ):(
		                                	<MaterialReactTable 
                                      columns={columns} 
                                      data={users} 
                                      onColumnVisibilityChange={setColumnVisibility}
                                      state={{columnVisibility}}
                                      enableRowSelection={true}
                                      // enableRowNumbers rowNumberMode="original"
                                      />
		                                )}
		                                </div>
		                            </div>
	                        	</div>
	                        	

			                    {/*content*/}

		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    {/*<!-- Modal -->*/}
		    {/*<Modal show={ViewPost}
                    onHide={hanldePostClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
			<div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
			    <div className="modal-dialog" role="document">
			        <div className="modal-content">
			            <div className="modal-header">
			                <h5 className="modal-title" id="exampleModalLabel">Add Company</h5>
			                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
			                  <svg> ... </svg>
			                </button>
			            </div>
			            <div className="modal-body">
			            	<form className="form" onSubmit={companyAdd}>
			            	<h5 className="modal-title">Add Company</h5>
                                <div className="text-center user-info">
                                    <div className="profile_image_upload">
                                    	<label>Company Logo</label>
                                    	<img src={imagePreview === null ? '../src/assets/img/profile-3.jpg' : imagePreview} alt="avatar"/>
                                    	<input type="file" accept="image/*" name="image" onChange={handleImageChange}/>
                                    </div>
                                    <p className="text-capitalize mb-0">{profile?.name}</p>
                                    <span>{profile.role}</span>
                                </div>                            
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control mb-3" id="fullName" placeholder="Company Name" name="name" value={name} onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="email" className="form-control mb-3" id="email" name="email" placeholder="Company Email *" value={email} onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <PasswordInput placeholder="Password *" name="password" value={password} onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control mb-3" name="phone" id="phone" placeholder="Phone Number" value={phone} onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                    	<select value={role} onChange={handleInputChange} name="role" className="form-select form-control-sm mb-3">
								     		<option>--Select--</option>
								     		{
								     			userRoles && userRoles.map((item) => {
								     				return(
								     						<option key={item._id} value={item.name}>{item.name}</option>
								     					)
								     			})
								     		}
								     		
								     	</select>
                                    </div>                              
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea type="text" className="form-control mb-3" name="desc" cols="5" rows="5" placeholder="Description" value={desc} onChange={handleInputChange}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-1">
                                        <div className="form-group text-end">
                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
			                				<button className="btn btn btn-light-dark mx-3" onClick={hanldePostClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
			            
			</Modal>*/}
			
		</div>
	)
}

export default MasterDataDetail