import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/roles/crm/`;


// add user role
const addRole = async(roleData) =>{
	const response = await axios.post(API_URL + "addRole", roleData);
	return response.data;
};

// Get all roles
const getRoles = async () => {
  const response = await axios.get(API_URL + "getRoles");
  return response.data;
};

// get user role
const getRole = async(id) =>{
	const response = await axios.get(API_URL + id);
	return response.data;
};

// update user profile
const updateRole = async(id, roleData) =>{
	const response = await axios.patch(`${API_URL}${id}`, roleData);
	return response.data;
};

// delete user profile
const deleteRole = async(id) =>{
	const response = await axios.delete(API_URL + id);
	return response.data.message;
};


const roleService = {
	addRole,
	getRoles,
	getRole,
	updateRole,
	deleteRole,
}

export default roleService;